import { Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { EnvTypeIcon, EnvTypeIconProps } from '../icons/env-type-icons';
import { theme } from '../shared/theme';

export function EnvironmentTitle({
  environmentName,
  subtype,
  fontSize,
}: {
  environmentName: string;
  subtype: EnvTypeIconProps['envSubtype'];
  fontSize?: string;
}) {
  return (
    <Flex gap={theme.spacing[6]} alignItems="center">
      <Flex
        backgroundColor="neutral100"
        height="56px"
        width="56px"
        minWidth="36px"
        alignItems="center"
        justifyContent="center"
        borderRadius={theme.borders.radius.small}
      >
        <EnvTypeIcon
          envSubtype={subtype}
          style={{ maxHeight: '24px', maxWidth: '24px', height: '100%', width: '100%' }}
        />
      </Flex>
      <Flex alignItems="center" justifyContent="center">
        <SiteName style={{ fontSize }}>{environmentName}</SiteName>
      </Flex>
    </Flex>
  );
}

export function EnvironmentTitleSmall({
  environmentName,
  subtype,
}: {
  environmentName: string;
  subtype?: EnvTypeIconProps['envSubtype'];
}) {
  return (
    <Flex gap={theme.spacing[6]} width="100%" justifyContent="center">
      {subtype && (
        <Flex height="16px" width="16px" alignItems="center" justifyContent="center">
          <EnvTypeIcon
            envSubtype={subtype}
            color={theme.colors.neutral700}
            style={{ maxHeight: '24px', maxWidth: '24px', height: '100%', width: '100%' }}
          />
        </Flex>
      )}
      <Flex alignItems="center" justifyContent="flex-start">
        <SiteName style={{ fontSize: theme.fontSize.medium, paddingLeft: 0 }}>{environmentName}</SiteName>
      </Flex>
    </Flex>
  );
}

const SiteName = styled.div`
  font-family: ${theme.fontFamily.dmSans};
  font-size: ${theme.fontSize.h1};
  font-weight: ${theme.fontWeight.bold};
  color: ${theme.colors.neutral800};
  padding-left: ${theme.spacing[2]};
`;
