import { Kbd, ListItem } from '@chakra-ui/react';
import { getCtrlKeyName } from '@resistapp/client/utils/general';

interface Props {
  label?: string;
}

export function ChartHotkeyInstructions(props: Props) {
  return (
    <>
      <ListItem>
        <Kbd>{getCtrlKeyName()}</Kbd> + <Kbd>click</Kbd> to select multiple {props.label}
      </ListItem>
      <ListItem>
        <Kbd>Shift</Kbd> + <Kbd>click</Kbd> to select a range of {props.label}
      </ListItem>
    </>
  );
}
