import { AbundanceStats, Pooling, PoolingMode, PoolingType } from '@resistapp/common/api-types';
import {
  EnvironmentSubType,
  EnvironmentType,
  isAfterSample,
  isBeforeSample,
} from '@resistapp/common/environment-types';
import { ensureUtcMonth, friendlyMonth } from '@resistapp/common/friendly';
import { poolSampleEnvironments } from '@resistapp/common/pool-samples';
import { arePropertiesPresentInAllFullSamples } from '@resistapp/common/typeguards';
import {
  AdminAreaByLevel,
  Environment,
  FullAbundance,
  FullSample,
  FullSamplesByUID,
  FullSampleWithTime,
  PooledEnvHack,
} from '@resistapp/common/types';
import { flattenSamplesByUID } from '@resistapp/common/utils';
import { chain, flatten, intersection, keys, pickBy } from 'lodash';
import { getResistanceIndexData } from '../../../common/statistics/resistance-index';
import { DateDatum } from './research-plot-data';

export type SingleOverviewLineSeries = OverviewDatum[];
export type OverviewLineData = SingleOverviewLineSeries[];

export interface OverviewDatumSimple extends DateDatum, AbundanceStats {
  environment: Environment | PooledEnvHack;
  lat: number | undefined;
  lon: number | undefined;
  inferredLat: number | undefined;
  inferredLon: number | undefined;
  adminLevels: AdminAreaByLevel | undefined;
  city: string | undefined;
  region: string | undefined;
  country: string | undefined;
  abundances: FullAbundance[]; // TODO rename this to rawAbundances, make it optional and don't populate for effluent only sites
}

export interface OverviewProcessDatum extends OverviewDatumSimple {
  environmentAfter?: Environment | PooledEnvHack;
  afterAbundances?: FullAbundance[];
}

export type OverviewDatum = OverviewProcessDatum;

export const overviewEnvironmentTypesInPrioOrder = [EnvironmentType.WASTEWATER, EnvironmentType.NATURAL_WATER];
export const unsupportedEnvironmentSubTypes: EnvironmentSubType[] = [EnvironmentSubType.OTHER_WASTEWATER]; // Igonre other wastewater in order to support projects with incomplete data (eg. wastewater pellets without co-ordinates)

export function getProjectOverviewEnvironmentType(samplesByUID: FullSamplesByUID): EnvironmentType | undefined {
  const flatSamples = flattenSamplesByUID(samplesByUID);
  const overviewEnvType = overviewEnvironmentTypesInPrioOrder.find(supportedType =>
    flatSamples.some(
      sample =>
        sample.environment.type === supportedType &&
        (!sample.environment.subtype || !unsupportedEnvironmentSubTypes.includes(sample.environment.subtype)),
    ),
  );
  return overviewEnvType;
}

/*
 * Get relevant samples for overview and drop admin levels that are not present in all samples
 *
 * Overview is designed for (and may make biological sense only for) selected environment types
 */
export function getSupportedSamplesWithConsistentAdminLevels(samplesByUID: FullSamplesByUID): FullSample[] {
  const overviewEnvType = getProjectOverviewEnvironmentType(samplesByUID);
  if (!overviewEnvType) {
    return [];
  }
  const flatSamples = flattenSamplesByUID(samplesByUID);
  const flatSupportedSamples = flatSamples.filter(
    sample =>
      sample.environment.type === overviewEnvType &&
      (!sample.environment.subtype || !unsupportedEnvironmentSubTypes.includes(sample.environment.subtype)),
  );
  const levelsInAllSamples = new Set(
    chain(flatSupportedSamples)
      .map(s => s.adminLevels || {})
      .map(levels => keys(levels).map(level => +level))
      .reduce((acc, levels) => intersection(acc, levels))
      .value(),
  );
  const wastewaterSamplesWithConsistentLevels = flatSupportedSamples.map(sample => ({
    ...sample,
    adminLevels: pickBy(sample.adminLevels, datum => levelsInAllSamples.has(datum.level)),
  }));
  return wastewaterSamplesWithConsistentLevels;
}

/*
 * Build BoxDatum series (arrays), where each series contains time series BoxDatums for one overview environment / location (line)
 */
export function buildOverviewLineData(samples: FullSample[], pooling: Pooling | undefined): OverviewLineData {
  if (!samples.length) {
    throw new Error('No supported water samples available');
  }
  const samplesHaveTimes = arePropertiesPresentInAllFullSamples(samples, 'time');
  if (!samplesHaveTimes) {
    throw new Error('All samples must have time');
  }
  const samplesHaveLocations = arePropertiesPresentInAllFullSamples(samples, 'inferredLat');
  if (!samplesHaveLocations) {
    throw new Error('All samples must have location');
  }

  const envTypes = chain(samples)
    .map(sample => sample.environment.type)
    .uniq()
    .value();
  if (envTypes.length > 1) {
    // TODO: figure out if it's ok to admin-level pool natural and wastewater samples when enabling this
    throw new Error('All samples must have the same environment type');
  }

  // Before pooling by admin level, we have to combine site samples
  const maybeSitePooledSamples =
    pooling?.type === PoolingType.SITE_AND_ADMIN_LEVEL
      ? poolSampleEnvironmentsForOverview(samples, {
          type: PoolingType.SITE,
          mode: PoolingMode.THROW_MISSING,
        })
      : samples;

  // Proceed with primary pooling
  const maybePooledSamples = poolSampleEnvironmentsForOverview(maybeSitePooledSamples, pooling);

  return chain(maybePooledSamples)
    .groupBy(sample => sample.environment.id)
    .mapValues((siteSamples, _, _c) => {
      return buildSingleOverviewLineSeries(siteSamples);
    })
    .values()
    .value();
}

function poolSampleEnvironmentsForOverview(
  samples: FullSampleWithTime[],
  pooling: Pooling | undefined,
): FullSampleWithTime[] {
  // Special case checks
  if (!pooling) {
    return samples;
  } else if (pooling.type !== PoolingType.SITE_AND_ADMIN_LEVEL && pooling.type !== PoolingType.SITE) {
    throw Error('Only site and admin level pooling are supported in the overview');
  }

  // Combine sample environments so that samples with the same pooling criteria share the environment
  const pooledSamples = poolSampleEnvironments(samples, pooling, undefined) as FullSampleWithTime[];

  return pooledSamples;
}

function buildSingleOverviewLineSeries(siteSamples: FullSampleWithTime[]): SingleOverviewLineSeries {
  return (
    chain(siteSamples)
      // Always use monthly grouping
      .groupBy(sample => getSnappedTimeFields(sample).snappedTime)
      .mapValues(siteSamplesForTimePoint => buildOverviewDatum(siteSamplesForTimePoint))
      .values()
      .sortBy(datum => new Date(datum.date))
      .value()
  );
}

function buildOverviewDatum(siteSamplesForTimePoint: FullSampleWithTime[]): OverviewDatum {
  const numSubEnvs = chain(siteSamplesForTimePoint)
    .map(s => s.environment.subtype)
    .uniq()
    .value().length;

  if (numSubEnvs === 1) {
    // Normal case of single environment marker
    return buildOverviewDatumForEnv(siteSamplesForTimePoint);
  } else if (numSubEnvs === 2) {
    // TODO This logic fails if we have same lat lon, different sample name but both eg. raw water
    // Eg. try to re-analyse SIG 2 https://docs.google.com/spreadsheets/d/1tUG6tuB-iv4Q151LqldcoC0J4pKuTX8K-oH2-2QLg5I/edit?gid=2040372501#gid=2040372501
    const beforeSamples = siteSamplesForTimePoint.filter(s => isBeforeSample(s));
    const afterSamples = siteSamplesForTimePoint.filter(s => isAfterSample(s));
    if (beforeSamples.length + afterSamples.length !== siteSamplesForTimePoint.length) {
      throw Error(
        `Before samples.length ${beforeSamples.length} + ${afterSamples.length} != ${siteSamplesForTimePoint.length}`,
      );
    }
    const beforeDatum = buildOverviewDatumForEnv(beforeSamples);
    const afterDatum = buildOverviewDatumForEnv(afterSamples);
    return {
      ...beforeDatum,
      environmentAfter: afterDatum.environment,
      afterAbundances: afterDatum.abundances,
    };
  } else {
    throw Error();
  }
}

function buildOverviewDatumForEnv(grouppedSamples: FullSampleWithTime[]): OverviewDatum {
  const { snappedTime, formattedTime } = getSnappedTimeFields(grouppedSamples[0]);

  // Normal case of single environment marker
  const sample = grouppedSamples[0];
  const abundances = flatten(grouppedSamples.map(sampleLocal => sampleLocal.abundances));
  const { data } = getResistanceIndexData(abundances, undefined);

  return {
    ...data,
    date: snappedTime,
    abundances,
    label: formattedTime,
    environment: sample.environment,
    lat: sample.lat,
    lon: sample.lon,
    inferredLat: sample.inferredLat,
    inferredLon: sample.inferredLon,
    adminLevels: sample.adminLevels,
    city: sample.city,
    region: sample.region,
    country: sample.country,
  };
}

function getSnappedTimeFields({ time }: Pick<FullSampleWithTime, 'time'>) {
  const snappedTime = ensureUtcMonth(time);
  const formattedTime = friendlyMonth(snappedTime, 'postfixAlways');
  return { snappedTime: snappedTime.toISOString(), formattedTime };
}
