import { Box } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { oldCss } from '../shared/theme';

export const ItemBorderBox = styled(Box)`
  ${oldCss.greyBorder};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  padding: 4px 12px;
  word-break: break-word;
`;
