import { Box } from '@chakra-ui/react';
import { Filters } from '@resistapp/client/data-utils/filter-data/filter';
import { QueryFilters, getLowerCaseMatches } from '@resistapp/client/hooks/use-query-filters/use-query-filters';
import {
  EnvironmentType,
  EnvironmentTypeWithAllAndMatching,
  friendlyEnvironmentType,
} from '@resistapp/common/environment-types';
import { FullProject } from '@resistapp/common/types';
import { sortUniqEnvironments } from '@resistapp/common/utils';
import { InputActionMeta } from 'chakra-react-select';
import { uniq } from 'lodash';
import { useEffect, useState } from 'react';
import { ResistomapSelect, resistomapSelectClassNamePrefix } from '../../forms/resistomap-select';
import { theme } from '../../shared/theme';

interface Props {
  queryFilters: QueryFilters;
  sampling: FullProject;
}

export function SampleAndTypeSearch(props: Props) {
  const [inputValue, setInputValue] = useState('');
  const [menuOpen, setMenuOpen] = useState(false);
  const [selectionLabel, setSelectionLabel] = useState('');
  const environments = sortUniqEnvironments(props.sampling.samplesByUID);
  const noSamplesWithPhrase = 'No samples with phrase';

  // Note hacky extension of Select to a simple search field
  // - the label has to match the input to be shown
  useEffect(() => {
    if (inputValue) {
      const envNames = environments.map(env => env.name);
      const matchingEnvNames = getLowerCaseMatches(inputValue, envNames);
      const cnt = matchingEnvNames.length;

      const label =
        cnt > 1
          ? `Select ${cnt} samples with phrase '${inputValue.toLowerCase()}'`
          : cnt === 1
            ? `Select '${matchingEnvNames[0]}'`
            : `${noSamplesWithPhrase} '${inputValue.toLowerCase()}'`;

      setSelectionLabel(label);
    } else {
      setSelectionLabel(menuOpen ? 'Select all (or type to seach)' : 'All samples');
    }
  }, [inputValue, environments, menuOpen]);

  const environmentTypes = uniq(environments.map(env => env.type));
  const allOrSelectedOption: Option = {
    label: selectionLabel,
    value: EnvironmentTypeWithAllAndMatching.ALL,
  };
  const envTypeOptions: Option[] = environmentTypes.map(value => ({ label: friendlyEnvironmentType(value), value }));
  const options: Option[] = selectionLabel.startsWith(noSamplesWithPhrase)
    ? [...envTypeOptions, allOrSelectedOption]
    : [allOrSelectedOption, ...envTypeOptions];

  return (
    <Box style={{ width: '100%', backgroundColor: theme.colors.neutral50 }}>
      <ResistomapSelect<Option, false>
        options={options}
        size={'md'}
        isClearable={false}
        isDisabled={false}
        value={getSelectedOption(options, props.queryFilters.filters)}
        onChange={option => {
          if (option?.value !== EnvironmentTypeWithAllAndMatching.ALL) {
            props.queryFilters.setEnvironmentTypes(option?.value ? (option.value as EnvironmentType) : []);
          } else {
            const envNames = environments.map(env => env.name);
            const matchingEnvNames = inputValue ? getLowerCaseMatches(inputValue, envNames) : [];
            if (matchingEnvNames.length) {
              props.queryFilters.setMatchingEnvironmentsAcrossTypes(inputValue);
            } else {
              props.queryFilters.setEnvironmentTypes([]);
            }
          }
          setInputValue('');
        }}
        classNamePrefix={resistomapSelectClassNamePrefix}
        onInputChange={(newValue: string, actionMeta: InputActionMeta) => {
          if (actionMeta.action === 'input-change') {
            setInputValue(newValue);
          }
        }}
        onMenuOpen={() => {
          setMenuOpen(true);
        }}
        onMenuClose={() => {
          setMenuOpen(false);
        }}
        isOptionDisabled={(option, _selectValue) => option.label.startsWith(noSamplesWithPhrase)}
      />
    </Box>
  );
}

type Option = {
  label: string;
  value: (typeof EnvironmentTypeWithAllAndMatching)[keyof typeof EnvironmentTypeWithAllAndMatching];
};

function getSelectedOption(options: Option[], filters: Filters): Option {
  const envTypeOptions = options.filter(opt => Object.values(EnvironmentType).includes(opt.value as EnvironmentType));
  if (envTypeOptions.length === 1) {
    return options[0];
  }

  const selectedEnvironmentTypes = filters.selectedEnvironmentTypes;
  const moreThanOneButNotAllSelected =
    selectedEnvironmentTypes.length > 1 && selectedEnvironmentTypes.length < options.length - 1;
  if (moreThanOneButNotAllSelected) {
    return { label: 'Multiple env. types', value: EnvironmentTypeWithAllAndMatching.MULTIPLE };
  }

  return options.find(opt => opt.value === filters.singleSelectedEnvironmentType) || options[0];
}
