import { Button, FormControl, FormLabel, Input, Stack } from '@chakra-ui/react';
import { ResistomapSelect } from '@resistapp/client/components/forms/resistomap-select';
import { RSecondTitle } from '@resistapp/client/components/headers/r-second-title';
import { RThirdTitle } from '@resistapp/client/components/headers/r-third-title';
import { MaybeApiError } from '@resistapp/client/components/states/error';
import { useNavigateWithQuery } from '@resistapp/client/hooks/use-navigate-with-query';
import { PoolProjectRequest, PooledProjectCheckResponse, PoolingMode, PoolingType } from '@resistapp/common/api-types';
import { Targets, carbapenem } from '@resistapp/common/assays';
import { EnvironmentType } from '@resistapp/common/environment-types';
import { getCountryNameByAlpha3 } from '@resistapp/common/types';
import { useEffect, useState } from 'react';
import { FormView } from '../../components/shared/layout';
import { oldTheme } from '../../components/shared/old-styles';
import { useCountries, usePostPooledProject, useProjects } from '../../hooks/api';

export function ComposeView() {
  const navigate = useNavigateWithQuery();
  const { check, create } = usePostPooledProject();
  const { data: projects } = useProjects();
  const { data: countryByAlpha3 } = useCountries();
  const loading = check.isPending || create.isPending;
  const countryData = countryByAlpha3 && getCountryNameByAlpha3(countryByAlpha3);

  const countryOptions: CountryOptions = countryData
    ? Object.entries(countryData).map(([alpha3, countryName]) => ({ label: countryName, value: alpha3 }))
    : [];
  const projectOptions: ProjectOptions = projects ? projects.map(p => ({ label: p.name, value: p.id })) : [];

  // Form state
  const [name, setName] = useState('');
  const defaultExlude = '.*(ntc|outlet|effluent|outflow).*';
  const [excludeRegexStr, setExcludeRegexStr] = useState(defaultExlude);
  const [includeRegexStr, setIncludeRegexStr] = useState('');
  const [selectedTargetOptions, setSelectedTargetOptions] = useState<TargetOptions>([]);
  const [selectedEnvironmentTypeOptions, setSelectedEnvironmentTypeOptions] = useState<EnvTypeOptions>([]);
  const [selectedProjectOptions, setSelectedProjectOptions] = useState<ProjectOptions>([]);
  const [assaySetProjectOption, setAssaySetProjectOption] = useState<ProjectOption | null>(null);
  const [minAssays, setMinAssays] = useState<number | undefined>(undefined);
  const [selectedCountries, setSelectedCountries] = useState<CountryOptions>([]);
  const [analyzeStartDate, setAnalyzeStartDate] = useState<string | undefined>();
  const [analyzeEndDate, setAnalyzeEndDate] = useState<string | undefined>();
  const [selectedPoolingOption, setSelectedPoolingOption] = useState<PoolingOption | null>(null);

  useEffect(() => {
    if (create.data) {
      navigate(`/research/${create.data.id}`, false);
    }
  }, [create.data, navigate]);

  return (
    <FormView>
      <RSecondTitle>Create pooled project</RSecondTitle>
      <Stack spacing={5} width="full">
        <FormControl>
          <RThirdTitle>General</RThirdTitle>
          <FormLabel>Name for the pooled / virtual project</FormLabel>
          <Input
            required
            value={name}
            placeholder="Enter project name"
            isDisabled={loading}
            onChange={e => {
              setName(e.target.value);
              check.reset();
            }}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Pooling</FormLabel>
          <ResistomapSelect<PoolingOption, false>
            isClearable
            placeholder="None"
            options={[
              { label: 'By city', value: PoolingType.CITY },
              { label: 'By region', value: PoolingType.REGION },
              { label: 'By country', value: PoolingType.COUNTRY },
              { label: 'By environment type', value: PoolingType.ENVIRONMENT_TYPE },
            ]}
            value={selectedPoolingOption}
            isDisabled={loading}
            onChange={(selectedOption: PoolingOption | null) => {
              setSelectedPoolingOption(selectedOption);
              check.reset();
            }}
          />
        </FormControl>
        <FormControl>
          <RThirdTitle style={{ marginTop: oldTheme.spacing.xs }}>Assay set</RThirdTitle>
          <FormLabel>Limit to assay in project</FormLabel>
          <ResistomapSelect<ProjectOption, false>
            options={projectOptions}
            value={assaySetProjectOption}
            isDisabled={loading}
            onChange={(selectedOption: ProjectOption | null) => {
              setAssaySetProjectOption(selectedOption);
              check.reset();
            }}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Limit to assays with targets</FormLabel>
          <ResistomapSelect<TargetOptions[number], true>
            isMulti
            options={targetOptions}
            value={selectedTargetOptions}
            onChange={(selectedOptions: TargetOptions) => {
              setSelectedTargetOptions(selectedOptions);
              check.reset();
            }}
            isDisabled={loading}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Minimum assays to include</FormLabel>
          <Input
            min={2}
            max={384}
            onChange={e => {
              setMinAssays(Number(e.target.value));
              check.reset();
            }}
            isDisabled={loading}
          />
        </FormControl>
        <FormControl>
          <RThirdTitle style={{ marginTop: oldTheme.spacing.xs }}>Sample filters</RThirdTitle>
          <FormLabel>Only selected projects</FormLabel>
          <ResistomapSelect<ProjectOptions[number], true>
            closeMenuOnSelect={false}
            isMulti
            options={projectOptions}
            value={selectedProjectOptions}
            isDisabled={loading}
            onChange={(selectedOptions: ProjectOptions) => {
              setSelectedProjectOptions(selectedOptions);
              check.reset();
            }}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Only selected environment types</FormLabel>
          <ResistomapSelect<EnvTypeOptions[number], true>
            isMulti
            options={environmentTypeOptions}
            value={selectedEnvironmentTypeOptions}
            isDisabled={loading}
            onChange={(selectedOptions: EnvTypeOptions) => {
              setSelectedEnvironmentTypeOptions(selectedOptions);
              check.reset();
            }}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Only selected countries</FormLabel>
          <ResistomapSelect<CountryOption, true>
            closeMenuOnSelect={false}
            isMulti
            options={countryOptions}
            value={selectedCountries}
            isDisabled={loading}
            onChange={selectedOptions => {
              setSelectedCountries(selectedOptions);
              check.reset();
            }}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Exclude sample regex</FormLabel>
          <Input
            required
            value={excludeRegexStr}
            placeholder={excludeRegexStr}
            isDisabled={loading}
            onChange={e => {
              setExcludeRegexStr(e.target.value);
              check.reset();
            }}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Include sample regex</FormLabel>
          <Input
            required
            value={includeRegexStr}
            placeholder=""
            isDisabled={loading}
            onChange={e => {
              setIncludeRegexStr(e.target.value);
              check.reset();
            }}
          />
        </FormControl>
        <FormControl>
          <RThirdTitle style={{ marginTop: oldTheme.spacing.xs }}>Analyse time</RThirdTitle>
          <FormLabel>Analysed on/after</FormLabel>
          <Input
            type="date"
            isDisabled={loading}
            onChange={e => {
              setAnalyzeStartDate(e.target.value);
              check.reset();
            }}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Analysed before</FormLabel>
          <Input
            type="date"
            isDisabled={loading}
            onChange={e => {
              setAnalyzeEndDate(e.target.value);
              check.reset();
            }}
          />
        </FormControl>
        <FormControl id="submit">
          <Button
            style={{ marginTop: oldTheme.spacing.xxs }}
            isLoading={check.isPending || create.isPending}
            isDisabled={
              !name ||
              check.isError ||
              (!selectedProjectOptions.length && !selectedEnvironmentTypeOptions.length && !selectedCountries.length)
            }
            onClick={() => {
              const projectIds = selectedProjectOptions.map(p => p.value);
              const environmentTypes = selectedEnvironmentTypeOptions.map(e => e.value);
              const countries = selectedCountries.map(c => c.value);
              const targets = selectedTargetOptions.map(t => t.value);
              const payload: PoolProjectRequest = {
                name,
                minAssays,
                assaySetProjectId: assaySetProjectOption?.value,
                projectIds: projectIds.length ? projectIds : undefined,
                targets: targets.length ? targets : undefined,
                environmentTypes: environmentTypes.length ? environmentTypes : undefined,
                countries: countries.length ? countries : undefined,
                includeRegexStr,
                excludeRegexStr,
                analyzeStartDate,
                analyzeEndDate,
                pooling: selectedPoolingOption?.value
                  ? { type: selectedPoolingOption.value, mode: PoolingMode.SKIP_MISSING }
                  : undefined,
              };
              if (check.data) {
                create.mutate(payload);
                check.reset();
              } else {
                check.mutate(payload);
              }
            }}
          >
            {check.data ? 'Create' : 'Check'}
          </Button>
        </FormControl>
        <CheckResult data={check.data} />
      </Stack>
      <MaybeApiError error={create.error || check.error} />
    </FormView>
  );
}

interface CheckResultProps {
  data: PooledProjectCheckResponse | undefined;
}
function CheckResult(props: CheckResultProps) {
  if (!props.data) {
    return null;
  }
  return (
    <ul>
      {Object.entries(props.data).map(([key, value]) => (
        <li key={key}>{`${key}: ${value}`}</li>
      ))}
    </ul>
  );
}

const targetOptions: TargetOptions = [carbapenem, ...Object.values(Targets)].map(
  (value: Targets | typeof carbapenem) => ({
    label: value,
    value,
  }),
);
const environmentTypeOptions: EnvTypeOption[] = Object.values(EnvironmentType).map((value: EnvironmentType) => ({
  label: value,
  value,
}));

type TargetOption = { label: string; value: Targets | typeof carbapenem };
type TargetOptions = readonly TargetOption[];
type EnvTypeOption = { label: string; value: EnvironmentType };
type EnvTypeOptions = readonly EnvTypeOption[];
type ProjectOption = { label: string; value: number };
type ProjectOptions = readonly ProjectOption[];
type CountryOption = { label: string; value: string };
type CountryOptions = readonly CountryOption[];
type PoolingOption = {
  label: string;
  value: PoolingType.CITY | PoolingType.COUNTRY | PoolingType.REGION | PoolingType.ENVIRONMENT_TYPE;
};
