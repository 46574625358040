import { theme } from './theme';

export const oldTheme = {
  spacing: {
    min: theme.spacing[0.5],
    xxs: theme.spacing[2],
    xs: theme.spacing[3],
    s: theme.spacing[4],
    m: theme.spacing[6],
    l: '36px',
    xl: theme.spacing[16],
    xxl: '96px',
  },
  fontSize: { xxs: '8px', xs: theme.spacing[3], s: '14px', m: '16px', l: '20px', xl: '26px', xxl: '32px' },
  fontFamily: 'Inter', // replace both with jiperish to debug fonts
  fontFamilyBig: 'InterDisplay',
  fontFamilyDMSans: 'DM Sans',
  fontWeight: {
    light: `
      font-style: normal;
      font-weight: 300;
    `,
    regular: `
      font-style: normal;
      font-weight: 400;
    `,
    bold: `
      font-style: normal;
     font-weight: 700;
    `,
    heavy: `
      font-style: normal;
     font-weight: 900;
    `,
  },

  // Keep color palette on top level for convenience
  white: '#FFFFFF',
  transparent: 'rgba(255, 255, 255, 0.0)',
  blue: '#2E3EBE',
  lightBlue: '#919CF3',
  slightBlue: '#BEC5FF',
  pinkishBlue: '#AE9CF3',
  bluishPink: '#C79CF3',
  slightGray: '#C8C8DC',
  faintGray: '#F0F0F0',
  pink: '#EBD0DE',
  faintGreen: '#a5bab7',
  darkPink: '#AB6282',
  deepPink: '#B94282',
  darkGray: '#333333',
  dark: '#1A1A1A',
  cautionRed: '#550000',
  black: '#000000',
};
