import styled from '@emotion/styled';
import { useNavigateWithQuery } from '@resistapp/client/hooks/use-navigate-with-query';
import { forwardRef } from 'react';
import { theme } from '../shared/theme';

interface Props {
  text: string;
  src?: string;
  alt: string;
  link?: string;
}

export const ActionButton = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const navigate = useNavigateWithQuery();
  return (
    <ActionContainer
      key={props.text}
      ref={ref}
      link={props.link}
      onClick={() => {
        if (props.link) {
          navigate(props.link, true);
        }
      }}
    >
      {props.src && <IconDiv src={props.src} alt={props.alt} link={props.link} />}
      <div style={{ whiteSpace: 'nowrap' }}>{props.text}</div>
    </ActionContainer>
  );
});
ActionButton.displayName = 'ActionButton';

const ActionContainer = styled.div<{ selected?: boolean; link?: string }>`
  cursor: ${props => (props.link ? 'pointer' : 'default')};
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  ${props => (props.selected ? '' : theme.addBorder(300, 'full'))};
  background: ${props => (props.selected ? theme.colors.backgroundBlack : 'white ')};
  color: ${props => (props.link ? theme.colors.neutral900 : theme.colors.neutral300)};
  padding: 12px;
  padding-left: 24px;
  padding-right: 24px;
  font-weight: 501;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    ${props =>
      props.link &&
      `
      ${theme.addBorder(500, 'full')};
      img {
        filter: none;
      }
    `}
  }
`;

const ArrowContainer = styled.div`
  width: auto;
  height: auto;
  right: 31px;
  top: 24px;
`;
const IconDiv = ({ src, alt, link }: { src: string; alt: string; link: string | undefined }) => (
  <ArrowContainer>
    <img
      src={src}
      alt={alt}
      style={{
        paddingRight: '12px',
        display: 'flex',
        filter: 'grayscale(100)',
        color: link ? theme.colors.neutral900 : theme.colors.neutral300,
        maxHeight: '22px',
      }}
    />
  </ArrowContainer>
);
