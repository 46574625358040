import { ChakraProvider } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { MetricMode } from '@resistapp/common/types';
import { oldDemoProjectIds, rndProjectId } from '@resistapp/common/utils';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import 'mapbox-gl/dist/mapbox-gl.css';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { Navigate, Outlet, RouterProvider, createBrowserRouter, useLocation, useParams } from 'react-router-dom';
import { MobileSupportNotice } from './components/misc/mobile-support-notice';
import { Footer } from './components/page-layout/footer/footer';
import { HeaderBar } from './components/page-layout/header-bar/header-bar';
import { storedToken } from './components/shared/local-storage';
import { chakraTheme, theme } from './components/shared/theme';
import { ResearchProvider } from './contexts/research-context';
import { SampleDataProvider } from './contexts/sample-data-context';
import { UrlProvider } from './contexts/url-provider';
import { OverviewContextProvider } from './contexts/use-overview-context/use-overview-context';
import { UserContextProvider, useUser } from './contexts/use-user-context';
import { SetGlobalStyles } from './global-styles';
import { PathParams, usePathParam } from './hooks/use-path-params';
import { initHotjar } from './hotjar';
import './utils/sentry';
import { RootErrorView } from './views/RootErrorView';
import { AnalyseView } from './views/analyse-view/analyse-view';
import { ComposeView } from './views/compose-view/compose-view';
import { LanderView } from './views/lander-view';
import { LoginView } from './views/login-view';
import { CreateOrganizationView } from './views/organizations-view/create-organization-view';
import { CreateUserView } from './views/organizations-view/create-user-view';
import { OrganisationsView } from './views/organizations-view/organizations-view';
import { OverviewView } from './views/overview-view/overview-view';
import { ResearchView } from './views/research-view/research-view';
import { SignupView } from './views/signup/signup-view';
import { VerifyEmailView } from './views/signup/verify-email-view';
import { WorldView } from './views/world-view/world-view';

initHotjar();

const rootElement = document.createElement('div');
rootElement.id = 'content';
document.body.appendChild(rootElement);

const root = document.getElementById('root');
if (!root) {
  throw Error('Root element not found!');
}

const queryClient = new QueryClient();

function Layout({ children }: { children?: React.ReactNode }) {
  return (
    <ChakraProvider theme={chakraTheme}>
      <SetGlobalStyles />
      <UrlProvider>
        <QueryClientProvider client={queryClient}>
          <UserContextProvider>
            <MobileSupportNotice />
            <HeaderBar />
            {children ?? (
              <ZindexPositioner>
                <Outlet />
              </ZindexPositioner>
            )}
            {/* this div-element positions the main content on top, instead of center */}
            <div style={{ flexGrow: 1 }} />
            <Footer />
          </UserContextProvider>
        </QueryClientProvider>
      </UrlProvider>
    </ChakraProvider>
  );
}

function OldProjectRedirect({ children }: { children: React.ReactNode }) {
  const { projectId } = useParams();
  const oldIds = Object.values(oldDemoProjectIds);
  const location = useLocation();
  const { user, userLoading } = useUser();
  const token = storedToken();

  if (projectId && oldIds.includes(+projectId) && !token && !userLoading && !user) {
    const newPath = location.pathname.replace(`/${projectId}`, `/${rndProjectId}`);
    return <Navigate to={newPath} replace />;
  }

  return <>{children}</>;
}

function RedirectToResearch() {
  const projectId = usePathParam(PathParams.projectId);
  return <Navigate to={`/research/${projectId}`} replace />;
}

const router = createBrowserRouter([
  {
    element: <Layout />,
    errorElement: (
      <Layout>
        <RootErrorView />
      </Layout>
    ),
    children: [
      {
        path: '/',
        element: <LanderView />,
        // TODO loader?
      },
      {
        path: '/world',
        element: <WorldView />,
      },
      {
        path: `/signup/:${PathParams.signupCode}`,
        element: <SignupView />,
      },
      {
        path: `/verify-email`,
        element: <VerifyEmailView />,
      },
      {
        path: '/login',
        element: <LoginView />,
      },
      {
        path: '/user',
        element: <CreateUserView />,
      },
      {
        path: '/samplings',
        element: <Navigate to="/research" replace />,
      },
      {
        path: '/organization/create',
        element: <CreateOrganizationView />,
      },
      {
        path: '/admin',
        element: <OrganisationsView />,
      },
      {
        path: `/sampling/:${PathParams.projectId}`,
        element: <RedirectToResearch />,
      },
      {
        path: '/sampling',
        element: <Navigate to="/analyse" replace />,
      },
      {
        path: '/analyse',
        element: <AnalyseView />,
      },
      {
        path: '/compose',
        element: <ComposeView />,
      },
      {
        element: (
          <SampleDataProvider>
            <Outlet />
          </SampleDataProvider>
        ),
        children: [
          {
            path: `/index/:${PathParams.projectId}?`,
            element: (
              <OldProjectRedirect>
                <OverviewContextProvider metricMode={MetricMode.ARGI}>
                  <OverviewView />
                </OverviewContextProvider>
              </OldProjectRedirect>
            ),
          },
          {
            path: `/risk/:${PathParams.projectId}?`,
            element: (
              <OldProjectRedirect>
                <OverviewContextProvider metricMode={MetricMode.RISK}>
                  <OverviewView />
                </OverviewContextProvider>
              </OldProjectRedirect>
            ),
          },
          {
            path: '/reduction/:projectId?',
            element: (
              <OldProjectRedirect>
                <OverviewContextProvider metricMode={MetricMode.REDUCTION}>
                  <OverviewView />
                </OverviewContextProvider>
              </OldProjectRedirect>
            ),
          },
          {
            path: `/research/:${PathParams.projectId}`,
            element: (
              <OldProjectRedirect>
                <ResearchProvider>
                  <ResearchView />
                </ResearchProvider>
              </OldProjectRedirect>
            ),
          },
          {
            path: `/overview/:${PathParams.projectId}?`,
            element: <Navigate to={location.pathname.replace(/^\/overview\//, '/index/')} replace />,
          },
        ],
      },
    ],
  },
]);

ReactDOM.createRoot(root).render(
  <StrictMode>
    <RouterProvider router={router} />
  </StrictMode>,
);

const ZindexPositioner = styled.div`
  z-index: ${theme.zIndexes.mainSection};
  position: relative;
`;
