import styled from '@emotion/styled';
import noticeIconSrc from '../../assets/icon/notice.svg';

export function NoticeIcon() {
  return (
    <StyledIcon src={ noticeIconSrc } />
  );
}

const StyledIcon = styled.img`
  font-size: 13px;
  height: auto;
  padding-top: 2px;
  padding-bottom: 2px;
`;