import { ResistanceLevel } from '@resistapp/common/statistics/resistance-index';
import { theme } from '../components/shared/theme';

interface ResistanceLevelDetails {
  title: string;
  shortTitle: string;
  color: (typeof theme.colors)[keyof typeof theme.colors];
  textColor: string;
}
export const resistanceLevelMetadata: { [key in ResistanceLevel]: ResistanceLevelDetails } = {
  low: {
    title: 'ANTHROPOGENIC BASELINE',
    shortTitle: 'ANTHROPOGENIC',
    color: theme.colors.levelLow,
    textColor: '#FFFFFF', // E6EAFF light blue with proper contrast against #5874BB
  },
  moderate: {
    title: 'ELEVATED',
    shortTitle: 'ELEVATED',
    color: theme.colors.levelMod,
    textColor: '#FFFFFF', // F8E0E8
  },
  high: {
    title: 'HIGH',
    shortTitle: 'HIGH',
    color: theme.colors.levelHigh,
    textColor: '#FFFFFF', // F8E0E8 light pink with sufficient contrast against #4C2B3A
  },
} as const;

export function friendlyResistanceLevelShortTitle(level: ResistanceLevel) {
  return resistanceLevelMetadata[level].shortTitle;
}
