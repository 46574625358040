import styled from '@emotion/styled';
import { useOverviewContext } from '@resistapp/client/contexts/use-overview-context/use-overview-context';
import { resistanceLevelMetadata } from '@resistapp/client/data-utils/resistance-level';
import { capitalizeOnlyWords } from '@resistapp/common/friendly';
import { References, useScienceReference } from '../../../hooks/use-science-reference';
import { MetricTitle } from '../../shared/metric-title';
import { oldTheme } from '../../shared/old-styles';
import { LevelInfo } from './level-info';

interface Props {
  name: string;
  target: string | undefined;
  area: 'area' | 'location' | 'area or location';
}

export function IndexInfo(props: Props) {
  const { name, area } = props;
  const { selectedAntibiotic, metricGeneCnt, isOneHealthProject } = useOverviewContext();
  const indexHeight = 420;
  const acronym = !isOneHealthProject && !selectedAntibiotic ? 'ARGI' : '';
  const acronymOrName = !selectedAntibiotic && acronym ? acronym : `${name} Index`;

  const panelDescription = isOneHealthProject
    ? `a standardised set of ${metricGeneCnt} highly relevant ${selectedAntibiotic ? `${selectedAntibiotic} resistance genes` : 'ARGs'} in Resistomap's OneHealth Panel, making in globally comparable accross sampling projects`
    : `a custom set of ${metricGeneCnt} ARGs`;

  const [Ref1, Ref1Num] = useScienceReference({
    number: '1',
    href: 'https://doi.org/10.1016/j.envint.2023.108084',
    text: 'A. Abramova et. al., 2023, A global baseline for qPCR-determined antimicrobial…',
  });

  return (
    <div>
      <InfoSection>
        <i>
          <MetricTitle noColor noBold />
          {acronym ? ` (${acronym}) ` : ' '}
        </i>
        represents the {selectedAntibiotic ? `${selectedAntibiotic} resistance gene` : 'overall ARG'} load in the
        sampled {area}.
      </InfoSection>
      <InfoSection>
        <b>
          <MetricTitle noColor /> is defined as:
        </b>
        <MathIntend>
          <code>
            {acronym}&nbsp;=&nbsp;5&nbsp;+&nbsp;mean(log<sub>10</sub>(r<sub>a</sub>))
          </code>
          ,
        </MathIntend>
        where{' '}
        <code>
          r<sub>a</sub>
        </code>{' '}
        is the abundance of an ARG, relative to the abundance of the 16S&nbsp;rRNA&nbsp;gene. The index is calculated as
        the average of the exponents of relative abundances of {panelDescription}. {acronymOrName} is defined in the
        range from&nbsp;0&nbsp;to&nbsp;5.
      </InfoSection>
      <InfoSection>
        <InfoSection>
          <b>{name} Levels</b>
        </InfoSection>
        The {acronymOrName} range is divided into three <i>{name} Levels</i>:{' '}
        <i>{capitalizeOnlyWords(resistanceLevelMetadata.low.title)}</i>,{' '}
        <i>{capitalizeOnlyWords(resistanceLevelMetadata.moderate.title)}</i>, and{' '}
        <i>{capitalizeOnlyWords(resistanceLevelMetadata.high.title)}</i>. The below graph illustrates the different
        levels and their biological meaning:
      </InfoSection>
      <LevelInfo indexHeight={indexHeight} Ref1Num={Ref1Num} />
      <InfoSection>
        <References style={{ marginTop: 60 }}>
          <Ref1 />
        </References>
      </InfoSection>
    </div>
  );
}

const InfoSection = styled.div`
  padding-bottom: ${oldTheme.spacing.xs};
`;

export const MathIntend = styled.div`
  padding-left: ${oldTheme.spacing.m};
  padding-top: ${oldTheme.spacing.xs};
  padding-bottom: ${oldTheme.spacing.xs};
`;
