import styled from '@emotion/styled';
import { useOverviewContext } from '@resistapp/client/contexts/use-overview-context/use-overview-context';
import { AxisLeft, TickRendererProps } from '@visx/axis';
import { Group } from '@visx/group';
import { ScaleLinear } from 'd3-scale';
import { positioning, strokeColor } from '../../plots/trendchart/chart/chart-styles';
import {
  LeftLegendAndChartContainer,
  TrendChartContainerWithLegends,
} from '../../plots/trendchart/chart/chart-types/base-chart';
import { ResistanceIndexLegend } from '../../plots/trendchart/chart/resistance-index-legend';
import { getResistanceIndexScale } from '../../plots/trendchart/chart/scales';
import { perpareLeftAxisTick } from '../../plots/trendchart/chart/ticks';
import { theme } from '../../shared/theme';

interface LevelInfoProps {
  indexHeight: number;
  Ref1Num: React.FC<React.SVGProps<SVGSVGElement>>;
}
export function LevelInfo(props: LevelInfoProps) {
  const { indexHeight, Ref1Num } = props;
  const graphHeight = indexHeight - positioning.margin.top - positioning.margin.bottom;

  const { metricMode } = useOverviewContext();
  const resistanceIndexScale = getResistanceIndexScale(graphHeight);

  return (
    <TrendChartContainerWithLegends>
      <LeftLegendAndChartContainer>
        <RnHeader>
          <RnHeaderCol>
            r<sub>a</sub>
          </RnHeaderCol>
          <RnHeaderCol>RI</RnHeaderCol>
        </RnHeader>
        {/* Resistance Level color boxes  */}
        <ResistanceIndexLegend left={positioning.margin.legendLeft + 60} height={indexHeight} width={40} />{' '}
        <svg width={300} style={{ minHeight: graphHeight, overflow: 'visible' }}>
          <Group left={positioning.margin.left} top={positioning.margin.top / 2}>
            {/* Resistance index labels, similarly to trend chart  */}
            <AxisLeft
              scale={resistanceIndexScale}
              hideAxisLine={true}
              left={-10}
              tickStroke={strokeColor}
              numTicks={5}
              axisClassName="trendchart-legend-label trendchart-legend-label-left"
              tickComponent={perpareLeftAxisTick(metricMode)}
            />
            {/* Abuse Axis left to draw corresponding abundance levels */}
            <AxisLeft
              scale={resistanceIndexScale}
              hideAxisLine={true}
              left={-100}
              numTicks={5}
              tickStroke={'rgba(0, 0, 0, 0)'}
              axisClassName="trendchart-legend-label trendchart-legend-label-left"
              tickComponent={(data: TickRendererProps) => {
                const { formattedValue } = data;
                if (!formattedValue) {
                  return null;
                }
                return (
                  <foreignObject
                    y={data.y - 10}
                    x={data.x}
                    width="100" // Ensure this is enough space for your content
                    height="30" // Ensure this is enough space for your content
                    textAnchor="start"
                  >
                    {RmLabel(formattedValue)}
                  </foreignObject>
                );
              }}
            />

            <foreignObject x={62} y={resistanceIndexScale(4) - 10} width="400" height="20">
              <LevelReference>Rel. abundance in human gut during antibiotic treatment.</LevelReference>
            </foreignObject>
            <LowLevelBracket resistanceIndexScale={resistanceIndexScale} />
            <foreignObject x={62} y={resistanceIndexScale(1) - 30} width="400" height="40">
              <LevelReference>Global baseline in anthropogenic (ie. human impacted)</LevelReference>
            </foreignObject>
            <foreignObject x={62} y={resistanceIndexScale(1) - 10} width="400" height="20">
              <LevelReference>environments across 30 different countries and </LevelReference>
            </foreignObject>
            <foreignObject x={62} y={resistanceIndexScale(1) + 10} width="400" height="20">
              <LevelReference>
                12 sample types in a time span from 2001 to 2020.
                <Ref1Num />
              </LevelReference>
            </foreignObject>
          </Group>
        </svg>
      </LeftLegendAndChartContainer>
    </TrendChartContainerWithLegends>
  );
}

function LowLevelBracket(props: { resistanceIndexScale: ScaleLinear<number | undefined, number | undefined> }) {
  const lowTopY = props.resistanceIndexScale(2) as number;
  const lowBottomY = props.resistanceIndexScale(0) as number;
  const bracketWidth = 10;
  const bracketX = 50;
  const bracketPath = `M${bracketX},${lowTopY} 
  q${bracketWidth / 2},0 ${bracketWidth / 2},${(lowBottomY - lowTopY) / 2} 
  q0,${(lowBottomY - lowTopY) / 2} -${bracketWidth / 2},${(lowBottomY - lowTopY) / 2}`;
  return <path d={bracketPath} fill="none" stroke="black" strokeWidth="1" />;
}

function RmLabel(formatedValue: string) {
  switch (formatedValue) {
    case '5.0':
      return <RnDiv>&ge; 1.0</RnDiv>;
    case '4.0':
      return <RnDiv style={{ marginLeft: 14 }}>0.1</RnDiv>;
    case '3.0':
      return (
        <RnDiv style={{ marginLeft: 14 }}>
          10<sup>-2</sup>
        </RnDiv>
      );
    case '2.0':
      return (
        <RnDiv style={{ marginLeft: 14 }}>
          10<sup>-3</sup>
        </RnDiv>
      );
    case '1.0':
      return (
        <RnDiv style={{ marginLeft: 14 }}>
          10<sup>-4</sup>
        </RnDiv>
      );
    case '0.0':
      return (
        <RnDiv>
          &le; 10<sup>-5</sup>
        </RnDiv>
      );
    default:
      console.error('Unexpected formatedValue', formatedValue);
      return null;
  }
}

const LevelReference = styled.div`
  font-size: ${theme.oldFontSize.xs};
`;

const RnHeader = styled.div`
  margin-left: 10px;
  margin-bottom: 4px;
  width: 66px;
  display: flex;
  justify-content: space-between;
  font-weight: 800;
`;

const RnDiv = styled.div`
  color: ${theme.colors.neutral800};
  font-family: ${theme.fontFamily.base};
  font-size: ${theme.fontSize.medium};
  font-weight: 'normal';
`;

const RnHeaderCol = styled(RnDiv)`
  font-size: 15px;
  font-weight: 800;
`;
