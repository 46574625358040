import { Box } from '@chakra-ui/react';
import { Target } from '@resistapp/common/assays';
import { MetricMode } from '@resistapp/common/types';
import { useState } from 'react';
import { IntegratedTextSelect } from '../selects/integrated-text-select';
import { MetricTitle } from '../shared/metric-title';
import { getGroupColor, getGroupTextColor } from '../shared/palettes';
import { theme } from '../shared/theme';
import { allAntibiotics, Option, TargetOrAll } from './types';

interface AntibioticSelectorProps {
  onChange: (value: Option | null) => void;
  selectedAntibiotic: Option;
  analyzedAntibioticGroups: TargetOrAll[];
  metricMode: MetricMode;
}

export function AntibioticSelector({
  onChange,
  selectedAntibiotic,
  analyzedAntibioticGroups,
}: AntibioticSelectorProps) {
  const [selectActive, setSelectActive] = useState(false);

  return (
    <Box
      onClick={() => {
        !selectActive && setSelectActive(true);
      }}
      style={{ cursor: 'pointer' }}
    >
      <IntegratedTextSelect<Option>
        options={analyzedAntibioticGroups.map(target => ({
          value: target,
          label: target,
          textColor: target === allAntibiotics ? theme.colors.neutral800 : getGroupTextColor(target, 'antibiotic'),
          bgColor: target === allAntibiotics ? 'white' : getGroupColor(target, 'antibiotic'),
        }))}
        value={selectedAntibiotic}
        size="sm"
        menuIsOpen={selectActive}
        onChange={selectedOption => {
          onChange(selectedOption);
          setSelectActive(false);
        }}
        onMenuClose={() => {
          setSelectActive(false);
        }}
        renderOption={option => (
          <MetricTitle
            antibioticOverride={option.value === allAntibiotics ? null : (option.value as Target)}
            style={{
              color: option.textColor,
              backgroundColor: option.bgColor,
            }}
          />
        )}
      >
        <span style={{ wordWrap: 'normal', whiteSpace: 'nowrap' }}>
          <MetricTitle
            antibioticOverride={
              selectedAntibiotic.value === allAntibiotics ? null : (selectedAntibiotic.value as Target)
            }
            style={{ textDecoration: 'underline dotted' }}
          />
        </span>
      </IntegratedTextSelect>
    </Box>
  );
}
