import { Box } from '@chakra-ui/react';
import { getMarkerSize, ProcessMarker } from '@resistapp/client/components/map/markers/process-marker';
import { useSampleDataContext } from '@resistapp/client/contexts/sample-data-context';
import { OverviewDatum } from '@resistapp/client/data-utils/plot-data/build-overview-line-data';
import { getMetricAndLevel, metricRange } from '@resistapp/client/utils/metric-utils';
import { ensureUtcMonth, utcStartOfNextMonth } from '@resistapp/common/friendly';
import { AdminArea, getAllOriginalEnvironmentNames, MetricMode } from '@resistapp/common/types';
import { useTooltip } from '@visx/tooltip';
import { ScaleTime } from 'd3-scale';
import { isNil } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { chartLeftMargin, positioning } from './chart-styles';
import { getGradientScale, getResistanceIndexScale } from './scales';

export function useOverviewTooltip(
  selectedOrHoveredAreaOrSiteEnvId: number | undefined,
  setSelectedOrHoveredAreaOrSiteEnvId: (id: number) => void,
  trendData: OverviewDatum[][] | undefined,
  timeScale: ScaleTime<number | undefined, number | undefined> | undefined,
  metricMode: MetricMode,
  shownAdminArea: number | null | undefined,
  changeZoomedAdminArea: (adminArea: AdminArea | null | undefined) => void,
  selectedSiteDatum: OverviewDatum | undefined,
) {
  const { queryFilters } = useSampleDataContext();
  const { tooltipData, tooltipLeft, tooltipTop, tooltipOpen, showTooltip, hideTooltip } = useTooltip<OverviewDatum>();
  const envRef = useRef(selectedOrHoveredAreaOrSiteEnvId);
  const [trendChartSizeForTooltip, setTrendChartSizeForTooltip] = useState({ width: 0, height: 0 });

  const positionTooltip = useCallback(
    (datum: OverviewDatum) => {
      const [metric] = getMetricAndLevel(
        datum.abundances,
        datum.afterAbundances,
        queryFilters.filters.selectedTargets,
        metricMode,
      );

      if (isNil(metric)) {
        return;
      }
      if (!timeScale) {
        throw Error('Unexpectedly moved mouse without timescale');
      }

      const graphHeight = trendChartSizeForTooltip.height - positioning.margin.top - positioning.margin.bottom;
      const indexScale =
        metricMode === MetricMode.ARGI
          ? getResistanceIndexScale(graphHeight)
          : getGradientScale(graphHeight, [metricRange[metricMode].min, metricRange[metricMode].max]);
      const shadowWidth = 2;
      const markerSize = getMarkerSize(datum, metricMode);
      const leftOffset = markerSize.width / 2 + shadowWidth - markerSize.chartOffset;
      const topOffset = markerSize.height - 2 * shadowWidth;
      const b = indexScale(metric);

      showTooltip({
        tooltipLeft: (timeScale(new Date(datum.date.toString())) ?? 0) + leftOffset,
        tooltipTop: b - topOffset,
        tooltipData: datum,
      });
    },
    [timeScale, showTooltip],
  );

  useEffect(() => {
    if (timeScale && trendData && envRef.current !== selectedOrHoveredAreaOrSiteEnvId) {
      envRef.current = selectedOrHoveredAreaOrSiteEnvId;
      const envData = trendData.find(data => data[0]?.environment.id === selectedOrHoveredAreaOrSiteEnvId);
      const lastDatum = envData?.length && envData[envData.length - 1];
      if (lastDatum) {
        positionTooltip(lastDatum);
      }
    }
  }, [positionTooltip, showTooltip, trendData, timeScale, selectedOrHoveredAreaOrSiteEnvId]);

  const mouseMoveHandler = (data: OverviewDatum[], event: React.MouseEvent<SVGElement>) => {
    const { offsetX } = event.nativeEvent;

    if (!timeScale) {
      throw Error('Unexpectedly moved mouse without timescale');
    }

    const closestDatum = data.reduce((prev, curr) => {
      const currentTimePoint = timeScale(new Date(curr.date));
      const previousTimePoint = timeScale(new Date(prev.date));
      if (isNil(currentTimePoint) || isNil(previousTimePoint)) {
        console.error(`Unexpectedly nill time`, typeof curr, curr, curr.date, typeof prev, prev, prev.date);
        return prev;
      }
      const currentXPoint = currentTimePoint + chartLeftMargin;
      const previousXPoint = previousTimePoint + chartLeftMargin;

      return Math.abs(currentXPoint - offsetX) < Math.abs(previousXPoint - offsetX) ? curr : prev;
    });

    positionTooltip(closestDatum);
    setSelectedOrHoveredAreaOrSiteEnvId(closestDatum.environment.id);
  };

  const mouseClickHandler = useCallback(
    (data: OverviewDatum) => {
      // If admin areas are active
      if (selectedSiteDatum) {
        const startOfMonth = ensureUtcMonth(data.date);
        const startOfNextMonth = utcStartOfNextMonth(startOfMonth);
        queryFilters.setInterval(startOfMonth, startOfNextMonth);
      } else if (shownAdminArea) {
        const nextAdminLevel = Object.values(data.adminLevels ?? {}).find(d => Number(d.level) === shownAdminArea);
        if (nextAdminLevel) {
          changeZoomedAdminArea(nextAdminLevel);
        }
      } else {
        const envName = data.environment.name;
        !isEnvironmentSelected(envName, queryFilters.filters.selectedEnvironmentNamesOrdered) &&
          queryFilters.toggleEnvironment(getAllOriginalEnvironmentNames(data.environment, data.environmentAfter), true);
      }
    },
    [queryFilters, shownAdminArea, changeZoomedAdminArea],
  );

  const onMouseLeave = () => {
    hideTooltip();
  };

  const onMouseEnter = () => {};

  const TooltipComponentForARGIMarker = useCallback(() => {
    if (!tooltipOpen || !tooltipData) {
      return null;
    }

    return (
      <Box position="absolute" top={tooltipTop} left={(tooltipLeft || 0) + 20} id="tooltip-component">
        <ProcessMarker
          overviewDatum={tooltipData}
          position="chart"
          metricMode={metricMode}
          onClick={() => {
            mouseClickHandler(tooltipData);
          }}
        />
      </Box>
    );
  }, [tooltipOpen, tooltipData, tooltipLeft, tooltipTop, mouseClickHandler, metricMode]);
  const TooltipComponentForRiskScore = TooltipComponentForARGIMarker;
  const TooltipComponentForReduction = TooltipComponentForARGIMarker;

  return {
    mouseMoveHandler,
    mouseClickHandler,
    onMouseLeave,
    onMouseEnter,
    TooltipComponentForARGIMarker,
    TooltipComponentForRiskScore,
    TooltipComponentForReduction,
    hideTooltip,
    positionTooltip,
    setTrendChartSizeForTooltip,
  };
}

function isEnvironmentSelected(name: string, selectedEnvironmentNamesOrdered: string[]) {
  return name === selectedEnvironmentNamesOrdered[0] && selectedEnvironmentNamesOrdered.length === 1;
}
