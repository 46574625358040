import { fetchOrThrow } from '@resistapp/client/hooks/api';
import { Organisation } from '@resistapp/common/types';
import { ResistomapSelect } from '../forms/resistomap-select';

interface Option {
  label: string;
  value: number;
}

export function AddOrganizationAccessDropdown({
  organizations,
  projectId,
}: {
  organizations: Organisation[];
  projectId: number;
}) {
  const options: Option[] = organizations.map(org => ({ label: org.name, value: org.id }));
  return (
    <div>
      <span style={{ fontWeight: 701 }}>Grant access to:</span>
      <ResistomapSelect<(typeof options)[number], false>
        placeholder="-"
        options={options}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onChange={async changingOption => {
          const selectedOption = options.find(option => {
            return option.value.toString() === changingOption?.value.toString();
          });

          if (selectedOption && selectedOption.value) {
            try {
              const { status } = await fetchOrThrow(
                'POST',
                `/organization/${selectedOption.value}/project/${projectId}`,
              );

              if (status === 200) {
                window.location.reload();
              } else {
                alert(`Adding access failed with status ${status}`);
              }
            } catch (e) {
              alert('Adding access failed');
            }
          }
        }}
      />
    </div>
  );
}
