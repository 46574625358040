import styled from '@emotion/styled';
import { oldCss, theme } from '../../shared/theme';

export const ChartGroupContainer = styled.div<{ noborder?: boolean }>`
  ${props => (props.noborder ? '' : oldCss.greyBorder)};
  background-color: ${props => (props.noborder ? '' : 'white')};
  padding: ${theme.oldSpacing.s};
  padding-top: ${theme.oldSpacing.xs};
  margin: 15px;
`;
