import styled from '@emotion/styled';
import { theme } from '@resistapp/client/components/shared/theme';
import {
  friendlyResistanceLevelShortTitle,
  resistanceLevelMetadata,
} from '@resistapp/client/data-utils/resistance-level';
import { ResistanceLevel } from '@resistapp/common/statistics/resistance-index';
import { positioning } from './chart-styles';

interface Props {
  height: number;
  width: number;
  left: number;
}

export function ResistanceIndexLegend(props: Props) {
  return (
    <LegendColorContainer {...props} id="resistance-index-legend">
      {colorLegends.map(legend => (
        <LegendColor
          bgcolor={legend.bgcolor}
          color={legend.color}
          style={{ height: legend.height }}
          key={legend.textShort}
        >
          <LegendColorText>{legend.textLong}</LegendColorText>
        </LegendColor>
      ))}
    </LegendColorContainer>
  );
}

const LegendColor = styled.div<{ bgcolor: string; color: string }>`
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-radius: 5px;
  background-color: ${props => props.bgcolor};
  color: ${props => props.color};
`;

const LegendColorText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  font-weight: 501;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
`;

const colorLegends = [
  {
    height: '20%',
    color: resistanceLevelMetadata.high.textColor,
    bgcolor: theme.colors.levelHigh,
    textShort: friendlyResistanceLevelShortTitle(ResistanceLevel.high),
    textLong: friendlyResistanceLevelShortTitle(ResistanceLevel.high),
  },
  {
    height: '40%',
    color: resistanceLevelMetadata.moderate.textColor,
    bgcolor: theme.colors.levelMod,
    textShort: friendlyResistanceLevelShortTitle(ResistanceLevel.moderate),
    textLong: friendlyResistanceLevelShortTitle(ResistanceLevel.moderate),
  },
  {
    height: '40%',
    color: resistanceLevelMetadata.low.textColor,
    bgcolor: theme.colors.levelLow,
    textShort: friendlyResistanceLevelShortTitle(ResistanceLevel.low),
    textLong: friendlyResistanceLevelShortTitle(ResistanceLevel.low),
  },
];

const LegendColorContainer = styled.div<Props>`
  position: absolute;
  left: ${props => props.left}px;
  display: flex;
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  justify-content: center;
  flex-direction: column;
  gap: 5px;
  padding-top: ${positioning.margin.top / 2}px;
  padding-bottom: ${positioning.margin.bottom + 20}px;
`;
