import { ProjectStatus } from '@resistapp/common/types';
import { theme } from './theme';

export function getStatusColors(status: ProjectStatus | string) {
  return status === (ProjectStatus.APPROVED as string)
    ? { bg: 'green', text: 'white' }
    : status === (ProjectStatus.DRAFT as string)
      ? { bg: theme.colors.neutral500, text: 'white' }
      : status === (ProjectStatus.APPROVED_WITH_ISSUES as string)
        ? { bg: 'orange', text: theme.colors.neutral900 }
        : status === (ProjectStatus.FAILED as string)
          ? { bg: 'red', text: theme.colors.neutral900 }
          : {
              bg: theme.colors.neutral100,
              text: theme.colors.neutral900,
              border: `1px solid ${theme.colors.neutral900}`,
            };
}

export const dotStylesForSelect = (
  color = 'transparent',
  textColor = 'transparent',
  content = '',
  border = 'inherit',
) => ({
  position: 'relative',
  alignItems: 'center',
  display: 'flex',

  ':before': {
    backgroundColor: color,
    color: textColor,
    borderRadius: '50%',
    fontSize: theme.fontSize.small,
    lineHeight: 2,
    content: `"${content}"`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: theme.spacing[2],
    height: 6,
    minHeight: 6,
    width: 7,
    minWidth: 7,
    border,
  },
});
