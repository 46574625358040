import { getTarget, Target } from '@resistapp/common/assays';
import { filterMinorTarget, isMinorTarget, MinorTarget } from '@resistapp/common/assays-temp-96-gene-minor-targets';
import { PartialAbundance, WithAbundances } from '@resistapp/common/statistics/resistance-index';
import { MetricMode } from '@resistapp/common/types';
import { filterDetected } from '@resistapp/common/utils';
import { chain, isNil, keys, mean } from 'lodash';

export interface DetectedAndAnalysedCounts {
  detectedCount: number;
  analysedCount: number;
}

export function getNumDetectedAndAnalysedAssays(
  overviewDatum: WithAbundances,
  target: Target | MinorTarget,
  metricMode: MetricMode,
): DetectedAndAnalysedCounts {
  const abundances = getMainAbundances(overviewDatum, metricMode);
  const targetedAbundancesByAssay = targetAbundancesByAssay(abundances, target);
  const analysedCount = keys(targetedAbundancesByAssay).length;
  const detectedCount = chain(targetedAbundancesByAssay)
    .mapValues(filterDetected)
    .pickBy(abs => abs.length)
    .keys()
    .value().length;
  return { detectedCount, analysedCount };
}

export function getAnalysedAssays(overviewDatum: WithAbundances, target: Target | MinorTarget, metricMode: MetricMode) {
  const abundances = getMainAbundances(overviewDatum, metricMode);
  const targetedAbundancesByAssay = targetAbundancesByAssay(abundances, target);
  return chain(targetedAbundancesByAssay).values().flatten().value();
}

export function targetAbundancesByAssay<T extends PartialAbundance>(
  abundances: T[],
  target: Target | MinorTarget,
  includeAY1: boolean = false,
): Record<string, T[]> {
  if (isMinorTarget(target)) {
    return chain(filterMinorTarget(abundances, target, includeAY1))
      .groupBy(d => d.assay)
      .value();
  } else {
    return chain(abundances)
      .filter(abundance => getTarget(abundance.gene) === target || (includeAY1 && abundance.assay === 'AY1'))
      .groupBy(d => d.assay)
      .value();
  }
}

export interface GeneAndCopyNumber {
  assay: string;
  gene: string;
  copyNumber: number;
  reduction?: number | null;
}

export function getGenesAndCopyNumbers(
  overviewDatum: WithAbundances,
  target: Target | MinorTarget,
  metricMode: MetricMode,
): GeneAndCopyNumber[] {
  const abundances = getMainAbundances(overviewDatum, metricMode);
  const targetedAbundancesByAssay = targetAbundancesByAssay(abundances, target);
  return chain(targetedAbundancesByAssay)
    .pickBy(data => data.some(d => !isNil(d.absolute)))
    .mapValues((assayAbundances, assay) => ({
      assay,
      gene: assayAbundances[0].gene,
      copyNumber: mean(assayAbundances.map(({ absolute }) => absolute)),
    }))
    .values()
    .value();
}

export function getMainAbundances<T extends WithAbundances>(overviewDatum: T, metricMode: MetricMode): T['abundances'] {
  return metricMode === MetricMode.RISK
    ? ((overviewDatum.afterAbundances || overviewDatum.abundances) satisfies T['abundances'])
    : (overviewDatum.abundances satisfies T['abundances']);
}

export function getMainMetricLabel(overviewDatum: WithAbundances, metricMode: MetricMode) {
  return metricMode === MetricMode.REDUCTION
    ? undefined
    : metricMode === MetricMode.RISK && overviewDatum.afterAbundances
      ? 'effluent'
      : 'raw';
}
