import styled from '@emotion/styled';
import LogoSource from '@resistapp/client/assets/Resistomap-new@3x.png';
import { theme } from '@resistapp/client/components/shared/theme';

export function SignupLogo() {
  return (
    <SignupLogoContainer>
      <Logo alt="Resistomap" src={LogoSource as string} />
    </SignupLogoContainer>
  );
}

const SignupLogoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 60px;
`;

const Logo = styled.img`
  width: 300px;
  padding-right: 5px;
  padding-left: 5px;
  padding-top: 13.5px;
  padding-bottom: 13.5px;
  margin: 0;
  background-color: ${theme.colors.neutral50};
`;
