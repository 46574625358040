import { useOverviewContext } from '@resistapp/client/contexts/use-overview-context/use-overview-context';
import { Filters } from '@resistapp/client/data-utils/filter-data/filter';
import { getResistanceGeneIndexAndLevel } from '@resistapp/common/statistics/resistance-index';
import { isNil } from 'lodash';
import { positioning } from '../chart-styles';
import { ResistanceIndexLegend } from '../resistance-index-legend';
import { getResistanceIndexScale } from '../scales';
import { BaseChart } from './base-chart';

interface ArgiChartProps {
  width: number;
  height: number;
  queryFilters: {
    filters: Pick<Filters, 'selectedTargets'>;
  };
}

export function ArgiChart(props: ArgiChartProps) {
  const { height, queryFilters, ...restProps } = props;
  const graphHeight = height - positioning.margin.top - positioning.margin.bottom;
  const resistanceIndexScale = getResistanceIndexScale(graphHeight);
  const {
    trenchartTooltip: { TooltipComponentForARGIMarker },
  } = useOverviewContext();

  return (
    <BaseChart
      {...restProps}
      height={height}
      indexScale={resistanceIndexScale}
      Legend={ResistanceIndexLegend}
      numTicks={6}
      getIndexValue={d => {
        const { resistanceIndex } = getResistanceGeneIndexAndLevel(d.abundances, queryFilters.filters.selectedTargets);
        return isNil(resistanceIndex) ? undefined : resistanceIndexScale(resistanceIndex);
      }}
      showData={{ quartileRange: true, referenceLines: true }}
      TooltipComponent={TooltipComponentForARGIMarker}
    />
  );
}
