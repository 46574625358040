import { useUrlContext } from '@resistapp/client/contexts/url-provider';
import { useUser } from '@resistapp/client/contexts/use-user-context';
import { useProjects } from '@resistapp/client/hooks/api';
import { useNavigateWithQuery } from '@resistapp/client/hooks/use-navigate-with-query';
import { Feature, hasFeature } from '@resistapp/common/features';
import { useCallback, useMemo } from 'react';
import { ResistomapSelect } from '../forms/resistomap-select';
import { dotStylesForSelect, getStatusColors } from '../shared/general-components';
import { theme } from '../shared/theme';

interface Props {
  showStatus?: boolean;
}

export const ProjectSelector = ({ showStatus }: Props) => {
  const navigate = useNavigateWithQuery();
  const { data: projects } = useProjects();
  const { page, projectId } = useUrlContext();
  const { user } = useUser();

  const pageCurrent = page.current;
  const pageViable = page.viable;

  // This should redirect user so that:
  // - When they are on the pages that support projectId, they stay on the same page, but project changes
  // - When they are on the pages that don't support projectId, they are redirected to the correct page
  //    - In the priority order, if user has access to the pages: index-page -> risk-page -> reduction-page
  //    - If user doesn't have access to any of the pages, they are redirected to the research page
  const handleSelect = useCallback(
    (projectIdLocal: number) => {
      if (pageCurrent === 'research' || pageCurrent === 'admin') {
        navigate(`/research/${projectIdLocal}`, false);
      } else if (
        (pageCurrent === 'index' && hasFeature(user, Feature.ARGI)) ||
        (pageCurrent === 'risk' && hasFeature(user, Feature.RISK)) ||
        (pageCurrent === 'reduction' && hasFeature(user, Feature.REDUCTION))
      ) {
        // TODO: Use navigate
        window.location.href = `/${pageCurrent}/${projectIdLocal}`;
      } else if (
        hasFeature(user, Feature.ARGI) ||
        hasFeature(user, Feature.RISK) ||
        hasFeature(user, Feature.REDUCTION)
      ) {
        // TODO: Use navigate
        window.location.href = `/${pageViable}/${projectIdLocal}`;
      } else {
        // TODO: Use navigate
        window.location.href = `/research/${projectIdLocal}`;
      }
    },
    [navigate, pageCurrent, user],
  );

  const options = useMemo(
    () => projects?.map(project => ({ label: project.name, value: project.id, project })).reverse() || [],
    [projects],
  );

  const defaultValue = useMemo(() => {
    return options.find(projectLocal => projectLocal.value === projectId.current);
  }, [projectId.current, options]);

  return (
    <ResistomapSelect<(typeof options)[number], false>
      options={options}
      onChange={option => {
        option && handleSelect(option.value);
      }}
      value={defaultValue}
      chakraStyles={{
        container: styles => {
          return {
            ...styles,
            width: 'max-content',
            minWidth: '100%',
            fontWeight: theme.fontWeight.light,
            fontSize: theme.fontSize.medium,
            color: theme.colors.neutral700,
          };
        },
        control: styles => {
          return {
            ...styles,
            border: theme.borders.normal,
            borderRadius: theme.borders.radius.full,
          };
        },
        option: (styles, { data }) => {
          if (!showStatus) {
            return {
              ...styles,
            };
          }

          const statusColors = getStatusColors(data.project.status);

          return {
            ...styles,
            ...dotStylesForSelect(
              statusColors.bg,
              statusColors.text,
              data.project.status.slice(0, 2),
              statusColors.border,
            ),
            position: 'relative',
            whiteSpace: 'nowrap',
          };
        },
      }}
    />
  );
};
