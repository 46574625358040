import { faMap } from '@fortawesome/free-regular-svg-icons';
import { faCity, faFillDrip, faToilet } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EnvironmentSubType } from '@resistapp/common/environment-types';
import { CSSProperties } from 'react';

export interface EnvTypeIconProps {
  style?: CSSProperties;
  envSubtype: EnvironmentSubType | 'pooled' | undefined;
  color?: string;
}

export function EnvTypeIcon({ color, style, envSubtype }: EnvTypeIconProps) {
  switch (envSubtype) {
    case EnvironmentSubType.URBAN_RUNOFF:
      return <FontAwesomeIcon style={style} icon={faFillDrip} />;
    case EnvironmentSubType.HOSPITAL_WASTEWATER_RAW:
    case EnvironmentSubType.HOSPITAL_WASTEWATER_TREATED:
      return <HospitalIcon style={style} color={color} />;
    case EnvironmentSubType.URBAN_WASTEWATER_RAW:
    case EnvironmentSubType.URBAN_WASTEWATER_TREATED:
      return <FontAwesomeIcon style={style} icon={faCity} />;
    case EnvironmentSubType.SEPTIC_TANK_RAW:
    case EnvironmentSubType.SEPTIC_TANK_TREATED:
      return <FontAwesomeIcon style={style} icon={faToilet} />;
    case 'pooled':
      return <FontAwesomeIcon style={style} icon={faMap} />;
    default:
      return null;
  }
}

export function HospitalIcon({ color, style }: { color?: string; style?: CSSProperties }) {
  const svgStyle: CSSProperties = {
    display: 'inline-block',
    verticalAlign: 'middle',
    paddingBottom: '1px',
    marginRight: '-2px',
    ...(style || {}),
  };

  return (
    <svg style={svgStyle} width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="cutout-mask">
        <rect width="22" height="22" fill="white" />
        <path d="M9.5 5.5H12.5V9H16V12H12.5V15.5H9.5V12H6V9H9.5V5.5Z" fill="black" />
      </mask>
      <circle cx="11" cy="11" r="9" fill={color || '#000000'} mask="url(#cutout-mask)" />
    </svg>
  );
}
