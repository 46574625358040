import { Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { useSampleDataContext } from '@resistapp/client/contexts/sample-data-context';
import { useOverviewContext } from '@resistapp/client/contexts/use-overview-context/use-overview-context';
import { friendlyEnvSubTypeOrType } from '@resistapp/common/environment-types';
import { MetricMode } from '@resistapp/common/types';
import { isNil } from 'lodash';
import { getMetricAndLevel, MetricAndLevel } from '../../utils/metric-utils';
import { ArrowIcon } from '../icons/arrow-icon';
import { CloseIconSimple } from '../icons/close-icon';
import { theme } from '../shared/theme';
import { EnvironmentTitle, EnvironmentTitleSmall } from './environment-titles';
import { ResistanceBoxes } from './resistance-boxes';
import { ScopeSelectors } from './scope-selectors';
import { Option } from './types';

export function OverviewFocusHeader() {
  const {
    topLevelMapData,
    selectedSiteDatum,
    zoomedMapData,
    previousAdminAreasLifo,
    trendData,
    changeZoomedAdminArea,
    metricMode,
    siteCount,
    samplingPointsCount,
  } = useOverviewContext();
  const { queryFilters } = useSampleDataContext();
  const { toggleEnvironment } = queryFilters;
  const topLevelEnv = topLevelMapData?.environment;
  const selectedOrZoomedDatum = selectedSiteDatum || zoomedMapData;
  const selectedOrZoomedEnv = selectedOrZoomedDatum?.environment;

  // Influent for ARGI, effluent for risk
  const primaryMetric: MetricAndLevel = selectedOrZoomedDatum
    ? getMetricAndLevel(
        selectedOrZoomedDatum.abundances,
        selectedOrZoomedDatum.afterAbundances,
        queryFilters.filters.selectedTargets,
        metricMode,
      )
    : [null, null];

  // Effluent for ARGI, influent for risk
  const secondaryMetric: MetricAndLevel = getMetricAndLevel(
    selectedSiteDatum?.afterAbundances || [],
    selectedSiteDatum?.abundances,
    queryFilters.filters.selectedTargets,
    metricMode,
  );

  const [metricBefore, levelBefore] = metricMode === MetricMode.RISK ? secondaryMetric : primaryMetric;
  const [metricAfter, levelAfter] = metricMode === MetricMode.RISK ? primaryMetric : secondaryMetric;

  const hasAfterAbundances = selectedSiteDatum?.afterAbundances && selectedSiteDatum.afterAbundances.length > 0;
  const hasAbundances = !!selectedSiteDatum?.abundances && selectedSiteDatum.abundances.length > 0;

  const firstLevelLabel = metricMode === MetricMode.ARGI || metricMode === MetricMode.RISK ? 'Raw' : undefined;

  const secondLevelLabel = hasAfterAbundances
    ? metricMode === MetricMode.RISK || metricMode === MetricMode.ARGI
      ? 'Treated'
      : undefined
    : undefined;

  const [metricTopAdminLevel, levelTopAdminLevel] = topLevelMapData
    ? getMetricAndLevel(
        topLevelMapData.abundances,
        topLevelMapData.afterAbundances,
        queryFilters.filters.selectedTargets,
        metricMode,
      )
    : [null, null];

  const selectedMetricAfter = isNil(metricAfter)
    ? null
    : Number(metricAfter.toFixed(metricMode === MetricMode.ARGI ? 1 : 0));
  const changeInMetric =
    isNil(selectedMetricAfter) || isNil(metricBefore)
      ? undefined
      : selectedMetricAfter === metricBefore
        ? 0
        : selectedMetricAfter > metricBefore
          ? 1
          : -1;
  const previousAdminArea =
    previousAdminAreasLifo.length > 0 && previousAdminAreasLifo[previousAdminAreasLifo.length - 1];

  const handleAntibioticChange = (value: Option | null) => {
    const correctValue =
      !value || value.value === 'All antibiotics' ? queryFilters.filters.selectedTargets : value.value;
    queryFilters.toggleGeneGroup(correctValue, true);
  };

  const siteInfo =
    `${siteCount ?? '-'} Sites` +
    (samplingPointsCount && siteCount && samplingPointsCount > siteCount
      ? ` and ${samplingPointsCount} Sampling Points`
      : '');

  const TopLevelResistanceBoxes = (
    <ResistanceBoxes
      firstLevel={{
        level: levelTopAdminLevel,
        indexNumber: metricTopAdminLevel,
        label: metricMode === MetricMode.ARGI ? firstLevelLabel : undefined,
      }}
      metricMode={metricMode}
      showReductionMetric={false}
    />
  );

  return (
    <Container id="overview-focus-header-container" flexDirection={{ lg: 'row' }}>
      {/* TOP LEVEL ENVIRONMENT BOX */}
      {selectedOrZoomedEnv ? (
        <BorderContainerSmall height={{ md: '128px' }} display={{ base: 'none', lg: 'flex' }}>
          <EnvironmentTitleSmall environmentName={topLevelEnv?.name || ''} />
          {TopLevelResistanceBoxes}
        </BorderContainerSmall>
      ) : (
        <BorderContainerBig
          flexDirection={{ md: 'row' }}
          height={{ md: '128px' }}
          gap={{ base: theme.spacing[6], lg: theme.spacing[12] }}
        >
          <EnvironmentTitle environmentName={topLevelEnv?.name || ''} subtype="pooled" />
          {TopLevelResistanceBoxes}
          <ScopeSelectors
            metricMode={metricMode}
            title={siteInfo}
            onChange={handleAntibioticChange}
            selectedTargets={queryFilters.filters.selectedTargets}
            trendData={trendData}
          />
        </BorderContainerBig>
      )}

      {/* SELECTED OR ZOOMED ENVIRONMENT */}
      {selectedOrZoomedEnv && (
        <>
          {/* BREADCRUMB ARROWS */}
          <Flex
            sx={{
              height: { base: '25px', lg: 'auto' },
              transform: { base: 'rotate(90deg)', lg: 'none' },
              alignSelf: 'center',
              width: 'min-content',
            }}
            display={{ base: 'none', lg: 'flex' }}
          >
            {previousAdminAreasLifo.map(area => (
              <ArrowIcon direction="right" style={{ color: theme.colors.neutral400, width: '14px' }} key={area.name} />
            ))}
          </Flex>
          {/* MAIN ENVIRONMENT BOX */}
          <BorderContainerBig
            flexDirection={{ md: 'row' }}
            height={{ md: '128px' }}
            gap={{ base: theme.spacing[6], lg: theme.spacing[12] }}
          >
            <EnvironmentTitle
              environmentName={selectedOrZoomedEnv.name || ''}
              subtype={selectedOrZoomedEnv.subtype || 'pooled'}
            />
            <ResistanceBoxes
              firstLevel={{
                level: levelBefore,
                indexNumber: metricBefore,
                label: firstLevelLabel,
              }}
              secondLevel={
                metricMode !== MetricMode.REDUCTION && hasAfterAbundances
                  ? {
                      level: levelAfter,
                      indexNumber: metricAfter,
                      label: secondLevelLabel,
                    }
                  : undefined
              }
              changeInIndex={
                metricMode === MetricMode.ARGI || metricMode === MetricMode.RISK ? changeInMetric : undefined
              }
              metricMode={metricMode}
              showReductionMetric={metricMode === MetricMode.REDUCTION && hasAbundances && !!hasAfterAbundances}
            />
            <ScopeSelectors
              metricMode={metricMode}
              title={friendlyEnvSubTypeOrType(selectedOrZoomedEnv.subtype, selectedOrZoomedEnv.type)}
              onChange={handleAntibioticChange}
              bolded={true}
              selectedTargets={queryFilters.filters.selectedTargets}
              trendData={trendData}
            />
            {selectedSiteDatum && (
              <CloseContainer>
                <CloseIconSimple
                  onClick={() => {
                    toggleEnvironment(undefined, true);
                    previousAdminArea && changeZoomedAdminArea(previousAdminArea);
                  }}
                  style={{
                    color: theme.colors.neutral600,
                  }}
                />
              </CloseContainer>
            )}
          </BorderContainerBig>
        </>
      )}
    </Container>
  );
}

const Container = styled(Flex)`
  flex-direction: column;
  height: 100%;
  gap: ${theme.spacing[4]};
`;

const BorderContainer = styled(Flex)`
  position: relative;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: ${theme.spacing[6]} ${theme.spacing[8]};
  ${theme.addBorder(300, 'small')}
  background-color: white;
`;
const BorderContainerSmall = styled(BorderContainer)`
  justify-content: center;
  width: auto;
  gap: 13px;
`;
const BorderContainerBig = styled(BorderContainer)`
  padding-right: 0;
  padding-right: 65px;
`;

const CloseContainer = styled(Flex)`
  position: absolute;
  top: 0;
  right: 38px;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-size: ${theme.fontSize.h2};
`;
