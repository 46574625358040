import { theme } from '@resistapp/client/components/shared/theme';
import { OverviewDatum } from '@resistapp/client/data-utils/plot-data/build-overview-line-data';
import { calcResistanceGeneIndex } from '@resistapp/common/statistics/resistance-index';
import { Group } from '@visx/group';
import { Threshold } from '@visx/threshold';
import { curveLinear } from '@vx/curve';
import { ScaleLinear, ScaleTime } from 'd3-scale';
import { isNil } from 'lodash';

interface Props {
  selectedEnvironmentData: OverviewDatum[] | undefined;
  timeScale: ScaleTime<number | undefined, number | undefined>;
  resistanceIndexScale: ScaleLinear<number | undefined, number | undefined>;
  graphHeight: number;
  mouseMoveHandler: (data: OverviewDatum[], event: React.MouseEvent<SVGGElement>) => void;
}

export function QuartileRange(props: Props) {
  const { selectedEnvironmentData, timeScale, resistanceIndexScale, graphHeight, mouseMoveHandler } = props;
  if (!selectedEnvironmentData?.length) {
    return null;
  }

  return (
    <Group
      onMouseMove={event => {
        mouseMoveHandler(selectedEnvironmentData, event);
      }}
    >
      <Threshold<OverviewDatum>
        id={`${Math.random()}`}
        data={selectedEnvironmentData}
        x={(d: OverviewDatum) => timeScale(new Date(d.date)) ?? 0}
        y0={(d: OverviewDatum) =>
          resistanceIndexScale(isNil(d.firstQuartile) ? 0 : calcResistanceGeneIndex(d.firstQuartile)) ?? 0
        }
        y1={(d: OverviewDatum) =>
          resistanceIndexScale(isNil(d.thirdQuartile) ? 0 : calcResistanceGeneIndex(d.thirdQuartile)) ?? 0
        }
        clipAboveTo={0}
        clipBelowTo={graphHeight}
        curve={curveLinear}
        belowAreaProps={{
          fill: theme.colors.transparentBlue,
        }}
        aboveAreaProps={{
          fill: theme.colors.transparentBlue,
        }}
      />
    </Group>
  );
}
