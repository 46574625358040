import { chain } from 'lodash';
import { MetricMode, Organisation, User } from './types';
import { rndProjectId } from './utils';

export function getUserProjectIds(user: User | undefined | null): number[] {
  const orgProjectIds = (user?.organizations || []).flatMap(org => org.accesses.map(access => access.projectId));
  const directProjectIds = (user?.directAccesses || []).map(access => access.projectId);
  const sortedIds = chain([...orgProjectIds, ...directProjectIds])
    .sortBy(id => -id)
    .concat(rndProjectId)
    .uniq()
    .value();
  // if (isEqual(sortedIds, WastPanProjectIds)) {
  //   // Return WastPan demo projects in the right oreder so that Regional project is first and becomes the default project
  //   return [...WastPanProjectIds].reverse();
  // } else {
  return sortedIds;
}

export function getUserDefaultMetric(user: User | undefined | null): MetricMode | null {
  const orgDefaults = chain(user?.organizations || [])
    .map(o => o.defaultMetric)
    .compact()
    .uniq()
    .value();
  return Object.values(MetricMode).find(mode => orgDefaults.includes(mode)) || MetricMode.ARGI;
}

export function getProjectOrganizationIds(organization: Organisation | undefined | null): number[] {
  return (organization?.accesses || []).map(access => access.projectId);
}
