import { DEFAULT_GENE_GROUPING } from '@resistapp/client/data-utils/gene-groups';
import { GeneGrouping, Target, allGeneGroups, sixteenS } from '@resistapp/common/assays';
import { EnvironmentType } from '@resistapp/common/environment-types';
import {
  DEFAULT_END_INTERVAL,
  DEFAULT_START_INTERVAL,
  ensureValidUtcMidnightOrDefault,
} from '@resistapp/common/friendly';
import { Environment, FullProject, FullSamplesByUID } from '@resistapp/common/types';
import { sortUniqEnvironments } from '@resistapp/common/utils';
import { uniq } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import useLocalStorageState from 'use-local-storage-state';
import {
  AbunanceSelection,
  Filters,
  filterAndRenumber,
  getNextToggledFilter,
} from '../../data-utils/filter-data/filter';
import {
  QueryParams,
  getAbsoluteModeParams,
  getEnvironmentTypeParams,
  getEnvironmentsParams,
  getGeneGroupingParams,
  getGeneGroupsParams,
  mutateEnvironmentRelatedSearchParams,
  mutateSearchParamsWithSelection,
} from '../../utils/url-manipulation';
import { constructFocusInfo } from './use-query-filters-utils';

export interface UseQueryFilters {
  focusAndRenumber: (samplesByUID: FullSamplesByUID) => FullSamplesByUID;
  queryFilters: QueryFilters;
}

export interface QueryFilters {
  setGrouping: (grouping: GeneGrouping) => void;
  setEnvironmentTypes: (types: EnvironmentType | EnvironmentType[]) => void;
  toggleEnvironment: (name: string | string[] | undefined, removeOldSelections: boolean) => void;
  setMatchingEnvironmentsAcrossTypes: (search: string) => void;
  hasFocus: boolean;
  toggleGeneGroup: (
    group: Target | Target[],
    removeOldSelections: boolean,
    selectedGroupingInCaseStateHasNotUpdated?: GeneGrouping,
  ) => void;
  filters: Filters;
  focusInfo: string;
  setAbundanceMode: (mode: AbunanceSelection) => void;
  setAbsoluteMode: (value: boolean) => void;
  resetFilters: () => void;
  setInterval: (start: Date | string, end: Date | string) => void;
}

export function useQueryFilters(project: FullProject | undefined, absoluteModeOn?: boolean): UseQueryFilters {
  const defaults = {
    abunanceSelection: AbunanceSelection.ANALYSED,
    geneGroups: allGeneGroups[DEFAULT_GENE_GROUPING],
  };
  const [abundanceMode, setAbundanceMode] = useLocalStorageState('abundanceMode', {
    defaultValue: defaults.abunanceSelection,
  });
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const start = searchParams.get('start');
  const end = searchParams.get('end');

  const [allProjectEnvironments, setAllProjectEnvironments] = useState<Environment[]>([]);
  const [allProjectEnvironmentTypes, setAllProjectEnvironmentTypes] = useState<EnvironmentType[]>([]); // This should be a convenience copy of the above (here lies dragons?)
  const [allProjectEnvironmentNames, setAllProjectEnvironmentNames] = useState<string[]>([]); // This should be a convenience copy of the above (here lies dragons?)

  // TODO de-duplicate queryX and selectedX state variables
  const queryGeneGroups = getGeneGroupsParams(searchParams);
  const queryGeneGrouping = getGeneGroupingParams(searchParams);
  const [selectedGrouping, setSelectedGrouping] = useState<GeneGrouping>(queryGeneGrouping);

  const envNamesHaveComma = allProjectEnvironmentNames.join('').includes(','); // For url backwards compatibility: legacy query param array separator support
  const queryEnvironmentNames = getEnvironmentsParams(searchParams, envNamesHaveComma);
  const queryEnvironmentTypes = getEnvironmentTypeParams(searchParams);

  const queryAbsoluteMode = getAbsoluteModeParams(searchParams);

  useEffect(() => {
    // Convert legacy value
    if (abundanceMode === ('ANALYZED' as AbunanceSelection)) {
      setAbundanceMode(AbunanceSelection.ANALYSED);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [abundanceMode]);

  const selectedTypeEnvironments = queryEnvironmentTypes
    ? allProjectEnvironments.filter(e => queryEnvironmentTypes.includes(e.type))
    : allProjectEnvironments;

  const [filters, setFilters] = useState<Filters>({
    selectedEnvironmentTypes: allProjectEnvironmentTypes,
    selectedEnvironmentNamesOrdered: queryEnvironmentNames,
    singleSelectedEnvironmentType: queryEnvironmentTypes?.length === 1 ? queryEnvironmentTypes[0] : undefined,
    selectedTargetGrouping: selectedGrouping,
    abundances: abundanceMode,
    selectedTargets: allGeneGroups[selectedGrouping] as Target[],
    interval: {
      start: ensureValidUtcMidnightOrDefault(start, DEFAULT_START_INTERVAL),
      end: ensureValidUtcMidnightOrDefault(end, DEFAULT_END_INTERVAL),
    },
    absoluteMode: queryAbsoluteMode,
  });

  const oldSamplingRef = useRef<number | null>(null);
  useEffect(() => {
    if (project && oldSamplingRef.current !== project.id) {
      oldSamplingRef.current = project.id;
      const projectEnvironments = sortUniqEnvironments(project.samplesByUID);
      const allEnvironmentNames = projectEnvironments.map(env => env.name);
      const allProjectEnvironmentTypesToBeSet = uniq(projectEnvironments.map(env => env.type));
      setAllProjectEnvironmentTypes(allProjectEnvironmentTypesToBeSet);
      setAllProjectEnvironments(projectEnvironments);
      setAllProjectEnvironmentNames(allEnvironmentNames);

      const initialEnvironmentTypes: EnvironmentType[] = queryEnvironmentTypes
        ? queryEnvironmentTypes
        : allProjectEnvironmentTypesToBeSet;

      const specificTypesNames = projectEnvironments
        .filter(e => initialEnvironmentTypes.includes(e.type))
        .map(e => e.name);
      const initialEnvironmentNames = queryEnvironmentNames.length
        ? getNextToggledFilter(queryEnvironmentNames, specificTypesNames, specificTypesNames, true)
        : allEnvironmentNames;

      setFilters({
        ...filters,
        selectedEnvironmentNamesOrdered: initialEnvironmentNames,
        selectedEnvironmentTypes: initialEnvironmentTypes,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project, oldSamplingRef.current]);

  // We activate this, when the project changes. This should null the selections.
  useEffect(() => {
    const groups = queryGeneGroups.length
      ? getNextToggledFilter(queryGeneGroups, allGeneGroups[selectedGrouping], allGeneGroups[selectedGrouping], true)
      : allGeneGroups[selectedGrouping];
    setFilters({
      ...filters,
      abundances: abundanceMode,
      selectedTargetGrouping: selectedGrouping,
      selectedTargets: groups as Target[],
    });
    // We trigger the other upper useEffect manually here, since this overwrites the environmentTypes and
    // environmentNames that it has set. The upper useEffect has to run after this. Otherwise the sample legends can
    // appear empty
    oldSamplingRef.current = null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGrouping, abundanceMode, location]);

  useEffect(() => {
    if (!absoluteModeOn && selectedGrouping === sixteenS) {
      setSelectedGrouping('target');
    }
  }, [absoluteModeOn, setSelectedGrouping, selectedGrouping]);

  const getNextEnvTypeFilterAndUpdateSearchParams = (newTypes: EnvironmentType[]): Filters => {
    mutateEnvironmentRelatedSearchParams(searchParams, newTypes);
    setSearchParams(searchParams, { replace: true });
    const localEnvTypes = newTypes.length ? newTypes : allProjectEnvironmentTypes;
    const validTypes = localEnvTypes.filter(etype => Object.values(EnvironmentType).includes(etype));

    return {
      ...filters,
      selectedEnvironmentNamesOrdered: allProjectEnvironmentNames,
      selectedEnvironmentTypes: validTypes,
      singleSelectedEnvironmentType: newTypes.length === 1 ? newTypes[0] : undefined,
    };
  };

  return {
    focusAndRenumber: (samplesByUID: FullSamplesByUID) => filterAndRenumber(samplesByUID, filters),
    queryFilters: {
      focusInfo: constructFocusInfo(filters, allProjectEnvironmentNames),
      toggleGeneGroup: (
        group: Target | Target[],
        removeOldSelections: boolean,
        selectedGroupingInCaseStateHasNotUpdated?: GeneGrouping,
      ) => {
        const realSelectedGrouping = selectedGroupingInCaseStateHasNotUpdated ?? selectedGrouping;
        const next = getNextToggledFilter<Target>(
          group,
          filters.selectedTargets,
          allGeneGroups[realSelectedGrouping] as Target[],
          removeOldSelections,
        );
        mutateSearchParamsWithSelection(
          QueryParams.GENE_GROUPS,
          searchParams,
          allGeneGroups[realSelectedGrouping],
          next,
          removeOldSelections,
        );
        setSearchParams(searchParams, { replace: true });
        setFilters({ ...filters, selectedTargets: next });
      },
      setGrouping: (value: GeneGrouping) => {
        searchParams.set(QueryParams.GENE_GROUP_GROUPING, value);
        searchParams.delete(QueryParams.GENE_GROUPS);
        setSearchParams(searchParams, { replace: true });
        setSelectedGrouping(value);
      },
      filters,
      setAbundanceMode,
      setMatchingEnvironmentsAcrossTypes: (search: string) => {
        const newlyMatchedEnvNames = getLowerCaseMatches(search, allProjectEnvironmentNames);
        if (!search || !newlyMatchedEnvNames.length) {
          return;
        }
        const newlyMatchedEnvs = allProjectEnvironments.filter(e => newlyMatchedEnvNames.includes(e.name));
        const allNewlyMatchedEnvTypes = uniq(newlyMatchedEnvs.map(e => e.type));
        const newEnvTypeFilters = getNextEnvTypeFilterAndUpdateSearchParams(allNewlyMatchedEnvTypes);
        const allEnvNamesOfTheTheSelectedTypes = allProjectEnvironments
          .filter(e => allNewlyMatchedEnvTypes.includes(e.type))
          .map(e => e.name);

        const next = getNextToggledFilter(
          newlyMatchedEnvNames,
          newEnvTypeFilters.selectedEnvironmentNamesOrdered,
          allEnvNamesOfTheTheSelectedTypes,
          true,
        );
        mutateSearchParamsWithSelection(
          QueryParams.ENVIRONMENTS,
          searchParams,
          allEnvNamesOfTheTheSelectedTypes,
          next,
          true,
        );
        setSearchParams(searchParams, { replace: true });
        setFilters({ ...newEnvTypeFilters, selectedEnvironmentNamesOrdered: next });
      },
      toggleEnvironment: (name, removeOldSelections) => {
        const selectedEnvironmentNames = selectedTypeEnvironments.map(e => e.name);
        const next = name
          ? getNextToggledFilter(
              name,
              filters.selectedEnvironmentNamesOrdered,
              selectedEnvironmentNames,
              removeOldSelections,
            )
          : selectedEnvironmentNames;

        mutateSearchParamsWithSelection(
          QueryParams.ENVIRONMENTS,
          searchParams,
          selectedEnvironmentNames,
          next,
          removeOldSelections,
        );

        setSearchParams(searchParams, { replace: true });

        setFilters({
          ...filters,
          selectedEnvironmentNamesOrdered: next,
        });

        // Scroll to the map, when the selected environment changes
        const mapElement = document.querySelector('.mapboxgl-map');
        if (mapElement) {
          mapElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      },
      setEnvironmentTypes: (newTypeOrTypes: EnvironmentType | EnvironmentType[]) => {
        const newFilters = getNextEnvTypeFilterAndUpdateSearchParams(
          Array.isArray(newTypeOrTypes) ? newTypeOrTypes : [newTypeOrTypes],
        );
        setFilters(newFilters);
      },
      setAbsoluteMode: (value: boolean) => {
        searchParams.set(QueryParams.ABSOLUTE_MODE, String(value));
        setSearchParams(searchParams, { replace: true });
        setFilters({ ...filters, absoluteMode: value });
      },
      hasFocus:
        selectedGrouping !== 'target' ||
        filters.selectedTargets.length < allGeneGroups[selectedGrouping].length ||
        (filters.selectedEnvironmentTypes.length &&
          filters.selectedEnvironmentTypes.length < allProjectEnvironmentTypes.length) ||
        (filters.selectedEnvironmentNamesOrdered.length &&
          filters.selectedEnvironmentNamesOrdered.length < allProjectEnvironmentNames.length) ||
        filters.interval.start.getTime() !== DEFAULT_START_INTERVAL.getTime() ||
        filters.interval.end.getTime() !== DEFAULT_END_INTERVAL.getTime(),
      resetFilters: () => {
        setSearchParams({}, { replace: true });
        setFilters({
          selectedEnvironmentTypes: allProjectEnvironmentTypes,
          selectedEnvironmentNamesOrdered: allProjectEnvironmentNames,
          singleSelectedEnvironmentType: undefined,
          selectedTargetGrouping: DEFAULT_GENE_GROUPING,
          abundances: defaults.abunanceSelection,
          selectedTargets: defaults.geneGroups,
          interval: {
            start: DEFAULT_START_INTERVAL,
            end: DEFAULT_END_INTERVAL,
          },
          absoluteMode: false,
        });
      },
      setInterval: (requestedStart: Date | string, requestedEnd: Date | string) => {
        const snappedStart = ensureValidUtcMidnightOrDefault(requestedStart, DEFAULT_START_INTERVAL);

        if (snappedStart.getTime() === DEFAULT_START_INTERVAL.getTime()) {
          searchParams.delete('start');
        } else {
          searchParams.set('start', snappedStart.toISOString().slice(0, 10));
        }
        const snappedEnd = ensureValidUtcMidnightOrDefault(requestedEnd, DEFAULT_END_INTERVAL);
        if (snappedEnd.getTime() === DEFAULT_END_INTERVAL.getTime()) {
          searchParams.delete('end');
        } else {
          searchParams.set('end', snappedEnd.toISOString().slice(0, 10));
        }

        setSearchParams(searchParams, { replace: true });
        setFilters({
          ...filters,
          interval: {
            start: snappedStart,
            end: snappedEnd,
          },
        });
      },
    },
  };
}

export function getLowerCaseMatches(searchString: string, stringsToMatchTo: string[]) {
  const lowerSearchString = searchString.toLowerCase();
  return stringsToMatchTo.filter(str => str.toLowerCase().includes(lowerSearchString));
}
