import styled from '@emotion/styled';

export const arrowOffset = 20;

interface Props {
  direction: 'up' | 'right' | 'down' | 'left';
  color?: string;
  style?: React.CSSProperties;
  borderColor?: string;
}
export function MarkerPointerArrow(props: Props) {
  const ArrowComponent = props.borderColor ? arrows.border[props.direction] : arrows.normal[props.direction];
  return (
    <ArrowComponent style={props.style} color={props.color || 'white'} borderColor={props.borderColor as string} />
  );
}

const arrows = {
  normal: {
    up: styled.div<{ color: string }>`
      position: absolute;
      top: -${arrowOffset}px;
      width: 0;
      height: 0;
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-bottom: 20px solid ${props => props.color};
      padding-bottom: 2px;
    `,
    right: styled.div<{ color: string }>`
      position: absolute;
      right: -${arrowOffset}px;
      width: 0;
      height: 0;
      border-top: 20px solid transparent;
      border-bottom: 20px solid transparent;
      border-left: 20px solid ${props => props.color};
      padding-left: 2px;
      margin-left: 5px;
    `,
    down: styled.div<{ color: string }>`
      position: absolute;
      bottom: -${arrowOffset}px;
      width: 0;
      height: 0;
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-top: 20px solid ${props => props.color};
      padding-top: 2px;
    `,
    left: styled.div<{ color: string }>`
      position: absolute;
      left: -${arrowOffset}px;
      width: 0;
      height: 0;
      border-top: 20px solid transparent;
      border-bottom: 20px solid transparent;
      border-right: 20px solid ${props => props.color};
      padding-right: 2px;
    `,
  },
  border: {
    up: styled.div<{ color: string; borderColor: string }>`
      position: absolute;
      top: -${arrowOffset}px;
      width: 0;
      height: 0;
      border-left: 19px solid transparent;
      border-right: 19px solid transparent;
      border-bottom: 19px solid ${props => props.borderColor};
      padding-bottom: 2px;
      &::before {
        content: '';
        position: absolute;
        bottom: -22px;
        left: -20px;
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-bottom: 20px solid ${props => props.color};
      }
    `,
    right: styled.div<{ color: string; borderColor: string }>`
      position: absolute;
      right: -${arrowOffset}px;
      width: 0;
      height: 0;
      border-top: 19px solid transparent;
      border-bottom: 19px solid transparent;
      border-left: 19px solid ${props => props.borderColor};
      padding-left: 2px;
      margin-left: 5px;
      &::before {
        content: '';
        position: absolute;
        left: -22px;
        top: -20px;
        border-top: 20px solid transparent;
        border-bottom: 20px solid transparent;
        border-left: 20px solid ${props => props.color};
      }
    `,
    down: styled.div<{ color: string; borderColor: string }>`
      position: absolute;
      bottom: -${arrowOffset}px;
      width: 0;
      height: 0;
      border-left: 19px solid transparent;
      border-right: 19px solid transparent;
      border-top: 19px solid ${props => props.borderColor};
      padding-top: 2px;
      &::before {
        content: '';
        position: absolute;
        top: -22px;
        left: -20px;
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-top: 20px solid ${props => props.color};
      }
    `,
    left: styled.div<{ color: string; borderColor: string }>`
      position: absolute;
      left: -${arrowOffset}px;
      width: 0;
      height: 0;
      border-top: 19 solid transparent;
      border-bottom: 19 solid transparent;
      border-right: 19 solid ${props => props.borderColor};
      padding-right: 2px;
      &::before {
        content: '';
        position: absolute;
        right: -22px;
        bottom: -20px;
        border-top: 20px solid transparent;
        border-bottom: 20px solid transparent;
        border-right: 20px solid ${props => props.color};
      }
    `,
  },
} as const;
