import { OverviewDatum } from '@resistapp/client/data-utils/plot-data/build-overview-line-data';
import { scaleLinear, scaleTime } from '@visx/scale';
import { addMonths, max, min, startOfYear } from 'date-fns';
import { chain } from 'lodash';

// export function getResistanceIndexScale(pixelsYMax: number) {
//   const resistanceIndexMin = 0;
//   const resistanceIndexMax = 5;
//   const resistanceIndexScale = scaleLinear<number | undefined>({
//     domain: [resistanceIndexMin, resistanceIndexMax],
//     nice: true,
//     round: true,
//   }).range([pixelsYMax, 0]);

//   return resistanceIndexScale;
// }

export function getResistanceIndexScale(height: number) {
  return scaleLinear({
    domain: [0, 5],
    range: [height, 0],
    nice: true,
  });
}

export function getGradientScale(height: number, domain: [number, number]) {
  return scaleLinear({
    domain,
    range: [height, 0],
    nice: true,
  });
}

export function getTimeScale(trendData: OverviewDatum[][] | undefined, pixelsXMax: number) {
  if (!trendData) {
    return undefined;
  }
  const dates = chain(trendData)
    .flatMap(lineData => lineData.map(datum => datum.date))
    .map(m => new Date(m))
    .uniq()
    .value();
  return getTimeScaleFromDates(dates, pixelsXMax);
}

export function getTimeScaleFromDates(dates: Date[], pixelsXMax: number) {
  const minDate = min(dates);
  const maxDate = max(dates);
  const axisStartDate = startOfYear(minDate);
  const axisEndDate = addMonths(startOfYear(maxDate), 11);
  const timeScale = scaleTime<number | undefined>({
    domain: [axisStartDate.getTime(), axisEndDate.getTime()],
  }).range([0, pixelsXMax]);
  return timeScale;
}
