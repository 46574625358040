import styled from '@emotion/styled';
import IconSrc from '../../assets/icon/undo.svg';

export function UndoIcon() {
  return <StyledIcon src={IconSrc} />;
}

const StyledIcon = styled.img`
  font-size: 13px;
  width: 16px;
  padding-top: 2px;
  padding-bottom: 2px;
`;
