import { useMediaQuery, useToast } from '@chakra-ui/react';
import { useEffect } from 'react';
import { LocalStorageId } from '../shared/local-storage';

export function MobileSupportNotice() {
  const toast = useToast();
  const [isMobile] = useMediaQuery('(max-width: 550px)');
  const hasActivated = localStorage.getItem(LocalStorageId.mobileNoticeShown);

  useEffect(() => {
    if (isMobile && !hasActivated) {
      toast({
        title: 'Mobile support',
        description:
          'The platform has limited support for narrow screens. For the best experience, please use a laptop or a tablet.',
        status: 'warning',
        colorScheme: 'yellow',
        duration: 13000,
        isClosable: true,
      });
      localStorage.setItem(LocalStorageId.mobileNoticeShown, 'true');
    }
  }, [isMobile, hasActivated, toast]);

  return null;
}
