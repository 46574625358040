import { coerce, gte } from 'semver';

export function safeVersionGte(a: string, b: string, def: string | null) {
  // We need to coerce the analysisVersion, since it contains strings such as: 'v2.7.8 (CHANGES)'
  const semverA = coerce(a === 'dev' ? '99.99.99' : a) || coerce(def);
  const semverB = coerce(b === 'dev' ? '99.99.99' : b) || coerce(def);
  if (!semverA || !semverB) {
    throw new Error(`Invalid versions ${a} or ${b}`);
  }
  return gte(semverA, semverB);
}
