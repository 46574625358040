import { allGeneGroups, Target } from '@resistapp/common/assays';

export type TargetOrAll = Target | 'All antibiotics';
export const allAntibiotics: TargetOrAll = 'All antibiotics';

export const antibioticGroupOptions: TargetOrAll[] = ['All antibiotics' as const, ...allGeneGroups['antibiotic']];

export type Option = {
  label: TargetOrAll;
  value: TargetOrAll;
  bgColor: string;
  textColor: string;
};
