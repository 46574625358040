import { useParams } from 'react-router-dom';

export enum PathParams {
  projectId = 'projectId',
  signupCode = 'signupCode',
}

export function usePathParam(paramKey: PathParams) {
  const params = useParams<PathParams>();
  return params[paramKey];
}
