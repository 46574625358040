import styled from '@emotion/styled';
import { ArrowIcon } from '../icons/arrow-icon';
import { theme } from '../shared/theme';

interface BackButtonOnMapProps {
  title: string;
  onClick: () => void;
}

export function BackButtonOnMap({ title, onClick }: BackButtonOnMapProps) {
  return (
    <Container id="Site-back-button-on-map" onClick={onClick}>
      <ArrowIcon direction="left" style={{ height: 10, width: 10, color: theme.colors.neutral700 }} />
      <Text>{title}</Text>
    </Container>
  );
}

const Container = styled.div`
  position: absolute;
  top: 15px;
  left: 10px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 14px;

  ${theme.addBorder(300, 'full')};
  padding: ${theme.spacing[3]} ${theme.spacing[6]};
  background-color: white;
  ${theme.shadows.normal};
  // These are the default font styles from the body
  font-family: var(--chakra-fonts-body);
  color: var(--chakra-colors-chakra-body-text);
  line-height: var(--chakra-lineHeights-base);

  cursor: pointer;
  z-index: ${theme.zIndexes.overviewMapControls};
`;

const Text = styled.div`
  font-size: ${theme.fontSize.medium};
  font-weight: ${theme.fontWeight.bold};
  color: ${theme.colors.neutral900};
`;
