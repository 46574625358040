import { Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { ReactNode } from 'react';
import { theme } from '../shared/theme';

interface Props {
  title: string;
  explanation?: ReactNode;
  counts?: {
    detectedCount?: number;
    analysedCount?: number;
  };
}

export function SiteDetailTitle({ title, explanation, counts }: Props) {
  return (
    <>
      <Title id="site-detail-title">{title} </Title>
      <DetectedCount>
        {counts && (
          <span>
            {counts.detectedCount} of {counts.analysedCount} detected
          </span>
        )}
      </DetectedCount>
      <Flex justifyContent="space-between">{explanation && <Explanation>{explanation}</Explanation>}</Flex>
    </>
  );
}

const Title = styled.div`
  font-size: ${theme.fontSize.h4};
  font-weight: ${theme.fontWeight.heavy};
`;

const Explanation = styled.div`
  font-size: ${theme.fontSize.medium};
  font-weight: ${theme.fontWeight.light};
  color: ${theme.colors.neutral600};
  padding: ${theme.spacing[3]} 0;
  padding-top: ${theme.spacing[1]};
`;

const DetectedCount = styled.div`
  position: absolute;
  top: ${theme.spacing[3]};
  right: ${theme.spacing[6]};
  font-size: ${theme.fontSize.medium};
  font-weight: ${theme.fontWeight.heavy};
  color: ${theme.colors.neutral800};

  @media (max-width: 490px) {
    position: relative;
    top: auto;
    right: auto;
  }
`;
