import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { RefSmall } from '@resistapp/client/hooks/use-science-reference';
import { useState } from 'react';
import { NoticeIcon } from '../icons/notice-icon';
import { oldTheme } from '../shared/old-styles';
import { theme } from '../shared/theme';

interface Props {
  linkText: string;
  header: string;
  children: React.ReactNode;
}

export function InfoModalLink(props: Props) {
  const { header, linkText } = props;
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Root>
      <RefSmall
        onClick={() => {
          setIsOpen(true);
        }}
      >
        {linkText}
      </RefSmall>
      <Modal
        size={'lg'}
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        isCentered
      >
        <ModalOverlay />
        <ModalContent width="auto" maxWidth="600px" minWidth="400px">
          <ModalHeader>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <NoticeIcon />
              <div style={{ paddingLeft: theme.oldSpacing.xs }}>{header}</div>
            </div>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>{props.children}</ModalBody>
        </ModalContent>
      </Modal>
    </Root>
  );
}

const Root = styled.div`
  display: inline-block;
  align-items: center;
  padding-left: ${oldTheme.spacing.xs};
  padding-right: ${oldTheme.spacing.xs};
  padding-top: 0;
  padding-bottom: 0;
`;
