import { theme } from '@resistapp/client/components/shared/theme';
import { useOverviewContext } from '@resistapp/client/contexts/use-overview-context/use-overview-context';
import { capitalizeFirstLetterOnly } from '@resistapp/common/friendly';
import { Continent } from '@resistapp/common/types';
import { mean } from 'lodash';

export interface ReferenceLineProps {
  y: number;

  text: string;
  tooltipText: string;
  lineProps: {
    strokeWidth: number;
    strokeDasharray: string;
    strokeLinecap: 'round';
    stroke: string;
  };
}
export function useGeographicalReferenceLines(_graphHeight: number | undefined): ReferenceLineProps[] {
  const graphHeight = _graphHeight || 100; // All callers do not need to care about positioning
  const { continent } = useOverviewContext();

  const globalMeanARGI = getGlobalMeanARGI();
  const continentMeanARGI = getContinentMeanARGI(continent);

  const getHeightFromARGI = (argi: number) => {
    const pixelHeightOfOne = graphHeight / 5;
    return pixelHeightOfOne * (5 - argi);
  };

  const globalMeanHeight = getHeightFromARGI(globalMeanARGI);
  const continentMeanHeight = continentMeanARGI && getHeightFromARGI(continentMeanARGI);

  const global = {
    y: globalMeanHeight,
    lineProps: {
      stroke: globalMeanARGI >= 2 ? theme.colors.levelMod : theme.colors.levelLow,
      strokeDasharray: '1,8',
      strokeWidth: 2.5,
      strokeLinecap: 'round' as const,
    },
    text: 'Global mean ARGI',
    tooltipText:
      'Mean Antibiotic Resistance Gene Indexes from all waste water samples in the Resistomap database, equally weighted between continents.',
  };

  const continent2 =
    continent && continentMeanHeight && continentMeanARGI
      ? {
          y: continentMeanHeight,
          lineProps: {
            stroke: continentMeanARGI >= 2 ? theme.colors.levelMod : theme.colors.levelLow,
            strokeDasharray: '8,8',
            strokeWidth: 2.5,
            strokeLinecap: 'round' as const,
          },
          text: `${capitalizeFirstLetterOnly(continent)} mean ARGI`,
          tooltipText: `Mean Antibiotic Resistance Gene Index in wastewater samples from ${capitalizeFirstLetterOnly(continent)} in the Resistomap database.`,
        }
      : undefined;

  const referenceLines = [global];
  if (continent2) {
    referenceLines.push(continent2);
  }

  return referenceLines.sort((a, b) => a.y - b.y);
}
const argiByContinent: Partial<Record<Continent, number>> = {
  AFRICA: 2.1625413600993957,
  ASIA: 2.3978184520788632,
  EUROPE: 1.975467676979318,
  NORTH_AMERICA: 2.2544955131296396,
};
function getContinentMeanARGI(continent: Continent | undefined) {
  if (!continent || !argiByContinent[continent]) {
    return undefined;
  }
  return argiByContinent[continent];
}
function getGlobalMeanARGI() {
  return mean(Object.values(argiByContinent));
}
