import { Flex } from '@chakra-ui/react';
import { useOverviewContext } from '@resistapp/client/contexts/use-overview-context/use-overview-context';
import {
  getGenesAndCopyNumbers,
  getNumDetectedAndAnalysedAssays,
} from '@resistapp/client/data-utils/plot-data/process-overview-line-datum';
import { get_inRawOrEffluentSamples } from '@resistapp/client/utils/string-manipulation';
import { MinorTarget } from '@resistapp/common/assays-temp-96-gene-minor-targets';
import { MetricMode } from '@resistapp/common/types';
import { MetricTitle } from '../../shared/metric-title';
import { CopyNumberBarGraph } from '../copy-number-bar-graph';
import { SiteDetailTitle } from '../site-detail-title';
import { SiteGeneDetailBox } from '../site-gene-detail-box';
import { SiteDetailContainer, SiteDetailsBaseProps } from './general-site-details';
import {
  detailGraphHeight,
  getFriendlyGenesAndCopyNumbers,
  getFriendlyGenesAndReductionNumbers,
} from './site-details-utils';

const graphMargins = 26;

export function PathogenDetails({ containerRef }: SiteDetailsBaseProps) {
  const { selectedSiteDatum, metricMode } = useOverviewContext();

  if (!selectedSiteDatum) {
    return null;
  }

  const minorTarget = MinorTarget.PATHOGEN;
  const { detectedCount, analysedCount } = getNumDetectedAndAnalysedAssays(selectedSiteDatum, minorTarget, metricMode);
  const width = (containerRef.current?.clientWidth || 130) - graphMargins;
  const title = `Pathogen markers`;

  if (detectedCount === 0) {
    return (
      <SiteDetailContainer ref={containerRef}>
        <SiteDetailTitle title={title} explanation={`No pathogen associated genes detected`} />
        <Flex justifyContent="center" alignItems="center" height="100%" width="100%">
          <SiteGeneDetailBox target={minorTarget} />
        </Flex>
      </SiteDetailContainer>
    );
  } else {
    const genesAndCopyNumbers = getGenesAndCopyNumbers(selectedSiteDatum, minorTarget, metricMode);
    const friendlyGenesAndNumbers =
      metricMode === MetricMode.REDUCTION
        ? getFriendlyGenesAndReductionNumbers(genesAndCopyNumbers, selectedSiteDatum, minorTarget)
        : getFriendlyGenesAndCopyNumbers(genesAndCopyNumbers);

    const _inRawOrEffluentSamples = get_inRawOrEffluentSamples(selectedSiteDatum, metricMode);

    const unit =
      metricMode === MetricMode.REDUCTION ? (
        <MetricTitle noAntibiotic={true} plural={detectedCount > 1} />
      ) : (
        'Copy number'
      );
    const explanation = _inRawOrEffluentSamples ? (
      <span>
        {unit} of independently detected pathogen marker genes, {_inRawOrEffluentSamples}
      </span>
    ) : (
      <span>{unit} of independently detected pathogen marker genes</span>
    );
    return (
      <SiteDetailContainer ref={containerRef}>
        <SiteDetailTitle title={title} explanation={explanation} counts={{ detectedCount, analysedCount }} />
        <CopyNumberBarGraph
          genesAndNumbers={friendlyGenesAndNumbers}
          width={width}
          height={detailGraphHeight}
          events={false}
          metricMode={metricMode}
          italic
        />
      </SiteDetailContainer>
    );
  }
}
