import { DeleteIcon } from '@resistapp/client/components/icons/delete-icon';
import { Feature } from '@resistapp/common/features';
import { Organisation, User } from '@resistapp/common/types';
import {
  useDeleteOrganization,
  useDeleteOrganizationAccess,
  useDeleteOrganizationUser,
  usePatchOrganization,
} from '../../hooks/api';

interface DeleteOrgUserIconProps {
  refreshUsersByOrgId: () => Promise<void>;
  user: User;
  organization: Organisation;
}

export function DeleteOrgUserIcon(props: DeleteOrgUserIconProps) {
  const { refreshUsersByOrgId, organization, user } = props;
  const del = useDeleteOrganizationUser(organization.id, user.id);
  return (
    <DeleteIcon
      triggerDelete={async () => {
        await del.mutateAsync();
        await refreshUsersByOrgId();
      }}
      message={`${user.email} will be removed from ${organization.name} organization.\n\nAre you sure?`}
    />
  );
}

interface DeleteOrgAccessIconProps {
  organization: Organisation;
  projectId: number;
  projectName: string | undefined;
}

export function DeleteOrgAccessIcon(props: DeleteOrgAccessIconProps) {
  const { organization, projectId, projectName } = props;
  const del = useDeleteOrganizationAccess(organization.id, projectId);
  return (
    <DeleteIcon
      triggerDelete={async () => {
        await del.mutateAsync();
      }}
      message={`${organization.name} will loose access to project '${projectName || '?'}' (${projectId}).\n\nAre you sure?`}
    />
  );
}

export function DeleteFeatureLink(props: { featureToDelete: Feature; organisation: Organisation }) {
  const { organisation, featureToDelete } = props;
  const del = usePatchOrganization();
  return (
    <DeleteIcon
      triggerDelete={async () => {
        await del.mutateAsync({
          id: organisation.id,
          features: organisation.features.filter(f => f !== featureToDelete),
        });
      }}
      message={`Remove ${organisation.name}'s access to ${featureToDelete}?`}
    />
  );
}

export function DeleteOrganizationLink(props: { organisation: Organisation }) {
  const { organisation } = props;
  const del = useDeleteOrganization(organisation.id);
  return (
    <DeleteIcon
      triggerDelete={async () => {
        await del.mutateAsync();
      }}
      message={`THIS WILL ORPHAN ALL USERS (REVOKING THEIR ACCESSES) AND DELETE ${organisation.name}.\n\nARE YOU SURE?`}
    />
  );
}
