import styled from '@emotion/styled';
import { SizeProp, icon } from '@fortawesome/fontawesome-svg-core';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { IconBase } from './icon-base';

interface Props {
  style?: React.CSSProperties;
  title?: string;
  size?: SizeProp;
}

export const DownloadIcon = ({ style, title, size }: Props) => {
  return <StyledIcon style={style} icon={icon(faDownload)} title={title} size={size || 'sm'} />;
};

const StyledIcon = styled(IconBase)`
  transition: font-size 0.3s ease-in-out;
`;
