import { useEffect, useState } from 'react';
import BounceLoader from 'react-spinners/BounceLoader';
import { DeadCenter } from '../shared/layout';
import { oldTheme } from '../shared/old-styles';

export function LoadingView() {
  const [show, setShow] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setShow(true);
    }, 500);
    return () => {
      clearTimeout(timeout);
    };
  }, []);
  return <DeadCenter>{show && <Loading />}</DeadCenter>;
}

export function Loading() {
  const cssOverride = { display: 'bloc', margin: '0 auto', borderColor: 'red' };
  return <BounceLoader size={30} cssOverride={cssOverride} color={oldTheme.pink} />;
}
