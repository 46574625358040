import { FormControl, FormLabel } from '@chakra-ui/react';
import { sentanceCase } from '@resistapp/common/friendly';
import { chain, isNil } from 'lodash';
import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form';
import { MultiValue, SingleValue } from 'react-select';
import { ResistomapSelect } from '../resistomap-select';
import { Option, ValueAndLabel } from './types';
import { buildOption } from './utils';

interface ControlledSelectProps<F extends FieldValues, O extends Option = string> {
  control: Control<F>;
  name: FieldPath<F>;
  options: O[];
  placeholder?: string;
  isMulti?: true | undefined;
  closeMenuOnSelect?: boolean | undefined;
  labeller?: (option: O) => string;
}

export function ControlledSelect<F extends FieldValues, O extends Option = string>(props: ControlledSelectProps<F, O>) {
  const { control, name, options: rawOptions, placeholder, isMulti, labeller, closeMenuOnSelect } = props;
  const optionValuesByValue: { [k: string]: ValueAndLabel } = chain(rawOptions)
    .map(o => buildOption(o, labeller))
    .keyBy(o => o.value)
    .value();

  const options = rawOptions.map(o => buildOption(o, labeller));

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, onBlur, value, name: name2, ref }, fieldState: { error } }) => (
        <FormControl isInvalid={!!error}>
          <FormLabel>{sentanceCase(name)}</FormLabel>
          <ResistomapSelect<(typeof options)[number], true>
            isMulti={isMulti}
            closeMenuOnSelect={isNil(closeMenuOnSelect) ? !isMulti : closeMenuOnSelect}
            options={options}
            placeholder={placeholder || ''}
            onChange={(newValue: MultiValue<ValueAndLabel> | SingleValue<ValueAndLabel>) => {
              if (Array.isArray(newValue)) {
                onChange(newValue.map((option: ValueAndLabel) => option.value));
              } else if (newValue) {
                onChange((newValue as ValueAndLabel).value);
              } else {
                onChange(null);
              }
            }}
            onBlur={onBlur}
            value={
              isMulti
                ? (value || []).map((val: number | string) => optionValuesByValue[val])
                : value
                  ? optionValuesByValue[value]
                  : null
            }
            name={name2}
            ref={ref}
          />
        </FormControl>
      )}
    />
  );
}
