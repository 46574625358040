import { Coordinates, PartialDict } from '@resistapp/common/types';
import { MAPBOX_TOKEN, getServerUrl } from '@resistapp/common/utils';
import plainAxios, { AxiosError } from 'axios';
import { get } from 'lodash';
import mapbox from 'mapbox-gl';
import { storedToken } from '../components/shared/local-storage';
import { ApiResponse } from '../hooks/api';

export const API_URL = `${getServerUrl()}/api`;

export const resistomapApiClient = initializeDateParsingAxiosInstace();

function initializeDateParsingAxiosInstace() {
  const instance = plainAxios.create({
    baseURL: API_URL,
  });
  const defaultTransforms = Array.isArray(plainAxios.defaults.transformResponse)
    ? plainAxios.defaults.transformResponse
    : plainAxios.defaults.transformResponse
      ? [plainAxios.defaults.transformResponse]
      : [];
  instance.defaults.transformResponse = [jsonParseWithDates, ...defaultTransforms];

  instance.interceptors.response.use(
    response => response,
    (error: AxiosError<{ message: string; status: number; error: string }>) => {
      if (error.response?.data.message) {
        // Rely server boom error message, if found
        error.name = error.response.data.error;
        error.message = error.response.data.message;
      } else if (error.message === 'Network Error') {
        error.message =
          'Failed to reach or get a response from the server. This might be due to weak internet connection, or high server load. If it took over a minute to see this message, the process is likely still ongoing...';
      }
      throw error;
    },
  );
  return instance;
}

function jsonParseWithDates<T>(data: any, headers: PartialDict<string>) {
  const contentType = headers['content-type'];
  if (contentType?.includes('application/json') && typeof data === 'string') {
    return JSON.parse(data, dateParser) as T;
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return data;
}

const dateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;
export function dateParser(_key: any, value: any): any {
  if (typeof value === 'string' && dateRegex.test(value)) {
    return new Date(value);
  }
  return value;
}

function isError(status?: number) {
  return !status || status < 200 || status > 204;
}

export async function parseFetchResponseOld<Response>(response: globalThis.Response): Promise<ApiResponse<Response>> {
  if (response.headers.get('content-type')?.includes('image/png')) {
    const blob = await response.blob();
    return {
      data: blob as unknown as Response,
      status: response.status,
      statusText: response.statusText,
    };
  } else {
    // Assume json or html
    const text = (await response.text()).trim();
    const data: Response = (
      text.length === 0 ? null : text.startsWith('<') ? text : JSON.parse(text, dateParser)
    ) as Response;
    return {
      data,
      status: response.status,
      statusText: response.statusText,
    };
  }
}

export function getAuthHeaders(contentType?: string) {
  const headers: HeadersInit = contentType ? { 'Content-Type': contentType } : {};
  const token = storedToken();
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  return headers;
}

type MapResponse = { features: Array<{ geometry: { coordinates: [number, number] } }> };
export async function fetchAddressToCoordinates(searchText: string): Promise<Coordinates | undefined> {
  const response: ApiResponse<MapResponse> = await fetchOrThrow<undefined, MapResponse>(
    'GET',
    `${mapbox.baseApiUrl}/search/geocode/v6/forward?q=${encodeURIComponent(searchText)}&proximity=ip&access_token=${MAPBOX_TOKEN}`,
  );

  if (isError(response.status)) {
    return undefined;
  }

  // eslint-disable-next-line @typescript-eslint/no-confusing-void-expression
  const coordinates = get(response, 'data.features[0].geometry.coordinates', undefined);
  if (Array.isArray(coordinates)) {
    return {
      lat: coordinates[1],
      lon: coordinates[0],
    };
  }
  return undefined;
}

async function fetchOrThrow<RequestBody, Response>(
  method: string,
  path: string,
  bodyObj?: RequestBody,
): Promise<ApiResponse<Response>> {
  const body: undefined | string | FormData =
    bodyObj && (bodyObj instanceof FormData ? (bodyObj as FormData) : JSON.stringify(bodyObj));
  const response = await fetch(path, {
    method,
    body,
    credentials: 'omit',
  });

  return await parseFetchResponseOld(response);
}
