import { isAdmin } from '@resistapp/common/features';
import { useSearchParams } from 'react-router-dom';
import { useUser } from '../contexts/use-user-context';
import { ExperimentalMetrics } from '../data-utils/plot-data/build-experimental-metrics';

export const metricsExperimentQueryKey = 'experimental';

export function useExperimentalMetric() {
  const [searchParams] = useSearchParams();
  const { user } = useUser();
  const experimental = searchParams.get(metricsExperimentQueryKey);
  return experimental && isAdmin(user) ? (experimental as keyof ExperimentalMetrics) : null;
}
