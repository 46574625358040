import { Filters } from '@resistapp/client/data-utils/filter-data/filter';
import { OverviewDatum, OverviewProcessDatum } from '@resistapp/client/data-utils/plot-data/build-overview-line-data';
import { MapSource, MapSourceWithLevel } from '@resistapp/client/utils/map-sources';
import { getMetricAndLevel, getMetricColor } from '@resistapp/client/utils/metric-utils';
import { Target } from '@resistapp/common/assays';
import { AdminArea, AdminLevelKey, MetricMode } from '@resistapp/common/types';
import { Dictionary } from 'lodash';
import { LngLatBounds } from 'mapbox-gl';
import { MapboxMap, MapMouseEvent } from 'react-map-gl';

export enum ZoomLevels {
  marker = 13,
}

const coloredLayerId = 'colored-layer';
const activeEventListeners: Array<{
  type: MapMouseEvent['type'];
  layerId: string;
  listener: (e: MapMouseEvent & mapboxgl.EventData) => void;
}> = [];
export function activateColoredRegions(
  mapInstance: MapboxMap,
  activeMapStyle: MapSourceWithLevel,
  mapData: OverviewProcessDatum[],
  setSelectedEnvironmentId: (id: number) => void,
  environmentBoundingBoxes: Dictionary<{ ne: [number, number]; sw: [number, number] }>,
  changeCurrentAdminArea: (nextAdminArea: AdminArea) => void,
  filters: Filters,
  metricMode: MetricMode,
) {
  const sourceName = activeMapStyle.tileset.sourceLayer;
  const sourceLayer = activeMapStyle.tileset.sourceLayer;
  const layerId = `${sourceName}-${coloredLayerId}`;

  const isSourceLoaded = Boolean(mapInstance.getSource(sourceName));
  if (!isSourceLoaded) {
    mapInstance.addSource(sourceName, {
      // If you need to test some geojson data, before converting to mbtiles and uploading:
      // type: 'geojson', data: geoBoundariesFINADM1 as string,
      type: 'vector',
      url: activeMapStyle.tileset.url,
      promoteId: activeMapStyle.tileset.propertyName,
    });
  }

  const regionsWithData = new Map<string, number>();
  const matchExpression = ['match', ['get', activeMapStyle.tileset.propertyName]];

  const isCountries = activeMapStyle.tileset.admLevel.includes(2);
  const key = isCountries ? 'country' : 'region';

  // TIP for Error: layers.osmidnadm3_5-colored-layer.paint.fill-color: Expected at least 4 arguments, but found only 2.
  // This is likely due to undefined adminLevel or areaName, which causes improperly formated match expressions to be passed to addLayer
  mapData.forEach(dataItem => {
    const area = dataItem.adminLevels?.[`${activeMapStyle.adminLevel}` as AdminLevelKey]?.name;
    getMatchExpressionData(dataItem, matchExpression, regionsWithData, area, filters.selectedTargets, metricMode);
  });
  mapData.forEach(dataItem => {
    const area = getAreaName(activeMapStyle, dataItem[key]);
    getMatchExpressionData(dataItem, matchExpression, regionsWithData, area, filters.selectedTargets, metricMode);
  });

  // Last value is the default, used where there is no data
  matchExpression.push('rgba(255, 0, 0, 0)');

  const isLayerLoaded = Boolean(mapInstance.getLayer(layerId));

  if (!isLayerLoaded) {
    if (matchExpression.length < 5) {
      console.error('fill-color needs at least 4 parameters', matchExpression, mapData);
      return;
    }

    // const colorInterpolator = interpolateRgb('white', 'red');

    mapInstance.addLayer(
      {
        id: layerId,
        type: 'fill',
        source: sourceName,
        'source-layer': sourceLayer,
        filter: isCountries ? ['all'] : ['==', ['get', 'admin_level'], activeMapStyle.adminLevel],
        paint: {
          // The matchExpression works as string | string[], so we just ignore it here and force a dumb type
          'fill-color': matchExpression as unknown as string,
          // [
          //   'case',
          //   ['has', ['to-string', ['get', activeMapStyle.tileset.propertyName]], ['literal', regionsWithData]],
          //   [
          //     'interpolate',
          //     ['linear'],
          //     ['get', ['to-string', ['get', activeMapStyle.tileset.propertyName]], ['literal', regionsWithData]],
          //     0,
          //     metricMode === MetricType.ARGI
          //       ? resistanceLevelMetadata[ResistanceLevel.low].color
          //       : colorInterpolator(0),
          //     2.5,
          //     metricMode === MetricType.ARGI
          //       ? resistanceLevelMetadata[ResistanceLevel.moderate].color
          //       : colorInterpolator(0.5),
          //     5,
          //     metricMode === MetricType.ARGI
          //       ? resistanceLevelMetadata[ResistanceLevel.high].color
          //       : colorInterpolator(1),
          //   ],
          //   'rgba(0, 0, 0, 0)',
          // ],
          'fill-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 0.9, 0.7],
        },
      },
      'waterway-label', // 'building' is another option
    );

    mapInstance.addLayer(
      {
        id: `${layerId}-border`,
        type: 'line',
        source: sourceName,
        'source-layer': sourceLayer,
        filter: ['==', ['get', 'admin_level'], activeMapStyle.adminLevel],
        paint: {
          'line-color': 'rgba(0, 0, 0, 0.5)',
          'line-opacity': 0.35,
        },
      },
      'waterway-label', // 'building' is another option
    );
  }

  let mutatingHoveredRegionName: string | undefined;
  mapInstance.on('mousemove', layerId, e => {
    const features = e.features;
    const currentHoveredRegionName = features?.[0]?.properties?.local_name as string | undefined;

    if (features && features.length > 0) {
      if (currentHoveredRegionName !== mutatingHoveredRegionName && mutatingHoveredRegionName) {
        mapInstance.setFeatureState(
          {
            source: sourceName,
            sourceLayer,
            id: mutatingHoveredRegionName,
          },
          { hover: false },
        );
      }

      if (currentHoveredRegionName) {
        mapInstance.setFeatureState(
          {
            source: sourceName,
            sourceLayer,
            id: currentHoveredRegionName,
          },
          { hover: true },
        );
      }
    }

    mutatingHoveredRegionName = currentHoveredRegionName;
  });

  mapInstance.on('mouseleave', layerId, () => {
    if (mutatingHoveredRegionName) {
      mapInstance.setFeatureState(
        {
          source: sourceName,
          sourceLayer,
          id: mutatingHoveredRegionName,
        },
        { hover: false },
      );
    }

    mutatingHoveredRegionName = undefined;
  });

  const handleRegionHover = createHandleRegionHover(
    mapInstance,
    layerId,
    activeMapStyle,
    regionsWithData,
    setSelectedEnvironmentId,
  );
  const handleRegionClick = createHandleRegionClick(
    mapInstance,
    layerId,
    activeMapStyle,
    regionsWithData,
    environmentBoundingBoxes,
    mapData,
    changeCurrentAdminArea,
  );

  // We need to remove the old event listeners first, since we recreate them everytime.
  activeEventListeners.forEach(listener => void mapInstance.off(listener.type, listener.layerId, listener.listener));
  activeEventListeners.length = 0;
  activeEventListeners.push(
    { type: 'click', layerId, listener: handleRegionClick },
    { type: 'mousemove', layerId, listener: handleRegionHover },
    { type: 'mouseleave', layerId, listener: handleRegionLeave },
  );
  activeEventListeners.forEach(listener => void mapInstance.on(listener.type, listener.layerId, listener.listener));
}

function createHandleRegionHover(
  mapInstance: MapboxMap,
  layerId: string,
  activeMapStyle: MapSource,
  regionsWithData: Map<string, number>,
  setSelectedEnvironmentId: (id: number) => void,
) {
  return (e: MapMouseEvent & mapboxgl.EventData) => {
    const { region, environmentId } = getLayerDataAtPoint(mapInstance, e, layerId, activeMapStyle, regionsWithData);

    if (region && environmentId) {
      setCanvasCursor(e, 'pointer');
      setSelectedEnvironmentId(environmentId);
    } else {
      setCanvasCursor(e, 'default');
    }
  };
}

// createHandleRegionClick gets coordinates from the mapbox vector tile, that resides in mapbox studio, but since those
// coordinates didn't seem to be enough, we also add all the environment coordinates and calculate the bounding box
// from all of those. Otherwise we might end up zooming to an area of the region, that has no samples.
function createHandleRegionClick(
  mapInstance: MapboxMap,
  layerId: string,
  activeMapStyle: MapSourceWithLevel,
  regionsWithData: Map<string, number>,
  environmentBoundingBoxes: Dictionary<{ ne: [number, number]; sw: [number, number] }>,
  mapData: OverviewProcessDatum[],
  changeCurrentAdminArea: (nextAdminArea: AdminArea) => void,
) {
  return (e: MapMouseEvent & mapboxgl.EventData) => {
    const { environmentId, feature } = getLayerDataAtPoint(mapInstance, e, layerId, activeMapStyle, regionsWithData);
    const region = feature.properties?.[activeMapStyle.tileset.propertyName] as string;

    const correctRegionFeature = mapInstance.queryRenderedFeatures(undefined, {
      layers: [layerId],
      filter: [
        'all',
        ['==', ['get', 'admin_level'], activeMapStyle.adminLevel],
        ['==', ['get', activeMapStyle.tileset.propertyName], region],
      ],
    });

    if (environmentId) {
      const envAdminLevels = mapData.find(d => d.environment.id === environmentId)?.adminLevels;
      // TODO WN?
      const nextAdminLevel = Object.values(envAdminLevels ?? {}).find(
        d => Number(d.level) === activeMapStyle.adminLevel,
      );

      if (nextAdminLevel) {
        changeCurrentAdminArea(nextAdminLevel);
        return;
      }

      const coordinates = (feature.geometry as { coordinates: number[][] }).coordinates;

      const flattenedCoordinates = correctRegionFeature.map(_feature => coordinates.flat(5)).flat();
      const allCoordinates = [
        ...flattenedCoordinates,
        ...environmentBoundingBoxes[environmentId].ne.flat(),
        ...environmentBoundingBoxes[environmentId].sw.flat(),
      ];
      const { highest, lowest } = getHighestAndLowestCoordinates(allCoordinates);

      const boundsLocal = new LngLatBounds(lowest, highest);

      mapInstance.fitBounds(boundsLocal, { padding: 20 });
    }
  };
}

function handleRegionLeave(e: MapMouseEvent & mapboxgl.EventData) {
  setCanvasCursor(e, 'default');
}

function setCanvasCursor(e: MapMouseEvent & mapboxgl.EventData, cursor: string) {
  e.target.getCanvasContainer().style.cursor = cursor;
}

function getAreaName(activeMapStyle: MapSource, area?: string) {
  if (!area) return undefined;
  return (activeMapStyle as { mappings?: Record<string, string> }).mappings?.[area] || area;
}

function getLayerDataAtPoint(
  mapInstance: MapboxMap,
  e: MapMouseEvent & mapboxgl.EventData,
  layerId: string,
  activeMapStyle: MapSource,
  regionsWithData: Map<string, number>,
) {
  const features = mapInstance.queryRenderedFeatures(e.point, {
    layers: [layerId],
  });
  const feature = features[0];

  const region = feature.properties?.[activeMapStyle.tileset.propertyName] as string;
  return { region, environmentId: regionsWithData.get(region), feature };
}

function getHighestAndLowestCoordinates(coordinates: number[]): {
  highest: [number, number];
  lowest: [number, number];
} {
  const lons = coordinates.filter((_coord, index) => index % 2 === 0);
  const lats = coordinates.filter((_coord, index) => index % 2 === 1);
  const highest = [Math.max(...lons), Math.max(...lats)] as [number, number];
  const lowest = [Math.min(...lons), Math.min(...lats)] as [number, number];

  return { highest, lowest };
}

export function getBoundingBoxForEnvironment(data: OverviewDatum[]) {
  const highestAndLowestCoordinates = data
    .flat(3)
    .filter(d => d.inferredLon && d.inferredLat)
    .map(d => getHighestAndLowestCoordinates([d.inferredLon as number, d.inferredLat as number]));
  const ne = highestAndLowestCoordinates.map(coords => coords.highest);
  const sw = highestAndLowestCoordinates.map(coords => coords.lowest);

  return { ne, sw };
}

export function deactivateColoredRegions(mapInstance: MapboxMap) {
  const layers = mapInstance.getStyle().layers;

  if (layers.length) {
    layers.forEach(layer => {
      if (layer.id.includes('colored-layer')) {
        mapInstance.removeLayer(layer.id);
      }
    });
  }
}

function getMatchExpressionData(
  dataItem: OverviewProcessDatum,
  matchExpression: Array<string | string[]>,
  regionsWithData: Map<string, number>,
  area: string | undefined,
  targets: Target[],
  metricMode: MetricMode,
): void {
  if (!area || matchExpression.includes(area)) {
    return;
  }

  const [metric] = getMetricAndLevel(dataItem.abundances, dataItem.afterAbundances, targets, metricMode);
  const color = getMetricColor(metric, metricMode);

  matchExpression.push(area, color);
  regionsWithData.set(area, dataItem.environment.id);
}
