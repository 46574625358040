/* eslint-disable @typescript-eslint/no-unsafe-enum-comparison */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { chain, Dictionary, keyBy } from 'lodash';

// ----- ASSAYS
// When adding new assays, remember to update the following
// - assays_.json
// - Customer template gene selection (remember to edit data protection range)
// - Lab Template 600 plate positions
// - Lab Template Diluted plate positions
// - Targets and gene groups below if adding new ones
import { z } from 'zod';
import assays from './assays_.json';

// ----- TARGETS
// To modify targets, modify at least this section, assays_.json, targetPalette, analysis and Customer Template.
export type Target = keyof typeof Targets;
export enum Targets {
  MGE = 'MGE',
  Integrons = 'Integrons',
  'Beta-Lactam' = 'Beta-Lactam',
  Vancomycin = 'Vancomycin',
  MDR = 'MDR',
  Trimethoprim = 'Trimethoprim',
  Phenicol = 'Phenicol',
  Quinolone = 'Quinolone',
  Sulfonamide = 'Sulfonamide',
  Tetracycline = 'Tetracycline',
  Aminoglycoside = 'Aminoglycoside',
  MLSB = 'MLSB',
  Other = 'Other',
  Taxonomic = 'Taxonomic',
  '16S rRNA' = '16S rRNA',
}

export const sixteenS = '16S rRNA' as const satisfies Target;
export const zTargets = z.nativeEnum(Targets);

export function isCarrier(targetOrAssayOrGene: string) {
  const target = Targets[targetOrAssayOrGene as Target] || getTarget(targetOrAssayOrGene);
  return target === Targets.MGE || target === Targets.Integrons;
}

export function isTaxonomic(targetOrAssayOrGene: string) {
  const target = Targets[targetOrAssayOrGene as Target] || getTarget(targetOrAssayOrGene);
  return target === Targets.Taxonomic;
}

export function isAntibiotic(targetOrAssayOrGene: string) {
  // TODO new-antibiotic grouping remove one off gene hacks when implementing new gene classification
  // NOTE: the temporary overview hacks are not active when checking for antibiotic status based on target
  // NOTE: when removing, edit also getAbundanceLog10Stats
  // See: https://docs.google.com/spreadsheets/d/15kQ2Mr0FVRWfXBd9EaHY7vXP2LMN5pNF9SkaOSJmxgo/edit?gid=0#gid=0
  if (targetOrAssayOrGene === 'AY621' || targetOrAssayOrGene === 'mphE') {
    return true;
  }
  if (
    targetOrAssayOrGene === 'AY25' ||
    targetOrAssayOrGene === 'AY247' ||
    targetOrAssayOrGene === 'speA' ||
    targetOrAssayOrGene === 'folA_1'
  ) {
    return false;
  }
  const target = Targets[targetOrAssayOrGene as Target] || getTarget(targetOrAssayOrGene);
  return !isCarrier(target) && !isTaxonomic(target) && target !== Targets.Other && target !== sixteenS;
}

// -----

// --- GENE GROUPS
interface AssayInfo {
  assay: string;
  gene: string;
  target: Target;
  antibiotic?: string;
  carbapenem?: string;
  mge?: string;
  [sixteenS]?: string;
  minMeltingTemperature?: number;
  maxMeltingTemperature?: number;
}
type AssayRows = AssayInfo[];

export const allAssays: AssayRows = assays.map(assay => {
  const casted = {
    ...assay,
    target: assay.target as Target,
    antibiotic: isAntibiotic(assay.target as Target) ? assay.target : undefined,
    [sixteenS]: assay.target === sixteenS ? assay.target : undefined,
  };
  if (!Targets[casted.target]) {
    throw Error(`Gene target ${assay.target} off sync`);
  }
  return casted;
});

export const nonSixteenSTargets: Target[] = Object.values(Targets).filter(t => t !== sixteenS);
export const antibioticTargets: Target[] = Object.values(Targets).filter(isAntibiotic);

// This is mainly intended for UI grouping and coloring of genes based on all available categories (groups)
export const allGeneGroups = {
  [sixteenS]: [sixteenS],
  target: nonSixteenSTargets,
  antibiotic: antibioticTargets,
  assay: chain(allAssays) // Unused?
    .map(row => row.assay)
    .compact()
    .uniq()
    .value(),
  gene: chain(allAssays) // Unused?
    .map(row => row.gene)
    .compact()
    .uniq()
    .value(),
  carbapenem: chain(allAssays)
    .map(row => row.carbapenem)
    .compact()
    .uniq()
    .sort()
    .value(),
  mge: chain(allAssays)
    .map(row => row.mge)
    .compact()
    .uniq()
    .sort()
    .value(),
} as const satisfies Record<string, Targets[] | string[]>;

export type GeneGrouping = keyof typeof allGeneGroups;
export const selectableGeneGroups: GeneGrouping[] = ['target', 'antibiotic', 'carbapenem', 'mge'];

export const carbapenem = 'Carbapenem' as const;
export const groupingName = {
  target: 'All genes classes',
  antibiotic: 'All antibiotics',
  carbapenem,
  mge: 'MGEs',
  [sixteenS]: sixteenS,
  gene: '',
  assay: '',
};
export interface GroupingOption {
  label: string;
  value: GeneGrouping;
}
export const defaultGroupingOptions: GroupingOption[] = selectableGeneGroups.map(value => ({
  label: groupingName[value],
  value,
}));
// -----

const assayInfoByAssay: Dictionary<AssayInfo> = keyBy(allAssays, row => row.assay);
export const assayInfoByGene: Dictionary<AssayInfo> = keyBy(allAssays, row => row.gene);
export function getGroup(geneOrAssay: string, grouping: GeneGrouping = 'target'): Target | undefined {
  const assayInfo = assayInfoByAssay[geneOrAssay] || assayInfoByGene[geneOrAssay];

  if (!assayInfo) {
    throw Error(`Unrecognized gene or assay ${geneOrAssay}`);
  }
  const group = assayInfo[grouping] as Target | undefined;
  if (grouping === 'target' && !group) {
    throw Error(`Assay info is missing target`);
  }
  return group;
}

export function getGene(assay: string): string {
  const gene = getGroup(assay, 'gene');
  if (!gene) {
    throw Error(`Assay info is missing gene ${gene}`);
  }
  return gene;
}

export function getAssay(gene: string): string {
  const assay = getGroup(gene, 'assay');
  if (!assay) {
    throw Error(`Assay info is missing gene ${gene}`);
  }
  return assay;
}

export function getTarget(geneOrAssay: string): Target {
  const target = getGroup(geneOrAssay, 'target');
  if (!target) {
    throw Error(`Assay info is missing gene or assay ${geneOrAssay}`);
  }
  return target as Target;
}
