import { extendTheme } from '@chakra-ui/react';
import { BaseColors } from '@resistapp/common/base-styles';

export enum FontFamily {
  base = 'Inter, sans-serif',
  baseVariable = 'InterVariable, sans-serif',
  big = 'InterDisplay, sans-serif',
  dmSans = 'DM Sans, sans-serif',
}
export enum FontSize {
  h0 = '64px',
  h1 = '40px',
  h2 = '28px',
  h3 = '24px',
  h4 = '20px',
  large = '18px',
  medium = '14px',
  small = '12px',
  extraSmall = '10px',
}
export enum FontWeight {
  extraHeavy = 700,
  heavy = 600,
  bold = 500,
  light = 400,
}

export const Spacing = Object.freeze({
  0.5: '2px',
  1: '4px',
  2: '8px',
  3: '12px',
  4: '16px',
  6: '24px',
  8: '32px',
  12: '48px',
  16: '64px',
} as const);

type BorderRadiusTypes = 'none' | 'tiny' | 'small' | 'normal' | 'large' | 'full';
export const theme = {
  // We use chakra basecolors also: https://v2.chakra-ui.com/docs/styled-system/theme
  colors: {
    ...BaseColors,
    ...{
      riskScore100: '#53001D', // This represents the highest risk score color
      riskScore0: BaseColors.red200, // This represents the lowest risk score color
      reductionMinus100: BaseColors.red900, // This represents the lowest fold change color
      reduction100: BaseColors.blue900, // This represents the lowest fold change color
      reduction50: BaseColors.blue500, // This represents the medium fold change color
      reduction0: BaseColors.blue50, // This represents the lowest fold change color
      levelLow: '#5874BB',
      levelMod: '#B76B8B',
      levelHigh: '#4C2B3A',
      transparentBlue: 'rgba(0, 100, 250, 0.08)',
      maroon: '#51293b',
      backgroundGrayTransparent6: '#F8FAFCA0',
      backgroundBlack: '#262626',
      bodyGray: '#364153',
      chartOrangeMid: '#FFA04D',
      chartPurpleDark: '#9467BD',
    },
  },
  spacing: Spacing,
  oldSpacing: {
    xxs: '5px',
    xs: '10px',
    s: '15px', // TODO, what should this be?
    m: '20px', // TODO, what should this be?
    l: '25px', // TODO, what should this be?
    xl: '30px', // TODO, what should this be?
  },
  shadows: {
    normal: { filter: 'drop-shadow(0px 0px 4px rgba(41, 54, 81, 0.25))' },
    strong: { filter: 'drop-shadow(0px 0px 7px black)' },
    superStrong: { filter: 'drop-shadow(0px 0px 14px black)' },
  },
  borders: {
    dashed: `1px dashed ${BaseColors.neutral300}`,
    radius: {
      none: '0px',
      tiny: '2px',
      small: '5px',
      normal: '8px',
      large: '14px',
      full: '100em',
    } as const satisfies Record<BorderRadiusTypes, string>,
    selected: `2px solid ${BaseColors.blue500}`,
    normal: `1px solid ${BaseColors.neutral300}`,
  },
  addBorder: (
    strength: 900 | 800 | 700 | 600 | 500 | 400 | 300 | 200 | 100 | 50,
    radius?: BorderRadiusTypes,
    color: 'neutral' | 'blue' | 'red' = 'neutral',
  ) => {
    const borderCSS = `border: 1px solid ${BaseColors[`${color}${strength}`]};`;
    const borderRadiusCSS = radius ? `border-radius: ${theme.borders.radius[radius]};` : '';
    return `${borderCSS} ${borderRadiusCSS}`;
  },
  fontSize: FontSize,
  oldFontSize: { xxs: '10px', xs: '14px', m: '15px', l: '20px', xxl: '36px' },
  fontWeight: FontWeight,
  fontFamily: FontFamily,
  zIndexes: {
    footerZ: -1, // behind worldmap
    mainSection: 1,
    heatmapYZ: 1,
    markers: {
      default: 1,
      selected: 2,
    },
    overviewMapControls: 3,
    overviewMap_siteSelected: 10,
    infoIcon: 20,
    tutorial: 21,
    closeIcon: 1000,
    header: 1234,
    heatmapXZ: 12345,
    titleZ: 123456,
    tooltipZ: 1234567,
  },
} as const;

const activeLabelStyles = {
  transform: 'scale(0.85) translateY(-24px)',
} as const;
// Chakra themes. Good sources:
// https://v2.chakra-ui.com/docs/styled-system/theme
// For components states, like disabled: https://v2.chakra-ui.com/docs/styled-system/style-props
export const chakraTheme = extendTheme({
  // These should be chakra default breakpoint + 1700px
  breakpoints: {
    base: '0em', // 0px
    sm: '30em', // ~480px
    md: '48em', // ~768px
    lg: '62em', // ~992px
    xl: '80em', // ~1280px
    '2xl': '96em', // ~1536px
    '1700px': '1700px',
  },
  colors: {
    red: {
      50: BaseColors.red50,
      100: BaseColors.red100,
      200: BaseColors.red200,
      300: BaseColors.red300,
      400: BaseColors.red400,
      500: BaseColors.red500,
      600: BaseColors.red600,
      700: BaseColors.red700,
      800: BaseColors.red800,
      900: BaseColors.red900,
    },
    blue: {
      50: BaseColors.blue50,
      100: BaseColors.blue100,
      200: BaseColors.blue200,
      300: BaseColors.blue300,
      400: BaseColors.blue400,
      500: BaseColors.blue500,
      600: BaseColors.blue600,
      700: BaseColors.blue700,
      800: BaseColors.blue800,
      900: BaseColors.blue900,
    },
    green: {
      50: BaseColors.green50,
      100: BaseColors.green100,
      200: BaseColors.green200,
      300: BaseColors.green300,
      400: BaseColors.green400,
      500: BaseColors.green500,
      600: BaseColors.green600,
      700: BaseColors.green700,
      800: BaseColors.green800,
      900: BaseColors.green900,
    },
    neutral: {
      50: BaseColors.neutral50,
      100: BaseColors.neutral100,
      200: BaseColors.neutral200,
      300: BaseColors.neutral300,
      400: BaseColors.neutral400,
      500: BaseColors.neutral500,
      600: BaseColors.neutral600,
      700: BaseColors.neutral700,
      800: BaseColors.neutral800,
      900: BaseColors.neutral900,
    },
  },
  fonts: {
    body: 'InterVariable, sans-serif',
    heading: 'InterVariable, sans-serif',
  },
  components: {
    Tooltip: {
      baseStyle: {
        zIndex: theme.zIndexes.tooltipZ,
      },
    },
    Checkbox: {
      baseStyle: {
        control: {
          _checked: {
            bg: theme.colors.blue900,
            borderColor: theme.colors.blue900,
            color: 'white',

            _hover: {
              bg: theme.colors.blue900,
              borderColor: theme.colors.blue900,
            },
          },
        },
      },
    },
    Link: {
      baseStyle: {
        _focusVisible: {
          border: `1px solid ${theme.colors.blue500}`,
        },
      },
    },
    Button: {
      baseStyle: {
        textTransform: 'capitalize',
        bg: theme.colors.blue900,
        color: 'white',
        borderRadius: '8px',
        border: `0`,
        _hover: {
          bg: theme.colors.blue800,
          _disabled: {
            bg: theme.colors.neutral400,
          },
        },

        _disabled: {
          bg: theme.colors.neutral400,
        },
        _focusVisible: {
          bg: theme.colors.blue500,
          boxShadow: 0,
        },
      },
      variants: {
        base: {},
        green: {
          bg: theme.colors.green800,
          color: 'white',
          _hover: {
            bg: theme.colors.green600,
            _disabled: {
              bg: theme.colors.neutral400,
            },
          },
          _disabled: {
            bg: theme.colors.neutral400,
          },
          _focusVisible: {
            bg: theme.colors.green500,
            boxShadow: 0,
          },
        },
      },
      defaultProps: {
        // Then here we set the base variant as the default
        variant: 'base',
      },
    },
    Heading: {
      variants: {
        h3: {
          color: theme.colors.neutral900,
          fontFamily: theme.fontFamily.base,
          fontSize: '20px',
          fontStyle: 'normal',
          fontWeight: 600,
        },
      },
    },
    Form: {
      variants: {
        floating: {
          container: {
            color: theme.colors.neutral500,
            _focusWithin: {
              label: {
                color: theme.colors.neutral800,
                ...activeLabelStyles,
              },
            },
            'input:not(:placeholder-shown) + label, .chakra-select__wrapper + label, textarea:not(:placeholder-shown) ~ label':
              {
                ...activeLabelStyles,
              },
            label: {
              bg: 'white',
              top: 0,
              left: 0,
              zIndex: 2,
              position: 'absolute',
              pointerEvents: 'none',
              mx: 3,
              px: 1,
              my: 2,
              transformOrigin: 'left top',
            },
          },
        },
      },
    },
    // Select: {
    //   baseStyle: {
    //     field: {
    //       bg: 'red.500',
    //       borderColor: 'red.500',
    //       color: 'red',
    //       _hover: {
    //         bg: 'red.600',
    //         borderColor: 'red.600',
    //       },
    //       _focus: {
    //         boxShadow: '0 0 0 1px red.700',
    //       },
    //     },
    //     icon: {
    //       color: 'red',
    //     },
    //   },
    // },
  },
  styles: {
    global: {
      body: {
        backgroundColor: theme.colors.neutral50,
        fontFeatureSettings: '"calt" 1, "liga" 1',
      },
    },
  },
  headers: {
    h3: `
      color: ${theme.colors.neutral900};
      font-family: ${theme.fontFamily.base};
      font-size: 20px;
      font-style: normal;
      font-weight: 600`,
  },
});

// https://stackoverflow.com/questions/826782/how-to-disable-text-selection-highlighting
export const noTextSelection = `
   -webkit-touch-callout: none; /* iOS Safari */
   -webkit-user-select: none; /* Mac Safari */
   -khtml-user-select: none; /* Konqueror */
   -moz-user-select: none; /* Firefox */
   -ms-user-select: none; /* IE & Edge */
   user-select: none; /* Chrome & Opera */
`;

export const oldBorderRadius = '6px';

export const oldCss = Object.freeze({
  greyBorder200: theme.addBorder(200, 'normal'),
  greyBorder: theme.addBorder(300, 'normal'),
});
