import { KeysPressOptions } from '@resistapp/client/components/plots/legends/legend';
import { GeneGrouping, getGroup, sixteenS, Target } from '@resistapp/common/assays';
import { EnvironmentType } from '@resistapp/common/environment-types';
import { extractSampleNumber, getSampleUID } from '@resistapp/common/sample-uid-utils';
import { FullSample, FullSamplesByUID } from '@resistapp/common/types';
import { flattenRelevantAbundances, flattenSamplesByUID, groupBioSamples } from '@resistapp/common/utils';
import { closestIndexTo, compareAsc, isWithinInterval } from 'date-fns';
import { Dictionary, difference, mapValues, min as minDate, union, uniq } from 'lodash';

export enum AbunanceSelection {
  ANALYSED = 'ANALYSED',
  QUANTIFIED_AND_TRACES = 'QUANTIFIED_AND_TRACES',
  QUANTIFIED_ONLY = 'QUANTIFIED_ONLY',
}

export interface FilterInterval {
  start: Date;
  end: Date;
}

export interface Filters {
  // Selected environment types (from query params), or all types if no query param
  // - In research view, selecting multiple samples manually can lead to a few env types being selected at a time, but the filter bar only shows this is the case, and does not allow selecting mulitple from the bar itself
  // - In overview, only a single enviroment type can be selected at a time, since pooling samples accross environment types does not make sense biologically
  //   - TODO add (Antibiotic-like?) selector for the environment type in the overview focus title
  //   - Until then: overview selects the first existing env type in the order of prioriority (see: overviewEnvironmentTypesInPrioOrder)
  selectedEnvironmentTypes: EnvironmentType[];
  // Selected environment type when only one is selected
  singleSelectedEnvironmentType?: EnvironmentType;
  selectedEnvironmentNamesOrdered: string[]; // Environment names in selection order (from query params), or all names if no query param
  selectedTargets: Target[]; // Selected targets of the selected grouping (from query params), or all targets if no query param
  selectedTargetGrouping: GeneGrouping;
  abundances: AbunanceSelection;
  absoluteMode: boolean;
  interval: FilterInterval;
}

export function getNextToggledFilter<T extends string>(toggled: T | T[], current: T[], defaults: T[], only = false) {
  const arrToggled = Array.isArray(toggled) ? toggled : [toggled];

  if (only) {
    // Return defaults if toggling the same single item in current
    if (current.length === 1 && arrToggled.length === 1 && arrToggled[0] === current[0]) {
      return defaults;
    }
    // When only is true, we replace the current selection with the toggled values if multiple, or filter defaults if single
    const next = arrToggled.length > 1 ? arrToggled : defaults.filter(d => arrToggled.includes(d));

    return next;
  } else {
    // Handle each toggled value individually
    const next = difference(
      union(
        current,
        arrToggled.filter(v => !current.includes(v)),
      ),
      arrToggled.filter(v => current.includes(v)),
    );

    return next.length ? next : defaults;
  }
}

export function filterSelectedGeneGroups(
  groups: string[],
  geneGroups: Array<string | undefined>,
  selectedGroups: string[],
) {
  const filteredGeneGroups = groups.filter(g => geneGroups.includes(g) && groups.includes(g)).reverse();
  let enabledGeneGroups = filteredGeneGroups.filter(t => groups.includes(t));
  if (selectedGroups.length) {
    enabledGeneGroups = enabledGeneGroups.filter(g => selectedGroups.includes(g));
  }

  return enabledGeneGroups;
}

export function filterSelectedGeneGroupings(
  groups: string[],
  geneGroups: Array<string | undefined>,
  selectedGroups: string[],
) {
  const filteredGeneGroups = groups.filter(g => geneGroups.includes(g) && groups.includes(g)).reverse();
  let enabledGeneGroups = filteredGeneGroups.filter(t => groups.includes(t));
  if (selectedGroups.length) {
    enabledGeneGroups = enabledGeneGroups.filter(g => selectedGroups.includes(g));
  }

  return enabledGeneGroups;
}

export function filterSelectedEnvironments(focusedByUID: FullSamplesByUID, selectedSamples: string[]) {
  let focusedSamples = flattenSamplesByUID(focusedByUID);
  if (selectedSamples.length) {
    focusedSamples = focusedSamples.filter(s => selectedSamples.includes(s.environment.name));
  }

  return focusedSamples;
}

export function filterSelectedEnvironmentTypes(focusedByUID: FullSamplesByUID, selectedSamples: string[]) {
  let focusedSamples = flattenSamplesByUID(focusedByUID);
  if (selectedSamples.length) {
    focusedSamples = focusedSamples.filter(s => selectedSamples.includes(s.environment.name));
  }

  return focusedSamples;
}

// environmentTypes, environmentNames and groups can be empty arrays and they act as undefined.
// If groups are defined selectedGrouping is used. If groups is empty array, selectedGrouping is mostly ignored.

// TODO: The filtering should be as separated as possible. So that you can filter the specific properties you want.
// Currently you have to pass the whole filters object, that requires multiple properties that are not really needed.
export function filterAndRenumber(samplesByUID: FullSamplesByUID, filters: Filters) {
  const samples = flattenSamplesByUID(samplesByUID);
  const focusedSamples = filterSamples(samples, filters);
  const focusedSamplesAndAbundances = filterAbundances(focusedSamples, filters);
  return sortRelabelAndRegroup(focusedSamplesAndAbundances, filters);
}

function filterSamples(samples: FullSample[], filters: Filters): FullSample[] {
  return samples
    .filter(
      sample =>
        !filters.selectedEnvironmentTypes.length || filters.selectedEnvironmentTypes.includes(sample.environment.type),
    )
    .filter(
      sample =>
        !filters.selectedEnvironmentNamesOrdered.length ||
        filters.selectedEnvironmentNamesOrdered.includes(sample.environment.name),
    )
    .filter(sample => !sample.time || isWithinInterval(new Date(sample.time), filters.interval));
}

function filterAbundances(samples: FullSample[], filters: Filters): FullSample[] {
  const showTraces = filters.abundances !== AbunanceSelection.QUANTIFIED_ONLY;
  const flatAbundances = flattenRelevantAbundances(samples, filters.selectedTargetGrouping === sixteenS);
  const showByAssay = flatAbundances.reduce<Dictionary<boolean>>((acc, abundance) => {
    acc[abundance.assay] =
      acc[abundance.assay] ||
      filters.abundances === AbunanceSelection.ANALYSED ||
      abundance.relative !== null ||
      (showTraces && abundance.traces);
    return acc;
  }, {});
  return samples.map(sample => {
    const abundances = sample.abundances
      .filter(datum => showByAssay[datum.assay])
      .filter(
        datum =>
          !filters.selectedTargets.length ||
          filters.selectedTargets.includes(getGroup(datum.assay, filters.selectedTargetGrouping) as Target), // undefined are ok
      );
    return {
      ...sample,
      abundances,
    };
  });
}

function sortRelabelAndRegroup(focusedBioRepSamples: FullSample[], filters: Filters): FullSamplesByUID {
  const sortedTimes = focusedBioRepSamples
    .filter(sample => sample.time)
    .map(sample => (sample.time ? new Date(sample.time) : new Date('nothing'))) // new Date('nothing') creates invalid date
    .sort(compareAsc);

  const bioRepsByUID = groupBioSamples(focusedBioRepSamples);
  if (sortedTimes.length !== focusedBioRepSamples.length || uniq(sortedTimes).length <= 1) {
    return bioRepsByUID;
  }

  const timeByUID = mapValues(bioRepsByUID, bioSamples =>
    minDate(bioSamples.map(sample => (sample.time ? new Date(sample.time) : new Date('nothing')))),
  );
  const sampleUIDs = uniq(focusedBioRepSamples.map(sample => getSampleUID(sample)));
  const sortedUIDs = sampleUIDs.sort((a, b) =>
    compareByTypeNameAndTime(a, b, bioRepsByUID, timeByUID as Dictionary<Date>, sortedTimes, filters),
  );
  return sortedUIDs.reduce<FullSamplesByUID>((sortedSoFar, oldUID, _index) => {
    const bioSamples = bioRepsByUID[oldUID];
    const relabeledSamples = bioSamples.map(sample => ({
      ...sample,
      number: extractSampleNumber(oldUID),
    }));
    sortedSoFar[oldUID] = relabeledSamples;
    return sortedSoFar;
  }, {});
}

function compareByTypeNameAndTime(
  aUID: string,
  bUID: string,
  samplesByUID: FullSamplesByUID,
  timeBySampleUID: Dictionary<Date>,
  sortedTimes: Date[],
  filters: Filters,
) {
  const aRank = sampleSortValue(samplesByUID[aUID][0], timeBySampleUID[aUID], sortedTimes, filters);
  const bRank = sampleSortValue(samplesByUID[bUID][0], timeBySampleUID[aUID], sortedTimes, filters);
  return aRank - bRank;
}

function sampleSortValue(sample: FullSample, date: Date, sortedTimes: Date[], filters: Filters) {
  const typeIndex =
    filters.selectedEnvironmentTypes.length && filters.selectedEnvironmentTypes.indexOf(sample.environment.type);
  const nameIndex =
    filters.selectedEnvironmentNamesOrdered.length &&
    filters.selectedEnvironmentNamesOrdered.indexOf(sample.environment.name);
  const dateIndex = closestIndexTo(date, sortedTimes);
  return typeIndex * 1000_000 + nameIndex * 1000 + (dateIndex ?? 0);
}

// It returns the new selection.
function selectContinuousRangeOfValues(allSelections: string[], selectedValues: [string, string]) {
  const selectedIndex1 = allSelections.findIndex(s => s === selectedValues[0]);
  const selectedIndex2 = allSelections.findIndex(s => s === selectedValues[1]);
  const smallerValueIndex = selectedIndex1 < selectedIndex2 ? selectedIndex1 : selectedIndex2;
  const largerValueIndex = selectedIndex1 < selectedIndex2 ? selectedIndex2 : selectedIndex1;
  const newSelection = allSelections.filter((_s, index) => index >= smallerValueIndex && index <= largerValueIndex);

  return newSelection;
}

// This manages the shift and control click selections
export function handleFiltersSelectionWithKeys(
  previousGroups: Filters['selectedTargets'] | Filters['selectedEnvironmentNamesOrdered'],
  allGroups: string[],
  selectedLabel: string,
  previousLabel: string | undefined,
  keys: KeysPressOptions,
  disableKeys?: boolean,
): [string | string[], boolean] {
  if (keys.shift && !disableKeys) {
    const areAllSelected = previousGroups.length === allGroups.length;

    // This is the first click with shift, so we don't select a range yet, only single value
    if (!previousLabel || areAllSelected) {
      previousLabel = previousGroups[0];
      return [selectedLabel, true] as const;
    }

    const newGroups = selectContinuousRangeOfValues(allGroups, [selectedLabel, previousLabel]);
    return [newGroups, true] as const;
  }

  previousLabel = selectedLabel;
  return [selectedLabel, Boolean(disableKeys) || !keys.ctrl] as const;
}
