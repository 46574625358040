export function getOS() {
  const userAgent = window.navigator.userAgent.toLowerCase();
  const platformMatchers = [
    { pattern: /(macintosh|macintel|macppc|mac68k|macos)/i, os: 'macos' },
    { pattern: /(iphone|ipad|ipod)/i, os: 'ios' },
    { pattern: /(win32|win64|windows|wince)/i, os: 'windows' },
    { pattern: /android/i, os: 'android' },
    { pattern: /linux/i, os: 'linux' },
  ];

  const matchedPlatform = platformMatchers.find(({ pattern }) => pattern.test(userAgent));
  return matchedPlatform ? matchedPlatform.os : null;
}

export function getKeyPressesFromMouseClick(e: React.MouseEvent) {
  const os = getOS();
  return {
    ctrl: os === 'macos' ? e.metaKey : e.ctrlKey,
    shift: e.shiftKey,
  };
}

export function getCtrlKeyName() {
  const os = getOS();
  return os === 'macos' ? 'Cmd' : 'Ctrl';
}
