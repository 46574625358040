import styled from '@emotion/styled';
import { useOverviewContext } from '@resistapp/client/contexts/use-overview-context/use-overview-context';
import { resistanceLevelMetadata } from '@resistapp/client/data-utils/resistance-level';
import { References } from '@resistapp/client/hooks/use-science-reference';
import { InfoTooltipIcon } from '../icons/info-tooltip-icon';
import { InfoModalLink } from '../modals/info-modal-link';
import { IndexInfo } from '../modals/resistance-index/index-info';
import { MetricTitle } from '../shared/metric-title';
import { theme } from '../shared/theme';
import { InfoIconProps } from './info-icons-general';

export function ResistanceIndexInfoIcon(props: InfoIconProps) {
  const style = props.style || {};
  const placement = props.placement || 'bottom';
  const name =
    props.selectedGeneGroup?.length === 1
      ? `${props.selectedGeneGroup[0]} Resistance Gene`
      : 'Antibiotic Resistance Gene';
  const target = props.selectedGeneGroup?.length === 1 ? `${props.selectedGeneGroup[0]} Resistance Gene` : 'ARG';

  return (
    <InfoTooltipIcon
      header={props.header}
      placement={placement}
      style={{
        ...style,
        padding: 0,
        paddingRight: theme.spacing[2],
      }}
      isForceOpen={props.isForceOpen}
      highlight={props.isForceOpen}
      footer={props.tutorialFooter}
    >
      <ResistanceIndexTooltipContent tutorialActive={!!props.header} name={name} target={target} />
    </InfoTooltipIcon>
  );
}

interface ContentProps {
  name: string;
  target: string;
  tutorialActive?: boolean;
}

function ResistanceIndexTooltipContent(props: ContentProps) {
  const boldOrNormal = props.tutorialActive ? 'normal' : 'bold';
  const { isOneHealthProject, selectedAntibiotic } = useOverviewContext();

  return (
    <div>
      <div>
        <span style={{ fontWeight: boldOrNormal }}>
          <i>
            <MetricTitle noColor /> {!isOneHealthProject && !selectedAntibiotic && `(ARGI)`}
          </i>
        </span>{' '}
        summarises the overall environmental {props.target} load on a three-level scale:
      </div>
      <div style={{ marginTop: 12 }}>
        <div>
          <div style={{ paddingBottom: 10 }}>
            <b>
              <Level
                style={{
                  background: resistanceLevelMetadata.high.color,
                  color: resistanceLevelMetadata.high.textColor,
                }}
              >
                {resistanceLevelMetadata.high.title}
              </Level>
            </b>{' '}
            level signals extreme {props.target} load, comparable to the level of resistance in human gut during
            antibiotic treatment.
          </div>
          <div style={{ paddingBottom: 10 }}>
            <b>
              <Level
                style={{
                  color: resistanceLevelMetadata.moderate.textColor,
                  background: resistanceLevelMetadata.moderate.color,
                }}
              >
                {resistanceLevelMetadata.moderate.title}
              </Level>
            </b>{' '}
            level indicates elevated levels that warrant attention.
          </div>
          <div>
            <b>
              <Level
                style={{
                  background: resistanceLevelMetadata.low.color,
                  color: resistanceLevelMetadata.low.textColor,
                }}
              >
                {resistanceLevelMetadata.low.shortTitle}
              </Level>
            </b>{' '}
            level is expected in a typical anthropogenic (ie. human impacted) environments.
          </div>
        </div>
      </div>
      <References style={{ marginTop: 25, marginLeft: -10, marginBottom: props.tutorialActive ? -5 : 15 }}>
        <ReadMoreLink {...props} />
      </References>
    </div>
  );
}

function ReadMoreLink(props: ContentProps) {
  const { name } = props;
  return (
    <InfoModalLink linkText="Read more…" header={`${name} Index and Levels`}>
      <IndexInfo name={name} target="Antibiotic Resistance Gene" area="area or location" />
    </InfoModalLink>
  );
}

const Level = styled.span`
  padding: 2px 4px;
  border-radius: 4px;
`;
