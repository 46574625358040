import styled from '@emotion/styled';
import { ReactNode } from 'react';
import { MaxWidth, sidePadding } from '../shared/layout';
import { oldTheme } from '../shared/old-styles';
import { theme } from '../shared/theme';

interface TitleProps {
  title: ReactNode;
  $extraWide?: boolean;
  controls?: ReactNode | ReactNode[];
  targets?: string;
}

export const titleBarMinHeight = 60;

export function TitleBar(props: TitleProps) {
  return (
    <div style={{ backgroundColor: theme.colors.neutral50, padding: '20px 20px 10px 20px' }} id="title-bar">
      <MaxWidth $extraWide={props.$extraWide} style={{ marginLeft: 0 }}>
        <PaddedFlex>
          <Title>{props.title}</Title>
          <FlexRowDiv>{props.controls}</FlexRowDiv>
        </PaddedFlex>
      </MaxWidth>
    </div>
  );
}

const FlexRowDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
  gap: ${oldTheme.spacing.s};
`;

const PaddedFlex = styled.div`
  ${sidePadding}
  display: flex;
  flex: 1;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const Title = styled.div`
  font-family: ${oldTheme.fontFamilyDMSans};
  font-style: normal;
  font-weight: 500;
  line-height: 38px;
  letter-spacing: -0.56px;
  color: ${theme.colors.neutral800};
  font-size: 28px;
`;
