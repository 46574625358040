import { assertSingleProject, extractSampleNumber } from '@resistapp/common/sample-uid-utils';
import { FullSamplesByUID } from '@resistapp/common/types';
import { countDetectedGenesPerSampleUID, countDetectedGenesPerTarget } from '@resistapp/common/utils';
import { csvFormat } from 'd3-dsv';
import { Dictionary, mapValues } from 'lodash';

// const targetColumn = 'Target';
const sampleNumberColumn = 'Sample number';
const numDetectedColumn = 'Total number of detected genes';
const descriptionColumn = 'Description';

export function getNumberOfDetectedGenesBySampleNumCsv(samplesByUID: FullSamplesByUID): string {
  assertSingleProject(samplesByUID);
  const counts = countDetectedGenesPerSampleUID(samplesByUID);
  const nameByUID = mapValues(samplesByUID, samples => samples[0].environment.name);
  const rows = Object.keys(counts).map(sampleUID => {
    const perTargetCounts = countDetectedGenesPerTarget(samplesByUID[sampleUID]);
    const sampleNum = extractSampleNumber(sampleUID);
    return Object.keys(perTargetCounts).reduce<Dictionary<string | number>>(
      (acc, target) => {
        acc[target] = perTargetCounts[target];
        return acc;
      },
      {
        [sampleNumberColumn]: `${sampleNum}`,
        [descriptionColumn]: nameByUID[sampleUID],
        [numDetectedColumn]: counts[sampleUID],
      },
    );
  });
  return csvFormat(rows);
}
