import { Pooling, PoolingMode, PoolingType } from '@resistapp/common/api-types';
import { getEnvSubtypePoolingKey, isBeforeSample } from '@resistapp/common/environment-types';
import { AdminArea, AdminLevelKey, BioRep, PooledEnvHack, Sample } from '@resistapp/common/types';
import { chain, Dictionary, get, isNil, keys, pickBy } from 'lodash';

/**
 * Pooling samples environments means rewriting their environments with virtual environments that are identical for the samples that are pooled together.
 *
 * Create new raw samples (with untouched abundances) that share an environment for all input samples that with the same pooling criteria.
 * If pooling is not specified, no pooling is performed, and samples are only mapped to new raw samples (with untouched abundances).
 */
export function poolSampleEnvironments(
  samples: Sample[],
  pooling: Pooling | undefined,
  _countryNameByAlpha3IfNeeded: Dictionary<string> | undefined,
): Sample[] {
  let mutatingSampleNumberCounter = 1;
  const hasMultipleCountries =
    chain(samples)
      .map(sample => sample.country)
      .uniq()
      .value().length > 1;
  const countryNameByAlpha3IfNeeded = hasMultipleCountries ? _countryNameByAlpha3IfNeeded : undefined;

  const pooledSamples = chain(samples)
    .filter(sample => {
      const hasKeys = hasPoolingKeys(sample, pooling, !!countryNameByAlpha3IfNeeded);
      if (pooling?.mode === PoolingMode.THROW_MISSING && !hasKeys) {
        throw new Error(`Sample ${sample.id} is missing ${pooling.type} (${get(pooling, 'level', '')}) pooling key`);
      }
      return hasKeys;
    })
    .groupBy(sample => getPoolingKey(sample, pooling, countryNameByAlpha3IfNeeded))
    .mapValues((samplesToBePooled, key, collection) => {
      const sampleNum = mutatingSampleNumberCounter++;

      // HACK! Use bogus negative ids to satisfy type system just in case some dumbass goes and upserts these in db
      // NOTE: env id should be unique accross sites but identical for site samples (though they have different subtypes for building before/after datums fields)
      const envId = -1 - keys(collection).findIndex(k => k === key);
      const sampleId = -sampleNum;
      return samplesToBePooled.map(sample =>
        createPooledSample(
          sample,
          sampleNum,
          BioRep.A,
          sampleId,
          envId,
          samplesToBePooled,
          pooling,
          countryNameByAlpha3IfNeeded,
        ),
      );
    })
    .values()
    .flatten()
    .value();
  return pooledSamples;
}

function createPooledSample(
  sample: Sample,
  sampleNum: number,
  bioRep: BioRep,
  sampleId: number,
  environmentId: number,
  samplesToBePooled: Sample[],
  pooling: Pooling | undefined,
  countryNameByAlpha3IfNeeded: Dictionary<string> | undefined,
): Sample {
  const keepLatLon = !pooling || pooling.type === PoolingType.SITE;
  const keepCity = keepLatLon || pooling.type === PoolingType.CITY;
  const keepRegion = keepCity || pooling.type === PoolingType.REGION;
  const keepCountry =
    keepRegion || pooling.type === PoolingType.COUNTRY || pooling.type === PoolingType.SITE_AND_ADMIN_LEVEL;

  const numUniqSubtype = chain(samplesToBePooled)
    .map(s => s.environment.subtype)
    .uniq()
    .value().length;
  const isSitePooling = pooling?.type === PoolingType.SITE && numUniqSubtype === 2;
  const allowedUniqueSubtypes = isSitePooling ? 2 : 1;
  const keepSubtype = numUniqSubtype <= allowedUniqueSubtypes;

  const adminLevels = keepLatLon
    ? sample.adminLevels
    : pooling.type === PoolingType.SITE_AND_ADMIN_LEVEL
      ? pickBy(sample.adminLevels, levelDatum => levelDatum.level >= pooling.level)
      : undefined;

  const inferredLat = chain(samplesToBePooled)
    .map(s => s.inferredLat)
    .mean()
    .value();
  const inferredLon = chain(samplesToBePooled)
    .map(s => s.inferredLon)
    .mean()
    .value();

  const environmentName = isSitePooling
    ? samplesToBePooled.find(isBeforeSample)?.environment.name || ''
    : getPoolName(sample, pooling, countryNameByAlpha3IfNeeded);

  if (!environmentName) {
    throw new Error(`Unexpectedly falsy env name for ${sample.id}`);
  }

  const pooledSample: Sample = {
    id: sampleId,
    projectId: sample.projectId,
    number: sampleNum,
    bioRep,
    environment: {
      name: environmentName,
      type: samplesToBePooled[0].environment.type,
      subtype: keepSubtype ? sample.environment.subtype : undefined,
      id: environmentId,
    },
    environmentId,
    time: sample.time,
    volume: sample.volume,
    dnaConcentration: sample.dnaConcentration,
    dnaQuality: sample.dnaQuality,
    lat: keepLatLon ? sample.lat : undefined,
    lon: keepLatLon ? sample.lon : undefined,
    inferredLat,
    inferredLon,
    adminLevels,
    city: keepCity ? sample.city : undefined,
    region: keepRegion ? sample.region : undefined,
    country: keepCountry ? sample.country : undefined,
    abundances: sample.abundances,
  };

  // HACK, see PooledEnvHack
  (pooledSample.environment as PooledEnvHack).originalEnvironmentNames = chain(samplesToBePooled)
    .map(s => (sample.environment as PooledEnvHack).originalEnvironmentNames || [s.environment.name])
    .flatten()
    .uniq()
    .value();

  return pooledSample;
}

function hasPoolingKeys(sample: Sample, pooling: Pooling | undefined, countryNeeded: boolean) {
  const countryOk = !countryNeeded || !!sample.country;
  if (pooling?.type === PoolingType.COUNTRY) {
    return countryOk;
  } else if (pooling?.type === PoolingType.REGION) {
    return countryOk && !!sample.region;
  } else if (pooling?.type === PoolingType.CITY) {
    return countryOk && !!sample.city;
  } else if (pooling?.type === PoolingType.SITE_AND_ADMIN_LEVEL) {
    return !!sample.adminLevels?.[`${pooling.level}` as AdminLevelKey];
  } else if (pooling?.type === PoolingType.SITE) {
    return !isNil(sample.lat) && !isNil(sample.lon);
  } else if (pooling?.type === PoolingType.ENVIRONMENT_TYPE || !pooling) {
    return true;
  }
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  throw Error(`Unsupported pooling type: ${pooling.type}`);
}

export function getPoolingKey(
  sample: Sample,
  pooling: Pooling | undefined,
  countryNameByAlpha3IfNeeded: Dictionary<string> | undefined,
): string {
  switch (pooling?.type) {
    case PoolingType.COUNTRY:
    case PoolingType.REGION:
    case PoolingType.CITY:
      return `${sample.environment.type} - ${getPoolName(sample, pooling, countryNameByAlpha3IfNeeded)}`;
    case PoolingType.ENVIRONMENT_TYPE:
      return sample.environment.type;
    case PoolingType.SITE_AND_ADMIN_LEVEL: {
      const adminArea = sample.adminLevels?.[`${pooling.level}` as AdminLevelKey];

      if (!adminArea) {
        throw new Error(`Sample ${sample.id} missing admin level ${pooling.level}`);
      }
      return getAdminAreaKey(adminArea);
    }
    case PoolingType.SITE:
      if (isNil(sample.lat) || isNil(sample.lon)) {
        throw new Error(`Sample ${sample.id} missing lat/lon`);
      }
      return getEnvSubtypePoolingKey(sample);
    case undefined: {
      const environment = sample.environment;
      if (!environment.id) {
        throw new Error(`Sample ${JSON.stringify(sample)} missing environment id in getPoolingKey`);
      }
      return `${environment.id}`;
    }
    default:
      throw Error(`Unexpected pooling type ${JSON.stringify(pooling)}`);
  }
}

export function getAdminAreaKey(area: AdminArea) {
  const prefix = `${area.level}---${area.name}---`;
  return area.boundaries ? prefix + JSON.stringify(area.boundaries) : prefix;
}

function getPoolName(
  sample: Sample,
  pooling: Pooling | undefined,
  countryNameByAlpha3IfNeeded: Dictionary<string> | undefined,
): string {
  const countryStr =
    countryNameByAlpha3IfNeeded && sample.country
      ? get(countryNameByAlpha3IfNeeded, sample.country, sample.country)
      : 'Unknown';
  switch (pooling?.type) {
    case PoolingType.COUNTRY:
      return countryStr;
    case PoolingType.REGION:
      return countryNameByAlpha3IfNeeded ? `${countryStr} - ${sample.region}` : (sample.region as string);
    case PoolingType.CITY:
      return countryNameByAlpha3IfNeeded ? `${countryStr} - ${sample.city}` : `${sample.city}`;
    case PoolingType.ENVIRONMENT_TYPE:
      return sample.environment.type;
    case PoolingType.SITE_AND_ADMIN_LEVEL: {
      const levelName = sample.adminLevels?.[`${pooling.level}` as AdminLevelKey]?.name;
      if (!levelName) {
        // Could be due to the same commented in maxAffordableLevelByCountry ?
        throw new Error(`Sample ${sample.id} missing admin level ${pooling.level}`);
      }
      return levelName;
    }
    case PoolingType.SITE:
      return sample.environment.name;
    case undefined:
      return `${sample.environment.name} (${sample.projectId})`;
  }
}
