import { Popover, PopoverBody, PopoverContent, PopoverFooter, PopoverHeader, PopoverTrigger } from '@chakra-ui/react';
import { Tutorial } from '@resistapp/client/hooks/use-tutorial';
import { ReactNode, useEffect, useRef } from 'react';
import { MarkerPointerArrow } from '../arrows/arrow';
import { theme } from '../shared/theme';
import { TutorialFooter } from './tutorial-footer';

interface Props {
  currentTutorialStep: number;
  thisTutorialStep: number;
  tutorial: Tutorial;
  setTutorialChanged: () => void;
  nextStep: () => void;
  prevStep: () => void;
  stopTutorial: () => void;
  children: ReactNode[];
  isMobile: boolean;
}

export function TutorialContainer(props: Props) {
  const {
    currentTutorialStep,
    thisTutorialStep,
    tutorial,
    setTutorialChanged,
    nextStep,
    prevStep,
    stopTutorial,
    isMobile,
    children,
  } = props;

  const popoverRef = useRef<HTMLDivElement>(null);

  // We scroll to the currently active tutorial step
  useEffect(() => {
    if (currentTutorialStep === thisTutorialStep) {
      tutorial.scrollToStep(popoverRef);
    }
  }, [currentTutorialStep, thisTutorialStep, tutorial]);

  return (
    <Popover trigger="hover" placement={'bottom'} isOpen={currentTutorialStep === thisTutorialStep}>
      <PopoverTrigger>{children[0]}</PopoverTrigger>
      <PopoverContent
        ref={popoverRef}
        // This marginLeft fixes mobile tutorial position. Otherwise the popover content is outside viewport to the right
        style={{ width: '330px', marginLeft: isMobile ? '-200px' : '' }}
        rootProps={{
          style: { padding: 10, margin: 10, marginTop: 51, ...theme.shadows.strong, border: theme.colors.maroon },
        }}
      >
        <MarkerPointerArrow direction="up" style={{ top: '-10px', zIndex: '-1', right: '50px' }} />
        <PopoverHeader>
          <PopoverHeader style={{ borderBottom: 0, paddingBottom: 0 }}>{tutorial.getTutorialPhaseText()}</PopoverHeader>
        </PopoverHeader>
        <PopoverBody>{children[1]}</PopoverBody>
        <PopoverFooter style={{ borderTop: 0 }}>
          <TutorialFooter
            nextStep={() => {
              setTutorialChanged();
              nextStep();
            }}
            prevStep={() => {
              setTutorialChanged();
              prevStep();
            }}
            stopTutorial={() => {
              setTutorialChanged();
              stopTutorial();
            }}
            currentStep={currentTutorialStep}
            stepCount={tutorial.stepCount}
          />
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
}
