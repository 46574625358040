import { oldTheme } from '@resistapp/client/components/shared/old-styles';

interface Props {
  tooltipData: {
    color: string;
    median: number | undefined;
    label: string;
  };
  demoProjectId: number | undefined;
}

export function CountryTooltipContent({ tooltipData, demoProjectId }: Props) {
  const [country, target] = tooltipData.label.split(' — ');
  return (
    <>
      <div style={{ color: oldTheme.darkGray }}>
        <strong>{country}</strong>&nbsp;<strong style={{ color: tooltipData.color }}>— {target}</strong>
      </div>
      {demoProjectId ? (
        <div style={{ marginTop: oldTheme.spacing.xxs, fontSize: oldTheme.fontSize.s }}>
          <strong>Click for an example report!</strong>
        </div>
      ) : (
        <div style={{ marginTop: oldTheme.spacing.xxs, fontSize: oldTheme.fontSize.s }}>
          Median relative abundance: {formatRelAbundance(tooltipData.median)}
        </div>
      )}
    </>
  );
}

function formatRelAbundance(x: number | undefined | null, f?: number) {
  if (!x) {
    return '-';
  }
  if (x > 0.01) {
    return x.toFixed(3);
  }
  const [n, e] = x.toExponential(f).split('e');
  return `${Number.parseFloat(n).toFixed(2)}e${e}`;
}
