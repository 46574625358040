import { References } from '@resistapp/client/hooks/use-science-reference';
import { InfoTooltipIcon } from '../icons/info-tooltip-icon';
import { MetricTitle } from '../shared/metric-title';
import { theme } from '../shared/theme';
import { InfoIconProps } from './info-icons-general';

export function ReductionInfoIcon(props: InfoIconProps) {
  const style = props.style || {};
  const placement = props.placement || 'bottom';

  return (
    <InfoTooltipIcon
      header={props.header}
      placement={placement}
      style={{
        ...style,
        padding: 0,
        paddingRight: theme.oldSpacing.xs,
      }}
      isForceOpen={props.isForceOpen}
      highlight={props.isForceOpen}
      footer={props.tutorialFooter}
    >
      <ReductionTooltipContent tutorialActive={!!props.header} />
    </InfoTooltipIcon>
  );
}

interface ContentProps {
  tutorialActive?: boolean;
}

export function ReductionTooltipContent(props: ContentProps) {
  const boldOrNormal = props.tutorialActive ? 'normal' : 'bold';

  return (
    <div>
      <div>
        <span style={{ fontWeight: boldOrNormal }}>
          <MetricTitle noBold noColor />
        </span>{' '}
        shows how many times the relative&nbsp;abundance of effluent genes have doubled (or halved) compared to the
        influent samples.
      </div>
      <div style={{ marginTop: 12 }}>
        Genewise fold change is calculated using the ΔΔCt method: First, the ΔCt is determined by subtracting the Ct
        value of 16S rRNA reference gene from the Ct value of the target gene in both the influent and effluent samples.
        Then, ΔΔCt is calculated by subtracting the ΔCt of the influent sample from the ΔCt of the effluent sample.
      </div>
      <div style={{ marginTop: 12 }}>
        To obtain an overall fold change across all measured genes and biological replicates, the geometric mean of the
        individual fold change (ratios) is calculated.
      </div>
      <div style={{ marginTop: 12 }}>
        <div style={{ marginTop: 12 }}>
          Due to the exponental nature of the phenomenon, fold changes are presented directly as ΔΔCt (exponents of 2).
          The fold change ratio can be calculated as 2<sup>−ΔΔCt</sup>. A 2-fold change of 0 means no change in gene
          abundance, a 2-fold change of -1 (ratio 0.5, or -50% change) indicates that relative abundances have been
          halved, a 2-fold change of 1 (ratio 2.0, or +100% change) means they have doubled, and so on. The range has no
          upper or lower limit, but values are generally between -5 and 5.
        </div>
      </div>
      <References style={{ marginTop: 25, marginLeft: -10, marginBottom: props.tutorialActive ? -5 : 15 }} />
    </div>
  );
}
