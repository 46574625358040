import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { CSSProperties } from 'react';
import { TrashIcon } from './trash-icon';

interface DeleteIconProps {
  name?: string;
  message?: string;
  triggerDelete: () => Promise<any>;
  color?: string[];
  style?: CSSProperties;
}
export function DeleteIcon({ name, triggerDelete, style, message }: DeleteIconProps) {
  const confirmText = message || `${name || 'this item'} will be permanently deleted. Are you sure?`;
  return (
    <TrashIcon
      style={style}
      icon={faTrashAlt}
      onClick={() => {
        if (confirm(confirmText)) {
          void triggerDelete();
          setTimeout(() => {
            window.location.reload();
          }, 500);
        }
      }}
    />
  );
}
