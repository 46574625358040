import styled from '@emotion/styled';
import { faChevronDown, faChevronLeft, faChevronRight, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
  direction: 'up' | 'right' | 'down' | 'left';
  style?: React.CSSProperties;
}

export function ArrowIcon(props: Props) {
  return (
    <StyledIcon
      icon={
        props.direction === 'up'
          ? faChevronUp
          : props.direction === 'right'
            ? faChevronRight
            : props.direction === 'down'
              ? faChevronDown
              : faChevronLeft
      }
      style={{ ...props.style }}
    />
  );
}

export const StyledIcon = styled(FontAwesomeIcon)`
  width: 100%;
  font-size: 13px;
  height: auto;
  padding-top: 2px;
  padding-bottom: 2px;
`;
