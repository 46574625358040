import { Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { ResistanceLevel } from '@resistapp/common/statistics/resistance-index';
import { MetricMode } from '@resistapp/common/types';
import { DownRightIcon, RightIcon, UpRightIcon } from '../icons/diaconal-arrow-icons';
import { OverviewMetricLabel, getChangeIndicatorColor } from '../shared/overview-metric-label';
import { theme } from '../shared/theme';

interface ResistanceBoxesProps {
  firstLevel: { level: ResistanceLevel | null | undefined; indexNumber: number | null; label?: string };
  secondLevel?: { level: ResistanceLevel | null | undefined; indexNumber: number | null; label?: string };
  changeInIndex?: number;
  metricMode: MetricMode;
  showReductionMetric: boolean;
  showRawLabel?: boolean;
}

export function ResistanceBoxes({
  firstLevel,
  secondLevel,
  changeInIndex,
  metricMode,
  showReductionMetric,
  showRawLabel,
}: ResistanceBoxesProps) {
  const showWhiteInfluentLabel = metricMode === MetricMode.RISK && secondLevel !== undefined;
  const showWhiteEffluentLabel = metricMode === MetricMode.ARGI;
  const showRiskArrow = metricMode === MetricMode.RISK && secondLevel !== undefined && changeInIndex !== undefined;
  const showArgiArrow = metricMode === MetricMode.ARGI && secondLevel !== undefined && changeInIndex !== undefined;

  return (
    <Flex gap={theme.spacing[4]}>
      {firstLevel.indexNumber !== null && (metricMode !== MetricMode.REDUCTION || showReductionMetric) && (
        <Flex flexDirection="column" width="100px">
          <OverviewMetricLabel
            id="influentLevelContainer"
            level={firstLevel.level ?? null}
            metricMode={metricMode}
            metric={firstLevel.indexNumber}
            style={{
              backgroundColor: showWhiteInfluentLabel ? 'white' : undefined,
              color: showWhiteInfluentLabel ? theme.colors.neutral500 : undefined,
              position: 'relative',
            }}
          >
            {showRiskArrow && (
              <ArrowContainer right>
                {changeInIndex < 0 ? (
                  <DownRightIcon style={{ color: getChangeIndicatorColor(-1) + 'A0' }} />
                ) : changeInIndex > 0 ? (
                  <UpRightIcon style={{ color: getChangeIndicatorColor(1) + 'A0' }} />
                ) : (
                  <RightIcon style={{ color: getChangeIndicatorColor(0) + 'A0' }} />
                )}
              </ArrowContainer>
            )}
          </OverviewMetricLabel>
          {(firstLevel.label || showRawLabel) && (
            <FluentsTextContainer>{firstLevel.label || 'Raw'}</FluentsTextContainer>
          )}
        </Flex>
      )}
      {secondLevel && secondLevel.indexNumber !== null && metricMode !== MetricMode.REDUCTION && (
        <Flex flexDirection="column" width="100px">
          <OverviewMetricLabel
            id="effluentLevelContainer"
            level={secondLevel.level ?? null}
            metricMode={metricMode}
            metric={secondLevel.indexNumber}
            style={{
              backgroundColor: showWhiteEffluentLabel ? 'white' : undefined,
              color: showWhiteEffluentLabel ? theme.colors.neutral500 : undefined,
            }}
          >
            {showArgiArrow && (
              <ArrowContainer>
                {changeInIndex < 0 ? (
                  <DownRightIcon style={{ color: getChangeIndicatorColor(-1) + 'A0' }} />
                ) : changeInIndex > 0 ? (
                  <UpRightIcon style={{ color: getChangeIndicatorColor(1) + 'A0' }} />
                ) : (
                  <RightIcon style={{ color: getChangeIndicatorColor(0) + 'A0' }} />
                )}
              </ArrowContainer>
            )}
          </OverviewMetricLabel>
          {secondLevel.label && <FluentsTextContainer>{secondLevel.label}</FluentsTextContainer>}
        </Flex>
      )}
    </Flex>
  );
}

const FluentsTextContainer = styled.div`
  color: ${theme.colors.neutral500};
  text-align: center;
  font-size: ${theme.fontSize.extraSmall};
  font-weight: ${theme.fontWeight.heavy};
`;

const ArrowContainer = styled.div<{ right?: boolean }>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  ${props => (props.right ? 'right: 10px;' : 'left: 8px;')}
  display: flex;
  justify-content: center;
  align-items: center;
  width: 23px;
  height: 23px;
`;
