import { OverviewProcessDatum } from '@resistapp/client/data-utils/plot-data/build-overview-line-data';
import {
  GeneAndCopyNumber,
  targetAbundancesByAssay,
} from '@resistapp/client/data-utils/plot-data/process-overview-line-datum';
import { MinorTarget } from '@resistapp/common/assays-temp-96-gene-minor-targets';
import { getLog2FoldChange } from '@resistapp/common/statistics/fold-change';
import { chain } from 'lodash';

export const detailGraphHeight = 320;

export function getFriendlyGenesAndReductionNumbers(
  genesAndCopyNumbers: GeneAndCopyNumber[],
  selectedSiteDatum: OverviewProcessDatum,
  minorTarget: MinorTarget,
) {
  const influentValues = chain(targetAbundancesByAssay(selectedSiteDatum.abundances, minorTarget, true))
    .values()
    .flatten()
    .value();
  const effluentValues = chain(targetAbundancesByAssay(selectedSiteDatum.afterAbundances || [], minorTarget, true))
    .values()
    .flatten()
    .value();

  return genesAndCopyNumbers.map(geneAndNumber => {
    const reductionNumber = getLog2FoldChange(influentValues, effluentValues, geneAndNumber.assay);

    return {
      ...geneAndNumber,
      gene: friendlyGeneName(geneAndNumber.gene),
      reduction: reductionNumber,
    };
  });
}

export function getFriendlyGenesAndCopyNumbers(genesAndCopyNumbers: GeneAndCopyNumber[]) {
  return genesAndCopyNumbers.map(geneAndCopyNumber => ({
    ...geneAndCopyNumber,
    gene: friendlyGeneName(geneAndCopyNumber.gene),
  }));
}

export function friendlyGeneName(geneName: string): string {
  const replaceables: Record<string, string> = {
    baca: 'Bacitracin resistance',
    crass64: 'CrAssphage',
    mera: 'Mercury resistance',
    mcr1: 'Colistin resistance',
    'e.coli-uida_2': 'E.coli',
  };

  return replaceables[geneName.toLowerCase()] || geneName;
}
