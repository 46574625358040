import { theme } from '@resistapp/client/components/shared/theme';
import { useSampleDataContext } from '@resistapp/client/contexts/sample-data-context';
import { useOverviewContext } from '@resistapp/client/contexts/use-overview-context/use-overview-context';
import { getMetricAndLevel, getMetricColor, metricRange } from '@resistapp/client/utils/metric-utils';
import { MetricMode } from '@resistapp/common/types';
import { LinearGradient } from '@visx/gradient';
import { scaleLinear } from '@visx/scale';
import { isNil } from 'lodash';
import { positioning } from '../chart-styles';
import { getGradientScale } from '../scales';
import { BaseChart } from './base-chart';

interface ReductionChartProps {
  width: number;
  height: number;
}

export function ReductionChart(props: ReductionChartProps) {
  const { width, height } = props;
  const { queryFilters } = useSampleDataContext();
  const graphHeight = height - positioning.margin.top - positioning.margin.bottom;
  const indexScale = getGradientScale(graphHeight, [
    metricRange[MetricMode.REDUCTION].min,
    metricRange[MetricMode.REDUCTION].max,
  ]);
  const labelScale = scaleLinear({
    domain: [metricRange[MetricMode.REDUCTION].optionalMin, metricRange[MetricMode.REDUCTION].optionalMax],
    range: [graphHeight, 0],
  });
  const {
    trenchartTooltip: { TooltipComponentForReduction },
  } = useOverviewContext();

  return (
    <BaseChart
      width={width}
      height={height}
      indexScale={indexScale}
      labelScale={labelScale}
      Legend={GradientLegend}
      getIndexValue={d => {
        const [metric] = getMetricAndLevel(
          d.abundances,
          d.afterAbundances,
          queryFilters.filters.selectedTargets,
          MetricMode.REDUCTION,
        );
        return isNil(metric) ? undefined : indexScale(metric);
      }}
      TooltipComponent={TooltipComponentForReduction}
    />
  );
}

interface GradientLegendProps {
  left: number;
  height: number;
  width: number;
}

function GradientLegend({ left, height, width }: GradientLegendProps) {
  const cornerRadius = theme.borders.radius.small; // You can adjust this value to change the roundness of the corners

  return (
    <svg width={width} height={height} style={{ position: 'absolute', left }}>
      <defs>
        <clipPath id="gradient-legend-clippath">
          <rect
            x={0}
            y={positioning.margin.top / 2}
            width={width}
            height={height - positioning.margin.top - positioning.margin.bottom}
            rx={cornerRadius}
          />
        </clipPath>
        <LinearGradient id="linear-gradient-for-legend" x1="0%" y1="100%" x2="0%" y2="0%">
          <stop offset="0%" stopColor={getMetricColor(metricRange[MetricMode.REDUCTION].min, MetricMode.REDUCTION)} />
          <stop offset="50%" stopColor={getMetricColor(0, MetricMode.REDUCTION)} />
          <stop offset="100%" stopColor={getMetricColor(metricRange[MetricMode.REDUCTION].max, MetricMode.REDUCTION)} />
        </LinearGradient>
      </defs>
      <rect
        x={0}
        y={positioning.margin.top / 2}
        width={width}
        height={height - positioning.margin.top - positioning.margin.bottom}
        fill="url(#linear-gradient-for-legend)"
        clipPath="url(#gradient-legend-clippath)"
      />
    </svg>
  );
}
