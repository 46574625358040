import { antibioticTargets, isAntibiotic, Target } from '@resistapp/common/assays';
import { getAbundanceLog10Stats } from '@resistapp/common/statistics/abundance-stats';
import { FullAbundance, StrippedFullAbundance } from '@resistapp/common/types';
import { isNil } from 'lodash';
import { LOD } from '../utils';

export type PartialAbundance = Pick<FullAbundance, 'assay' | 'gene' | 'absolute' | 'relative'>;
export type WithAbundances = {
  abundances: PartialAbundance[];
  afterAbundances?: PartialAbundance[];
};

export function getResistanceIndexData(
  abundances: StrippedFullAbundance[],
  antibiotic: Target[] | undefined, // undefined means all antibiotics
) {
  if (antibiotic && !antibiotic.every(isAntibiotic)) {
    throw Error('Only antibiotics supported in ARGI calculation');
  }
  const targets = antibiotic || antibioticTargets;
  const data = getAbundanceLog10Stats(abundances, {
    scope: 'analysed',
    mode: 'relative',
    targets,
  });

  const resistanceIndex = abundances.length ? calcResistanceGeneIndex(data.mean ?? Math.log10(LOD)) : null;
  return { resistanceIndex, data };
}

export function getResistanceGeneIndex(
  abundances: StrippedFullAbundance[],
  antibiotic: Target[] | undefined, // undefined means all antibiotics
) {
  if (antibiotic && !antibiotic.every(isAntibiotic)) {
    // This can happen upon early page load in Safari before the filters are properly initialized
    return null;
  }
  return getResistanceIndexData(abundances, antibiotic).resistanceIndex;
}

export function calcResistanceGeneIndex(relativeAbundanceExponent: number) {
  return Math.min(5, Math.max(0, 5 + relativeAbundanceExponent));
}

export enum ResistanceLevel {
  low = 'low',
  moderate = 'moderate',
  high = 'high',
}

export function getResistanceLevel(value: number): ResistanceLevel {
  return value >= 4 ? ResistanceLevel.high : value >= 2 ? ResistanceLevel.moderate : ResistanceLevel.low;
}

export function getResistanceGeneIndexAndLevel(
  abundances: StrippedFullAbundance[],
  antibiotic: Target[] | undefined, // undefined means all antibiotics
) {
  const resistanceIndex = getResistanceGeneIndex(abundances, antibiotic);
  const resistanceLevel = isNil(resistanceIndex) ? null : getResistanceLevel(resistanceIndex);
  return { resistanceIndex, resistanceLevel };
}
