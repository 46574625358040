import { Global } from '@emotion/react';
import { theme } from './components/shared/theme';

export function SetGlobalStyles() {
  return (
    <Global
      styles={{
        ':root': {
          fontFamily: theme.fontFamily.base,
          // These came recommended by the inter font-family
          fontFeatureSettings: "'liga' 1, 'calt' 1",
        },

        '@supports (font-variation-settings: normal)': {
          ':root': {
            fontFamily: theme.fontFamily.baseVariable,
          },
        },

        html: {
          margin: 0,
          padding: 0,
          height: '100%',
          fontSize: '15px',
          boxSizing: 'border-box',
          MozOsxFontSmoothing: 'grayscale',
          WebkitFontSmoothing: 'antialiased',
        },

        body: {
          margin: 0,
          padding: 0,
          height: '100%',
          fontSize: '15px',
          backgroundColor: theme.colors.neutral50,
        },

        '#root': {
          height: '100%',
        },

        /* https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/ */
        '*': {
          boxSizing: 'inherit',
        },
        '*:before': {
          boxSizing: 'inherit',
        },
        '*:after': {
          boxSizing: 'inherit',
        },

        /* Mapbox has it's own font-family baked in which we override */
        '.mapboxgl-map > div': {
          fontFamily: theme.fontFamily.base,
        },

        /* This styles the trendcharts left and bottom legend labels */
        '.trendchart-legend-label > g > svg > text': {
          fill: theme.colors.neutral800,
          textAlign: 'center',
          fontFamily: theme.fontFamily.base,
          fontSize: theme.fontSize.medium,
          fontWeight: `inherit !important`,
        },

        /* This styles the trendcharts left side legends text position */
        '.trendchart-legend-label-left > g > svg > text': {
          transform: 'translate(-4px)',
        },

        // The marker tooltip on the trenchards onhover effect shows properly with this
        '.hide-vx-background': {
          backgroundColor: 'transparent !important',
          boxShadow: 'none !important',
        },

        // These resistomap-select styles are for customizing "chakra-react-select" styles
        'div > .resistomap-select__control': {
          border: `1px solid ${theme.colors.neutral300}`,
        },
        // This is newer stripped version of the select. Which doesn't show the borders, but shows the selection as normal text
        '.stripped > .resistomap-select__control': {
          border: 'none',
          borderBottom: `1px dashed ${theme.colors.neutral300}`,
          boxShadow: 'none',
        },
        '.resistomap-select__control .resistomap-select__indicator': {
          backgroundColor: 'inherit',
        },
        '.resistomap-select__control .resistomap-select__indicator-separator': {
          border: 0,
        },
        '.resistomap-select__indicator > .chakra-icon > path': {
          fill: theme.colors.neutral400,
        },
      }}
    />
  );
}
