import React, { ReactNode, useContext, useState } from 'react';

interface TutorialContextType {
  tutorialHasChanged: number;
  setTutorialChanged: () => void;
}

const TutorialContext = React.createContext<TutorialContextType>({
  tutorialHasChanged: 0,
  setTutorialChanged: () => {},
});

export function useTutorialContext() {
  return useContext(TutorialContext);
}

export function TutorialContextProvider(props: { children: ReactNode }) {
  const [tutorialHasChanged, setTutorialHasChanged] = useState(0);

  const context = {
    tutorialHasChanged,
    setTutorialChanged: () => {
      setTutorialHasChanged(tutorialHasChanged + 1);
    },
  };

  return <TutorialContext.Provider value={context}>{props.children}</TutorialContext.Provider>;
}
