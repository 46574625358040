import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';
import { MaxWidth, ifDesktop } from './layout';

interface GridProps {
  children?: React.ReactNode;
  style?: React.CSSProperties;
}

export function MaxWidthGrid({ style, children }: GridProps) {
  return (
    <MaxWidth>
      <Grid style={style}>{children}</Grid>
    </MaxWidth>
  );
}

export function Grid({ style, children }: GridProps) {
  return <FlexRowWithWrap style={style}>{children}</FlexRowWithWrap>;
}

const FlexRowWithWrap = styled.div`
  flex-flow: row wrap;
  display: flex;
  width: 100%;
`;

interface GridCellProps {
  widthpercentage: number;
}

export function FullWidthCell(props: GridProps) {
  return (
    <GridCell widthpercentage={100} style={props.style}>
      {props.children}
    </GridCell>
  );
}

export function HalfWidthCell(props: GridProps) {
  return (
    <GridCell widthpercentage={50} style={props.style}>
      {props.children}
    </GridCell>
  );
}

// Use full width for every cell unless we have enough screen width
export const GridCell = styled.div<GridCellProps & { padding?: string }>`
  ${limitWidth(100)}
  ${props => (props.padding ? `padding: ${props.padding};` : '')}
  ${props => css`
    ${ifDesktop(css`
      flex-basis: ${props.widthpercentage}%;
      max-width: ${props.widthpercentage}%;
    `)}
  `}
`;

function limitWidth(widthPercentage: number) {
  // typescript doesn't understand styled.attrs
  return `
     flex-basis: ${widthPercentage}%;
     max-width: ${widthPercentage}%;
   `;
}
