import { Flex } from '@chakra-ui/react';
import { ResistomapButton, ResistomapButtonProps } from '../buttons/resistomap-button';

interface Props {
  currentStep: number;
  stepCount: number;
  nextStep: () => void;
  prevStep: () => void;
  stopTutorial: () => void;
}

export function TutorialFooter(props: Props) {
  return (
    <>
      <Flex justifyContent="space-between" alignItems="center" style={{ width: '300px' }}>
        <TutorialButton
          isDisabled={props.currentStep === 1}
          onClick={() => {
            props.prevStep();
          }}
          style={{ fontSize: '12px', width: 'max-content' }}
        >
          <span style={{ marginTop: 1 }}>Previous</span>
        </TutorialButton>
        <Flex
          justify="center"
          alignItems="center"
          alignContent="center"
          width="50px"
          style={{ fontSize: '10px', color: 'var(--chakra-colors-gray-700)' }}
        >
          {props.currentStep} / {props.stepCount}
        </Flex>
        <Flex justifyContent="flex-end" gap="6px">
          <TutorialButton
            isDisabled={props.currentStep === props.stepCount}
            onClick={() => {
              props.nextStep();
            }}
            style={{ fontSize: '12px' }}
          >
            <span style={{ marginTop: 1 }}>Next</span>
          </TutorialButton>
          <TutorialButton
            onClick={() => {
              props.stopTutorial();
            }}
          >
            <span style={{ marginTop: 1 }}>{props.currentStep === props.stepCount ? 'Finish' : 'Skip »'}</span>
          </TutorialButton>
        </Flex>
      </Flex>
    </>
  );
}

function TutorialButton(props: ResistomapButtonProps) {
  return (
    <ResistomapButton
      {...props}
      style={{
        fontSize: '12px',
        padding: 6,
        margin: 0,
        height: 25,
        minWidth: 50,
        borderRadius: 4,
        ...(props.style || {}),
      }}
    >
      {props.children}
    </ResistomapButton>
  );
}
