import { WithAbundances } from '@resistapp/common/statistics/resistance-index';
import { MetricMode } from '@resistapp/common/types';
import { getMainMetricLabel } from '../data-utils/plot-data/process-overview-line-datum';

export function truncateLongString(string: string) {
  return string.length > 10 ? `${string.slice(0, 10)}...` : string;
}

export function get_inRawOrEffluentSamples(overviewDatum: WithAbundances | undefined, metricMode: MetricMode) {
  if (!overviewDatum) {
    return '';
  }
  const mainMetricLabel = getMainMetricLabel(overviewDatum, metricMode);
  return mainMetricLabel ? ` in ${mainMetricLabel} samples` : '';
}
