import { CountryNameByAlpha3 } from '@resistapp/common/types';
import { filter, isNil, map, sortBy, uniq } from 'lodash';
import { OverviewDatum } from '../data-utils/plot-data/build-overview-line-data';

function itemCount(type: string, trendData?: OverviewDatum[][]) {
  const regions = trendData ? filter(uniq(map(trendData.flat(), type)), Boolean) : [];
  return regions.length;
}
function hasRegions(trendData?: OverviewDatum[][]) {
  return trendData?.every(item => item.every(subItem => subItem.region));
}
function hasCountries(trendData?: OverviewDatum[][]) {
  return trendData?.every(item => item.every(subItem => subItem.country));
}
export function showRegions(trendData?: OverviewDatum[][]) {
  return hasRegions(trendData) && itemCount('region', trendData) > 1 && hasCountries(trendData);
}
export function showCountries(trendData?: OverviewDatum[][]) {
  return hasCountries(trendData) && itemCount('country', trendData) > 1;
}
export function showExactCoordinates(trendData?: OverviewDatum[][]) {
  return trendData?.some(item => item.every(subItem => subItem.lat && subItem.lon));
}

export function getCorrectMapSourceFromAdminLevel(country: string, adminLevel: number): MapSourceWithLevel | undefined {
  const countrySources = mapSources.filter(
    source => country === source.countryAlphaCode3?.toUpperCase() && source.tileset.admLevel.includes(adminLevel),
  );
  const sortedSources = sortBy(countrySources, source => source.tileset.admLevel);
  return sortedSources.length ? { ...sortedSources[0], adminLevel } : undefined;
}

export function getMapSourceFromAdminLevel(
  adminLevel: number | undefined,
  country?: string | undefined,
): MapSourceWithLevel | undefined {
  // TODO should we pick only from the admin levels we have map sources for?
  if (country && !isNil(adminLevel)) {
    const mapSource = getCorrectMapSourceFromAdminLevel(country, adminLevel);
    return mapSource;
  } else if (!country && adminLevel === 2) {
    const mapSource = mapSources.find(s => s.tileset.admLevel.includes(2));
    return mapSource ? { ...mapSource, adminLevel: 2 } : undefined;
  } else {
    return undefined;
  }
}

export function getMapSourceOld(trendData?: OverviewDatum[][]): MapSourceWithLevel | undefined {
  if (showRegions(trendData)) {
    const countryCodes = trendData ? filter(uniq(map(trendData, item => item[0].country)), Boolean) : [];
    const source = mapSources.find(sourceLocal => countryCodes.includes(sourceLocal.countryAlphaCode3?.toUpperCase()));
    return source ? { ...source, adminLevel: 2 } : undefined;
  } else if (showCountries(trendData)) {
    const source = mapSources.find(sourceLocal => sourceLocal.tileset.admLevel.includes(2));
    return source ? { ...source, adminLevel: 2 } : undefined;
  } else {
    return undefined;
  }
}

export interface MapSourceWithLevel extends MapSource {
  adminLevel: number;
}
export function getMapSourceWithLevel(
  country: keyof CountryNameByAlpha3,
  adminLevel: number,
): MapSourceWithLevel | undefined {
  const mapSource = getCorrectMapSourceFromAdminLevel(country, adminLevel);
  return mapSource ? { ...mapSource, adminLevel } : undefined;
}
export interface MapSource {
  countryAlphaCode3: keyof CountryNameByAlpha3 | undefined;
  tileset: {
    url: string;
    sourceLayer: string;
    propertyName: string;
    admLevel: number[];
  };
  mappings: Record<string, string> | undefined;
}

export const mapSources: MapSource[] = [
  {
    countryAlphaCode3: undefined,
    tileset: {
      url: 'mapbox://mapbox.country-boundaries-v1',
      sourceLayer: 'country_boundaries',
      propertyName: 'iso_3166_1_alpha_3',
      admLevel: [2],
    },
    mappings: undefined,
  },
  {
    countryAlphaCode3: 'FIN',
    tileset: {
      url: 'mapbox://janneresistomap.banv5fry', // TODO dropp tileset osmfinadm6 when this is released
      sourceLayer: 'osmfinadm4_8',
      propertyName: 'name',
      admLevel: [4, 5, 6, 7, 8],
    },
    mappings: {
      Pohjanmaa: 'Österbotten',
    },
  },
  {
    countryAlphaCode3: 'FIN',
    tileset: {
      url: 'mapbox://janneresistomap.3b1shzl4', // TODO dropp tileset osmfinadm6 when this is released
      sourceLayer: 'osmfinadm9_12',
      propertyName: 'name',
      admLevel: [9, 10, 11, 12],
    },
    mappings: undefined,
  },
  {
    countryAlphaCode3: 'GBR',
    tileset: {
      url: 'mapbox://janneresistomap.2tzoslcy',
      sourceLayer: 'osmgbradm3_9',
      propertyName: 'name',
      admLevel: [3, 4, 5, 6, 7, 8, 9],
    },
    mappings: undefined,
  },
  {
    countryAlphaCode3: 'IDN',
    tileset: {
      url: 'mapbox://janneresistomap.9nhlqi3q',
      sourceLayer: 'osmidnadm3_8',
      propertyName: 'name',
      admLevel: [3, 4, 5, 6, 7, 8],
    },
    mappings: undefined,
  },
  {
    countryAlphaCode3: 'IDN',
    tileset: {
      url: 'mapbox://janneresistomap.6gpkgs1d',
      sourceLayer: 'osmidnadm9_10',
      propertyName: 'name',
      admLevel: [9, 10],
    },
    mappings: undefined,
  },
  {
    countryAlphaCode3: 'THA',
    tileset: {
      url: 'mapbox://janneresistomap.23pykpk8',
      sourceLayer: 'osmthaadm4_8',
      propertyName: 'name',
      admLevel: [4, 6, 7, 8],
    },
    mappings: undefined,
  },
  {
    countryAlphaCode3: 'NPL',
    tileset: {
      url: 'mapbox://janneresistomap.9hg4rxea',
      sourceLayer: 'osmnpladm4_8',
      propertyName: 'name',
      admLevel: [4, 6, 7],
    },
    mappings: undefined,
  },
  {
    countryAlphaCode3: 'NPL',
    tileset: {
      url: 'mapbox://janneresistomap.1gu07dds',
      sourceLayer: 'osmnpladm9_12',
      propertyName: 'name',
      admLevel: [9, 12],
    },
    mappings: undefined,
  },
];
