import { css, SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import { oldTheme } from './old-styles';
import { theme } from './theme';

const BREAKPOINT = 768;
export function ifDesktop(style: SerializedStyles) {
  return css`
    @media (min-width: ${BREAKPOINT}px) {
      ${style}
    }
  `;
}
export function isDesktop(windowWidth: number) {
  return windowWidth >= BREAKPOINT;
}

export const DEFAULT_MAX_WIDTH = 1400;
export const EXTRA_WIDE_WIDTH = 3600;

interface WidthProps {
  $extraWide?: boolean;
}
export const MaxWidth = styled.div<WidthProps>`
  max-width: ${props => (props.$extraWide ? EXTRA_WIDE_WIDTH : DEFAULT_MAX_WIDTH)}px;
  margin-left: auto;
  margin-right: auto;
`;

export const sidePadding = css`
  padding-left: ${oldTheme.spacing.s};
  padding-right: ${oldTheme.spacing.s};
  ${ifDesktop(css`
    padding-right: ${oldTheme.spacing.m};
    padding-left: ${oldTheme.spacing.m};
  `)}
`;

function getTotalSidePadding(windowWidth: number): number {
  return isDesktop(windowWidth) ? 2 * Number.parseInt(theme.spacing[6]) : 2 * Number.parseInt(theme.spacing[4]);
}

export function getPlotWidth(windowWidth: number, extraWide: boolean): number {
  const maxWidth = extraWide ? EXTRA_WIDE_WIDTH : DEFAULT_MAX_WIDTH;
  return Math.min(maxWidth, windowWidth) - getTotalSidePadding(windowWidth);
}

export const GridView = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-top: ${oldTheme.spacing.xl};
  ${sidePadding}
`;

export const FlexView = styled(GridView)`
  display: flex;
  flex-direction: column;
`;

export const CenteredView = styled(FlexView)`
  align-items: center;
`;

export const FormView = styled(CenteredView)`
  max-width: 375px;
  padding: ${oldTheme.spacing.s};
`;

export const DeadCenter = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
`;
