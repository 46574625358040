import styled from '@emotion/styled';
import { getMetricColor, metricRange } from '@resistapp/client/utils/metric-utils';
import { MetricMode } from '@resistapp/common/types';
import { theme } from '../../shared/theme';
import { BaseMapLegend } from './base-map-legend';

export function ReductionMapLegend({ timeLegend }: { timeLegend: React.ReactNode | string }) {
  return (
    <BaseMapLegend
      timeLegend={timeLegend}
      indicators={<ColorScale />}
      indexRange={metricRange[MetricMode.REDUCTION].mapLegendTicks.map(e => (
        // The marginLeft takes into account that some of the numbers have minuses in front of them and take up
        // space. Without the margin the scale is not inline with the colored bar over it.
        <div key={e} style={{ marginLeft: Number(e) < 0 ? '-6px' : '0px' }}>
          {e}
        </div>
      ))}
    />
  );
}

const ColorScale = styled.div`
  width: 100%;
  height: 20px;
  background: linear-gradient(
    to right,
    ${getMetricColor(metricRange[MetricMode.REDUCTION].min, MetricMode.REDUCTION)},
    ${getMetricColor(0, MetricMode.REDUCTION)},
    ${getMetricColor(metricRange[MetricMode.REDUCTION].max, MetricMode.REDUCTION)}
  );
  border-radius: ${theme.borders.radius.small};
`;
