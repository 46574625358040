import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react';
import { sentanceCase } from '@resistapp/common/friendly';
import { isNil } from 'lodash';
import React from 'react';
import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form';

interface ControlledSelectProps<TFieldValues extends FieldValues> {
  control: Control<TFieldValues>;
  name: FieldPath<TFieldValues>;
  children: React.ReactNode;
  label?: string | null;
}

export function ControlledInput<TFieldValues extends FieldValues>(props: ControlledSelectProps<TFieldValues>) {
  const { control, name, children, label } = props;

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, onBlur, value, name: name2, ref }, fieldState: { error } }) => (
        <FormControl isInvalid={!!error}>
          {isNil(label) ? label : <FormLabel>{label || sentanceCase(name)}</FormLabel>}
          {React.cloneElement(children as React.ReactElement, {
            name2,
            ref,
            onChange,
            onBlur,
            value,
          })}
          <FormErrorMessage>{error?.message}</FormErrorMessage>
        </FormControl>
      )}
    />
  );
}
