export enum BaseColors {
  red900 = '#520A27',
  red800 = '#942054',
  red700 = '#B22464',
  red600 = '#CE2C74',
  red500 = '#E15393',
  red400 = '#E989B4',
  red300 = '#F3AECD',
  red200 = '#FAD0E3', // This represents the lowest risk score color
  red100 = '#FCE7F0',
  red50 = '#FDF2F7',

  blue900 = '#2A4279',
  blue800 = '#3758A0',
  blue700 = '#3E66BB',
  blue600 = '#4772C9',
  blue500 = '#5685DB',
  blue400 = '#6B9CEF',
  blue300 = '#97BCF9',
  blue200 = '#C0D8FD',
  blue100 = '#DDEAFC',
  blue50 = '#EFF6FF',

  green900 = '#1C4532',
  green800 = '#22543D',
  green700 = '#276749',
  green600 = '#2F855A',
  green500 = '#38A169',
  green400 = '#48BB78',
  green300 = '#68D391',
  green200 = '#9AE6B4',
  green100 = '#C6F6D5',
  green50 = '#F0FFF4',

  neutral900 = '#0F172A',
  neutral800 = '#1E293B',
  neutral700 = '#334155',
  neutral600 = '#475569',
  neutral500 = '#64748B',
  neutral400 = '#94A3B8',
  neutral300 = '#CBD5E1',
  neutral200 = '#E2E8F0',
  neutral100 = '#F1F5F9',
  neutral50 = '#F8FAFC',
}
