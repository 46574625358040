import { Box } from '@chakra-ui/react';
import { useSampleDataContext } from '@resistapp/client/contexts/sample-data-context';
import { useOverviewContext } from '@resistapp/client/contexts/use-overview-context/use-overview-context';
import { getFriendlyInterval } from '@resistapp/common/date-utils';
import { friendlyMonth } from '@resistapp/common/friendly';
import { useState } from 'react';
import { IntegratedTextSelect } from '../selects/integrated-text-select';

interface Option {
  value: string;
  label: string;
  isDisabled?: boolean;
}

export function MonthSelector() {
  const { availableMonths, trendData, setMonth, selectedSiteDatum } = useOverviewContext();
  const { queryFilters } = useSampleDataContext();
  const selectedMonth = queryFilters.filters.interval.start;
  const [selectActive, setSelectActive] = useState(false);

  const siteTrendData = selectedSiteDatum
    ? trendData?.find(data => data[0].environment.id === selectedSiteDatum.environment.id)
    : undefined;
  const availableMonthsForSite = siteTrendData?.map(data => data.date);

  const latestDatumsForEachEnv = trendData?.map(data => data[data.length - 1]);
  const latestInterval = latestDatumsForEachEnv ? getFriendlyInterval(latestDatumsForEachEnv) : undefined;
  const selectableMonths = availableMonths.filter(month => friendlyMonth(month, 'postfixAlways') !== latestInterval);

  const options: Option[] = [
    { value: 'latest', label: latestInterval ? `${latestInterval} (latest)` : 'Latest sampling' },
    ...selectableMonths.map(month => ({
      value: month,
      label: friendlyMonth(month, 'postfixAlways'),
      isDisabled: selectedSiteDatum && !availableMonthsForSite?.includes(month),
    })),
  ];

  const value = options.find(option => option.value === selectedMonth.toISOString()) || options[0];

  function handleChange(newValue: Option | null) {
    if (newValue) {
      if (newValue.value === 'latest') {
        setMonth(null);
      } else {
        setMonth(new Date(newValue.value));
      }
    }
    setSelectActive(false);
  }

  return (
    <Box
      onClick={() => {
        !selectActive && setSelectActive(true);
      }}
    >
      <IntegratedTextSelect<Option>
        options={options}
        value={value}
        size="sm"
        menuIsOpen={selectActive}
        onChange={handleChange}
        onMenuClose={() => {
          setSelectActive(false);
        }}
      >
        <span style={{ wordWrap: 'normal', whiteSpace: 'nowrap' }}>{value.label}</span>
      </IntegratedTextSelect>
    </Box>
  );
}
