import Hotjar from '@hotjar/browser';
import { isProduction, isStaging } from '@resistapp/common/utils';
import { storedCookiesAccepted, storedTeamBrowser } from './components/shared/local-storage';

const productionHotjarSiteId = 4945986;
const stagingHotjarSiteId = 4946020;
const hotjarVersion = 6;
let hotjarInitialized = false;

export function initHotjar() {
  if (hotjarInitialized || storedTeamBrowser() || !storedCookiesAccepted()) {
    Hotjar.isReady();
    return;
  } else if (isProduction()) {
    Hotjar.init(productionHotjarSiteId, hotjarVersion);
    hotjarInitialized = true;
  } else if (isStaging()) {
    Hotjar.init(stagingHotjarSiteId, hotjarVersion);
    hotjarInitialized = true;
  }
}
