import { Props, Select, SelectInstance } from 'chakra-react-select';
import { RefAttributes } from 'react';

// Styling for this happens with index.css and targeting with className
// because Chakra style customizations don't seem to work for the third party chakra react select component
export const resistomapSelectClassNamePrefix = 'resistomap-select';

type Option = { label: unknown; value: unknown };

export function ResistomapSelect<T extends Option, IsMulti extends boolean>(
  props: Props<T, IsMulti> & RefAttributes<SelectInstance<T, IsMulti>>,
) {
  return <Select {...props} classNamePrefix={resistomapSelectClassNamePrefix} />;
}
