import {
  useOverviewContext,
  useSelectedSiteDatumOrThrow,
} from '@resistapp/client/contexts/use-overview-context/use-overview-context';
import { OverviewProcessDatum } from '@resistapp/client/data-utils/plot-data/build-overview-line-data';
import {
  getAnalysedAssays,
  getNumDetectedAndAnalysedAssays,
} from '@resistapp/client/data-utils/plot-data/process-overview-line-datum';
import { getMetricAndLevel } from '@resistapp/client/utils/metric-utils';
import { get_inRawOrEffluentSamples } from '@resistapp/client/utils/string-manipulation';
import { allGeneGroups, Target } from '@resistapp/common/assays';
import { riskClassByOneHealthAssay } from '@resistapp/common/assays-temp-96-gene-minor-targets';
import { getLog2FoldChange } from '@resistapp/common/statistics/fold-change';
import { MetricMode } from '@resistapp/common/types';
import { MetricTitle } from '../../shared/metric-title';
import { getGroupColor, getGroupTextColor } from '../../shared/palettes';
import { AntibioticStats, SiteAntibioticDetailBox } from '../site-antibiotic-detail-box';
import { SiteDetailContainer, SiteDetailsBaseProps } from './general-site-details';

export function AntibioticCompositionDetails(props: SiteDetailsBaseProps) {
  const { metricMode } = useOverviewContext();
  const selectedSiteDatum = useSelectedSiteDatumOrThrow();
  const { containerRef, antibioticTarget } = props;
  const antibioticStats: AntibioticStats[] = allGeneGroups.antibiotic
    .map(antibiotic => createAntibioticGeneStats(selectedSiteDatum, antibiotic, metricMode))
    // Only show antibiotics with analysed genes
    .filter(antibiotic => antibiotic.analysed > 0)
    // mark disabled antibiotics
    .map(antibiotic => ({
      ...antibiotic,
      isDisabled: (antibioticTarget && !antibioticTarget.includes(antibiotic.antibiotic)) || false,
    }))
    // Alphabetical order
    .sort((a, b) => (a.antibiotic < b.antibiotic ? -1 : 1));

  return (
    <SiteDetailContainer gridColumn={{ base: 1, lg: 'span 2' }} ref={containerRef} key="site-detail-box-arg">
      <SiteAntibioticDetailBox
        title={`${metricMode === MetricMode.REDUCTION ? 'Changes in a' : 'A'}ntibiotic resistance composition`}
        explanation={
          <span>
            Target antibiotic group specific
            <MetricTitle noAntibiotic={true} plural={true} />
            {get_inRawOrEffluentSamples(selectedSiteDatum, metricMode)}
          </span>
        }
        antibioticStats={antibioticStats}
      />
    </SiteDetailContainer>
  );
}

function createAntibioticGeneStats(
  selectedSiteDatum: OverviewProcessDatum,
  antibiotic: Target,
  metricMode: MetricMode,
): AntibioticStats {
  const counts = getNumDetectedAndAnalysedAssays(selectedSiteDatum, antibiotic, metricMode);
  const [metric, metricLevel] = getMetricAndLevel(
    selectedSiteDatum.abundances,
    selectedSiteDatum.afterAbundances,
    [antibiotic],
    metricMode,
  );
  const sortByField = metricMode === MetricMode.REDUCTION ? 'reduction' : 'copyNumber';
  const sortOrder = metricMode === MetricMode.REDUCTION ? 1 : -1;
  const groupColor = getGroupColor(antibiotic, 'antibiotic');
  const groupTextColor = getGroupTextColor(antibiotic, 'antibiotic');
  const analysedGenes = getAnalysedAssays(selectedSiteDatum, antibiotic, metricMode)
    .map(assay => {
      const geneReduction = getLog2FoldChange(
        selectedSiteDatum.abundances,
        selectedSiteDatum.afterAbundances,
        assay.assay,
      );
      return {
        gene: assay.gene,
        copyNumber: assay.absolute,
        riskClass: riskClassByOneHealthAssay[assay.assay],
        reduction: geneReduction,
      };
    })
    .sort((a, b) => ((a[sortByField] || 0) > (b[sortByField] || 0) ? sortOrder : -sortOrder));

  const reduction = getLog2FoldChange(selectedSiteDatum.abundances, selectedSiteDatum.afterAbundances, antibiotic);
  return {
    antibiotic,
    metric,
    metricLevel,
    detected: counts.detectedCount,
    analysed: counts.analysedCount,
    reduction,
    groupColor,
    groupTextColor,
    analysedGenes,
  };
}
