import { Button } from '@chakra-ui/react';

export interface ResistomapButtonProps {
  isLoading?: boolean;
  isDisabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
  children: React.ReactNode;
  onClick?: () => void;
  style?: React.CSSProperties;
}

export function ResistomapButton(props: ResistomapButtonProps) {
  return (
    <Button
      mt={4}
      isLoading={props.isLoading}
      isDisabled={props.isDisabled}
      type={props.type || 'button'}
      onClick={props.onClick}
      style={props.style}
    >
      {props.children}
    </Button>
  );
}
