import { Box, Flex, HStack, ListItem, UnorderedList, useRadio, useRadioGroup } from '@chakra-ui/react';
import { AbsoluteModeData } from '@resistapp/client/contexts/sample-data-context';
import { QueryFilters } from '@resistapp/client/hooks/use-query-filters/use-query-filters';
import React, { useEffect } from 'react';
import { InfoTooltipIcon } from '../../icons/info-tooltip-icon';
import { oldTheme } from '../../shared/old-styles';
import { theme } from '../../shared/theme';

export type Props = AbsoluteModeData & { queryFilters: QueryFilters };

export function AbsoluteSwitch({ enabled, queryFilters }: Props) {
  const onAndEnabled = queryFilters.filters.absoluteMode && enabled;
  const { value, setValue, getRootProps, getRadioProps } = useRadioGroup({
    name: 'absolute-mode',
    value: onAndEnabled ? 'true' : 'false',
    onChange: (val: string) => {
      queryFilters.setAbsoluteMode(val === 'true');
    },
  });
  useEffect(() => {
    setValue(onAndEnabled ? 'true' : 'false');
  }, [onAndEnabled, setValue]);
  const group = getRootProps();

  const copyLabel = 'Copy numbers';
  const relativeLabel = 'Relative abundances';
  const options = [
    {
      label: relativeLabel,
      value: 'false',
    },
    {
      label: copyLabel,
      value: 'true',
    },
  ];
  const { relativeInfo, absoluteInfo } = getRelativeAbsoluteInfo(onAndEnabled);

  return (
    <Flex align="center" style={{ width: '320px', backgroundColor: 'white' }}>
      <div>
        <HStack
          {...group}
          borderWidth="1px"
          borderRadius="md"
          height="38px"
          overflow="hidden"
          bg={theme.colors.neutral100}
          border={`1px solid ${theme.colors.neutral300}`}
          opacity={enabled ? 1 : 0.8}
        >
          {options.map((option, i) => {
            const radio = getRadioProps({ value: option.value });
            return (
              <RadioCard
                key={String(option.value)}
                selectedValue={String(value)}
                value={String(option.value)}
                disabled={!enabled}
                position={i === 0 ? 'left' : i === options.length - 1 ? 'right' : 'center'}
                {...radio}
              >
                {option.label}
              </RadioCard>
            );
          })}
        </HStack>
      </div>
      {enabled && (
        <InfoTooltipIcon>
          <UnorderedList>
            <ListItem>
              <strong>{onAndEnabled ? absoluteInfo : relativeInfo}</strong>
            </ListItem>
            <ListItem style={{ marginTop: oldTheme.spacing.xxs }}>
              {onAndEnabled ? relativeInfo : absoluteInfo}
            </ListItem>
            <ListItem style={{ marginTop: oldTheme.spacing.xxs }}>
              After clicking the toggle, you may flip between the modes with left arrow key
            </ListItem>
          </UnorderedList>
        </InfoTooltipIcon>
      )}
    </Flex>
  );
}

interface RadioCardProps {
  children: React.ReactNode;
  value: string;
  selectedValue: string;
  disabled: boolean;
  position: 'left' | 'right' | 'center';
}
function RadioCard(props: RadioCardProps) {
  const { getInputProps, getRadioProps } = useRadio(props);
  const { position } = props;

  const input = getInputProps();
  const checkbox = getRadioProps();
  const isChecked = String(props.value) === String(props.selectedValue);

  const selectedProps = isChecked
    ? {
        bg: '#FFFFFF',

        py: '2px',
        px: '10px',
        borderRadius: '5px',
      }
    : { bg: theme.colors.neutral100, m: '0px', py: '10px', px: '10px' };

  return (
    <Box as="label">
      <input {...input} disabled={props.disabled} />
      <Box
        {...checkbox}
        cursor={props.disabled ? 'not-allowed' : 'pointer'}
        {...selectedProps}
        ml={position === 'left' ? '5px' : '0px'}
        mr={position === 'right' ? '5px' : '0px'}
        width="max-content"
        style={{
          wordBreak: 'keep-all',
          whiteSpace: 'nowrap',
        }}
      >
        <div style={{ width: position === 'left' ? 149 : 105, fontWeight: isChecked ? 500 : 300 }}>
          {props.children}
        </div>
      </Box>
    </Box>
  );
}

function getRelativeAbsoluteInfo(onAndEnabled: boolean) {
  return {
    relativeInfo: `${
      onAndEnabled ? 'Alternatively, show' : 'Showing'
    } abundance of analysed genes relative to 16S rRNA gene`,
    absoluteInfo: `${onAndEnabled ? 'Showing' : 'Alternatively, show'} estimated copy numbers of analysed genes`,
  };
}
