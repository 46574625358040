import { useBreakpointValue } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { FilterBar } from '@resistapp/client/components/bars/filter-bar/filter-bar';
import { TitleBar } from '@resistapp/client/components/bars/title-bar';
import { BarBoxMap } from '@resistapp/client/components/plots/bar-box-map/bar-box-map';
import { oldTheme } from '@resistapp/client/components/shared/old-styles';
import { theme } from '@resistapp/client/components/shared/theme';
import { LoadingView } from '@resistapp/client/components/states/loading';
import { useResearchContext } from '@resistapp/client/contexts/research-context';
import { isExtraWideProject } from '@resistapp/client/data-utils/plot-data/research-plot-data';
import { useDeleteProject } from '@resistapp/client/hooks/api';
import { useNavigateWithQuery } from '@resistapp/client/hooks/use-navigate-with-query';
import { useEffect, useRef } from 'react';
import Sticky from 'react-stickynode';
import { AdminSection } from './admin-section';
import { MethodAndDownloadSections } from './method-section';

const FilterBarMargin = {
  sticky: '0px 25px',
  nonSticky: '0px 35px',
};

export function ResearchView() {
  const { sampling: project, queryFilters, absoluteMode, loading, error: sampleDataError } = useResearchContext();
  const navigate = useNavigateWithQuery();
  const { data: deleted, error: deleteError, send: triggerDelete } = useDeleteProject(project?.id || 0);
  const stickyChildRef = useRef<HTMLDivElement>(null);
  // Only activate sticky if the display screen is desktop
  const isSticky = useBreakpointValue({ base: false, lg: true });

  useEffect(() => {
    if (deleted) {
      navigate(`/admin`, false);
    }
  }, [deleted, navigate]);

  const error = sampleDataError || deleteError;
  if (error) {
    throw error;
  } else if (loading) {
    return <LoadingView />;
  }

  // TODO fix legacy projects that do not have heatmaps
  const extraWide = isExtraWideProject(project.samplesByUID);

  return (
    <div style={{ marginBottom: oldTheme.spacing.xl }} id="research-view">
      <Root>
        <TitleBar $extraWide={extraWide} title={<span>{project.name} </span>} />
        <Sticky
          enabled={isSticky}
          onStateChange={newStatus => {
            // This was fixed with manual styles, since Sticky was lagging, when using react state changes
            if (stickyChildRef.current) {
              stickyChildRef.current.style.borderBottom =
                newStatus.status !== Sticky.STATUS_ORIGINAL ? `1px solid ${theme.colors.neutral300}` : '';
              stickyChildRef.current.style.margin =
                newStatus.status !== Sticky.STATUS_ORIGINAL ? FilterBarMargin.sticky : FilterBarMargin.nonSticky;
              stickyChildRef.current.style.boxShadow =
                newStatus.status !== Sticky.STATUS_ORIGINAL ? '0px 2px 20px 0px rgba(41, 54, 81, 0.15)' : 'none';
            }
          }}
        >
          <div style={{ height: '25px' }} />
          <FilterBar
            absoluteMode={absoluteMode}
            innerRef={stickyChildRef}
            style={{
              transition: 'margin 1s 0s',
              boxShadow: 'none',
              margin: FilterBarMargin.nonSticky,
            }}
            sampling={project}
          />
        </Sticky>
      </Root>
      <div style={{ marginLeft: '20px', marginRight: '20px' }}>
        <BarBoxMap
          absoluteMode={queryFilters.filters.absoluteMode}
          setGrouping={queryFilters.setGrouping}
          filters={queryFilters.filters}
          toggleGeneGroup={queryFilters.toggleGeneGroup}
          setAbundanceMode={queryFilters.setAbundanceMode}
          toggleEnvironment={queryFilters.toggleEnvironment}
          project={project}
        />

        {project.id && (
          <MethodAndDownloadSections sampling={project} absoluteMode={queryFilters.filters.absoluteMode} />
        )}
        {project.id && <AdminSection project={project} triggerDelete={triggerDelete} />}
      </div>
    </div>
  );
}

const Root = styled.div`
  position: relative;
  z-index: ${theme.zIndexes.header};
`;
