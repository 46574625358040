import { allGeneGroups, GeneGrouping, sixteenS, Target } from '@resistapp/common/assays';
import { FullProject } from '@resistapp/common/types';
import { sortUniqEnvironments } from '@resistapp/common/utils';
import { Dictionary, isNil } from 'lodash';
import { theme } from './theme';

export function getGroupColor(group: string, grouping: GeneGrouping) {
  if (group === 'All antibiotics') {
    return '#FFFFFF';
  } else if (grouping === 'target' || grouping === 'antibiotic') {
    return targetPalette[group as Target];
  }

  const groupMembers = allGeneGroups[grouping];
  return geneGroupingPalette[(groupMembers as string[]).indexOf(group) % geneGroupingPalette.length];
}

export function getGroupTextColor(group: string, grouping: GeneGrouping) {
  return getTextColor(getGroupColor(group, grouping));
}

export function getGroupPalette(groups: string[], grouping: GeneGrouping) {
  return groups.map(t => getGroupColor(t, grouping));
}

// Color map distinct sampling environments alphabetically by type and name:
export function getEnvColorById(sampling: FullProject): Dictionary<string> {
  const environments = sortUniqEnvironments(sampling.samplesByUID);
  return environments.reduce<Dictionary<string>>((acc, env, envIdx) => {
    const paletteIdx = envIdx % envPalette.length;
    acc[env.id] = env.name === 'NTC' ? '#525252' : envPalette[paletteIdx];
    return acc;
  }, {});
}

const targetPalette: { [key in Target]: string } = {
  MGE: '#ffa929',
  Integrons: '#fa7900',
  'Beta-Lactam': '#9d0e09',
  Vancomycin: '#610525',
  MDR: '#2e081e',
  Trimethoprim: '#510c69',
  Phenicol: '#0c461b',
  Quinolone: '#0f9916',
  Sulfonamide: '#487a48',
  Tetracycline: '#2a9fc6',
  Aminoglycoside: '#2c4e81',
  MLSB: '#1a2542',
  Other: '#b66f35',
  Taxonomic: '#5a2b07',
  [sixteenS]: '#0F0F0F',
};

const miscPalette: string[] = [
  '#293651',
  '#3D68E8',
  '#872B53',
  '#8d06b0',
  '#7498E8',
  '#DC8BB1',
  '#076113',
  '#54b693',
  '#487a48',
  '#2BB151',
  '#3f5322',
  '#525252',
  '#929290',
  '#fecf0f',
  '#c7491f',
];

export function getTextColor(
  hexBackgroundColorString: string,
  light = theme.colors.neutral100,
  dark = theme.colors.neutral900,
) {
  if (isNil(hexBackgroundColorString) || hexBackgroundColorString.length !== 7 || hexBackgroundColorString[0] !== '#') {
    console.error('Invalid hex color string:', hexBackgroundColorString, typeof hexBackgroundColorString);
    return dark; // Default to dark if invalid input
  }
  const r = parseInt(hexBackgroundColorString.substring(1, 3), 16);
  const g = parseInt(hexBackgroundColorString.substring(3, 5), 16);
  const b = parseInt(hexBackgroundColorString.substring(5, 7), 16);
  const brightness = (r * 299 + g * 587 + b * 114) / 1000; // magic intensity numbers by gpt-4
  return brightness < 128 ? light : dark;
}
const envPalette: string[] = miscPalette;
const geneGroupingPalette: string[] = [...miscPalette].reverse();
