import { useCallback } from 'react';
// eslint-disable-next-line no-restricted-imports
import { useLocation, useNavigate } from 'react-router-dom';

export function useNavigateWithQuery() {
  const plainNavigate = useNavigate();
  const location = useLocation();

  const navigate = useCallback(
    (path: string | number, keepQueryString: boolean) => {
      if (typeof path === 'number') {
        plainNavigate(path);
      } else if (keepQueryString) {
        plainNavigate(`${path}${location.search}`);
      } else {
        plainNavigate(path);
      }
    },
    [plainNavigate, location.search],
  );

  return navigate;
}
