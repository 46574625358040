import { EnvironmentType } from '@resistapp/common/environment-types';
import { Dictionary, chunk, flatten, groupBy, isNil, mapValues, uniq, uniqBy } from 'lodash';
import { getGroup, getTarget, sixteenS } from './assays';
import { getSampleUID } from './sample-uid-utils';
import { FullSample, FullSamplesByUID, RawSample } from './types';

export const MAPBOX_TOKEN =
  'pk.eyJ1IjoiamFubmVyZXNpc3RvbWFwIiwiYSI6ImNsc2xqdjNkYTBlMzEybHJua21tN3l0MmEifQ.9CxerMEg8yZP613YrwXxJg';
export const REL_ABUNDANCE_DETECTION_LIMIT = 10 ** -5;

export const TRACES_VAL = -1;

// Note: See also on-marker-click.ts if re-enabling wastpan
// export const WastPanProjectIds = [1742, 1741];
// export const WastPanProjectDescription =

//   'Antibiotic Resistance Gene Index (ARGI) in urban wastewater treatment plants in Finland. Quantification of 25 Beta Lactam resistance genes, a gene marker for fecal human pollutant and three pathogens: <i>Actinobacter baumannii,  Pseudomonas aureginosa,</i> and <i>Staphylococci</i> in raw wastewater samples collected between 2020 and 2022.';
export const oldDemoProjectIds = {
  rnd2020: 254,
  wastpan: 1741,
  brokenBlomminmaki: 1948,
  global: 1682,
  nepal: 1681,
  finland: 1673,
  indonesia: 1672,
  thailand: 1670,
};
export const rndProjectId = 1962;
export const publicProjects = [
  rndProjectId,
  297, // GEUS
  332, // R&D HUS manuscript
  337, // University of Aberystwyth
  1965, // Helsinki Bathing waters temporarily opened for 24.9.2024 presentation
];

export function getEnvironmentTypeOrWasteWater(typeKeyMaybe: string | undefined | null) {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  return (typeKeyMaybe && EnvironmentType[typeKeyMaybe as keyof typeof EnvironmentType]) || EnvironmentType.WASTEWATER;
}

export async function sleep(ms = 0) {
  return await new Promise(r => setTimeout(r, ms));
}

export function parseSheetIdFromUrl(sheetLink: string | undefined) {
  const urlStart = 'https://docs.google.com/spreadsheets/d/';
  const SheetIdEnd = '/edit';
  const trimmedLink = sheetLink?.trim();
  if (trimmedLink && trimmedLink.indexOf(urlStart) === 0 && trimmedLink.indexOf(SheetIdEnd) > 0) {
    return trimmedLink.substring(urlStart.length, trimmedLink.indexOf(SheetIdEnd));
  } else {
    return undefined;
  }
}

export function groupBioSamples(samples: FullSample[]): FullSamplesByUID {
  return groupBy(samples, sample => getSampleUID(sample));
}

export function getBioNumber(sample: RawSample) {
  return `${sample.number}${sample.bioRep}`;
}

export function getAnalyzedGenes(samplesByUID: FullSamplesByUID): string[] {
  // All sampling samples should be analayzed wrt the same set of genes
  const samples = flattenSamplesByUID(samplesByUID);
  const relAbundances = flattenRelevantAbundances(samples);
  return uniq(relAbundances.map(datum => datum.gene));
}

export function flattenSamplesByUID(samplesByUID: FullSamplesByUID) {
  return flatten(Object.values(samplesByUID));
}

export function sortUniqEnvironments(samplesByUID: FullSamplesByUID) {
  // Numberical object keys are in numerical order
  return uniqBy(
    flattenSamplesByUID(samplesByUID).map(sample => sample.environment),
    env => env.id,
  );
}

export function countDetectedGenesPerSampleUID(samplesByUID: FullSamplesByUID) {
  return mapValues(
    samplesByUID,
    bioSamples =>
      uniq(
        flattenRelevantAbundances(bioSamples)
          .filter(datum => !!datum.relative)
          .map(datum => datum.gene),
      ).length,
  );
}

export function countDetectedGenesPerTarget(samples: FullSample[]) {
  const abundancesByTarget = groupBy(flattenRelevantAbundances(samples), r => getTarget(r.assay));
  return Object.keys(abundancesByTarget).reduce<Dictionary<number>>((acc, target) => {
    acc[target] = uniq(abundancesByTarget[target].filter(r => !!r.relative).map(r => r.gene)).length;
    return acc;
  }, {});
}

export function flattenAbundances(samples: FullSample[]) {
  return samples.flatMap(sample => sample.abundances);
}

export function flattenRelevantAbundances(samples: FullSample[], onlyAy1 = false) {
  return flattenAbundances(samples).filter(a => (onlyAy1 ? !not16S(a) : not16S(a)));
}

type WithAssayAndRelative = { assay: string; relative: number | null };
export function not16S(abundance: WithAssayAndRelative) {
  return getGroup(abundance.assay) !== sixteenS;
}

export function filterDetected<T extends WithAssayAndRelative>(abundances: T[]): T[] {
  return abundances.filter(v => !isNil(v.relative));
}

export const LOD = 0.00001;
export function replaceZerosWithLod(values: number[]): number[] {
  return values.map(v => (v === 0 ? LOD : v));
}

export function isStaging() {
  return getEnvironment() === 'staging';
}

export function isProduction() {
  return getEnvironment() === 'production';
}

export function getEnvironment() {
  return process.env.NODE_ENV || 'production';
}

export function isProductionLikeEnvironment() {
  return isStaging() || isProduction();
}

export function getServerUrl() {
  const env = getEnvironment();
  return env === 'production'
    ? 'https://platform.resistomap.com'
    : env === 'staging'
      ? 'https://staging.resistomap.com'
      : 'http://localhost:3001'; // replace to debug UI against prod data: https://platform.resistomap.com`
}

export function getUiUrl() {
  const env = getEnvironment();
  return env === 'production'
    ? 'https://platform.resistomap.com'
    : env === 'staging'
      ? 'https://staging.resistomap.com'
      : `http://localhost:5173`;
}

export async function chunkedAwait<A, R>(
  data: readonly A[],
  asyncFunc: (a: A, i: number) => Promise<R>,
  chunkSize = 10,
): Promise<R[]> {
  const chunks = chunk(data, chunkSize);
  const results: R[][] = [];
  for (const oneChunk of chunks) {
    const chunkResults = await Promise.all(oneChunk.map(asyncFunc));
    results.push(chunkResults);
  }
  return flatten(results);
}

export function safeAssert(condition: boolean, message?: string) {
  if (!condition) {
    throw new Error(message || 'Assertion failed');
  }
}

export function isAllNumeric(values: Array<number | string | null>): boolean {
  return values.every(value => typeof value === 'number' || (typeof value === 'string' && !isNaN(Number(value))));
}
