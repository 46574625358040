import styled from '@emotion/styled';
import IconSrc from '../../assets/icon/settings.svg';

export function SettingsIcon() {
  return <StyledIcon src={IconSrc} />;
}

const StyledIcon = styled.img`
  font-size: 13px;
  height: auto;
  padding-top: 2px;
  padding-bottom: 2px;
`;
