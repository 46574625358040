import styled from '@emotion/styled';
import { getMetricColor } from '@resistapp/client/utils/metric-utils';
import { MetricMode } from '@resistapp/common/types';
import { theme } from '../../shared/theme';
import { BaseMapLegend } from './base-map-legend';

export function RiskScoreMapLegend({ timeLegend }: { timeLegend: React.ReactNode | string }) {
  return (
    <BaseMapLegend
      timeLegend={timeLegend}
      indicators={<ColorScale />}
      indexRange={['0', '20', '40', '60', '80', '100'].map(e => (
        <div key={e}>{e}</div>
      ))}
    />
  );
}

const ColorScale = styled.div`
  width: 100%;
  height: 20px;
  background: linear-gradient(to right, ${getMetricColor(0, MetricMode.RISK)}, ${getMetricColor(100, MetricMode.RISK)});
  border-radius: ${theme.borders.radius.small};
`;
