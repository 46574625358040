import { oldTheme } from '@resistapp/client/components/shared/old-styles';
import { AbunanceSelection } from '@resistapp/client/data-utils/filter-data/filter';
import { TRACES_VAL } from '@resistapp/common/utils';

const QNT_COLORS = ['#550b1d', '#933b41', '#cc7878', '#dab9b7', '#cfd3ed', '#d6e7f2'];
const TRACE_COLOR = '#f2f2f2';
const NOT_DETECTED_COLOR = 'white';
export const QUANTIFIED_ONLY_COLORS = [...QNT_COLORS, NOT_DETECTED_COLOR];
export const ALL_COLORS = [...QNT_COLORS, TRACE_COLOR, NOT_DETECTED_COLOR];

const REL_TRESHOLDS = ['1', '0.1', '0.01', '1e-3', '1e-4', '1e-5'];
const ABS_TRESHOLDS = ['1e7', '1e6', '1e5', '1e4', '1e3', '1e2'];
const REL_BINS = REL_TRESHOLDS.map((t, i) => `≥ ${t}` + (i < REL_TRESHOLDS.length - 1 ? '' : ' (LOD)'));
const ABS_BINS = ABS_TRESHOLDS.map(t => `≥ ${t}`);
const TRACES = 'traces';
const NOT_DETECTED = 'not detected';
const ALL_ABS_BINS = [...ABS_BINS, TRACES, NOT_DETECTED];
const ALL_REL_BINS = [...REL_BINS, TRACES, NOT_DETECTED];
const QUANTFIED_REL_BINS = [...REL_BINS, NOT_DETECTED];
const QUANTFIED_ABS_BINS = [...ABS_BINS, NOT_DETECTED];

export function getBinLabels(absoluteMode: boolean, abundanceMode: AbunanceSelection) {
  return abundanceMode === AbunanceSelection.QUANTIFIED_ONLY
    ? absoluteMode
      ? QUANTFIED_ABS_BINS
      : QUANTFIED_REL_BINS
    : absoluteMode
      ? ALL_ABS_BINS
      : ALL_REL_BINS;
}

export const WORLDMAP_BINS = [...REL_BINS, 'no data'];
export const WORLDMAP_COLORS = [...QNT_COLORS, oldTheme.faintGray];

export function binColor(val: number | undefined | null, absoluteMode: boolean, isWorldmap = false) {
  const colors = isWorldmap ? WORLDMAP_COLORS : ALL_COLORS;
  const tresholds = absoluteMode ? ABS_TRESHOLDS : REL_TRESHOLDS;
  if (val === undefined || val === null) {
    return colors[colors.length - 1];
  } else if (val === TRACES_VAL) {
    return colors[colors.length - 2];
  }
  for (let i = 0; i < tresholds.length; i++) {
    if (val >= +tresholds[i]) {
      return colors[i];
    }
  }
  return colors[colors.length - 1];
}
