import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  Input,
  SimpleGrid,
} from '@chakra-ui/react';
import styled from '@emotion/styled';
import { ItemBorderBox } from '@resistapp/client/components/containers/item-border-box';
import { ResistomapSelect } from '@resistapp/client/components/forms/resistomap-select';
import { PlusIcon } from '@resistapp/client/components/icons/plus-icon';
import { theme } from '@resistapp/client/components/shared/theme';
import { usePatchOrganization, usePostOrganizationUser } from '@resistapp/client/hooks/api';
import { Feature, resistomapOrganizationId } from '@resistapp/common/features';
import { MetricMode, Organisation, PartialDict, Project, User } from '@resistapp/common/types';
import { getUiUrl } from '@resistapp/common/utils';
import { Dictionary, chain, clone, compact } from 'lodash';
import { useState } from 'react';
import { DeleteFeatureLink, DeleteOrgAccessIcon, DeleteOrgUserIcon, DeleteOrganizationLink } from './delete-icons';

interface OrganizationRowProps {
  org: Organisation;
  projectNamesById: Dictionary<Project | null>;
  usersByOrgId: PartialDict<User[]>;
  refreshUsersByOrgId: () => Promise<void>;
}

export function OrganizationRow(props: OrganizationRowProps) {
  const { org, projectNamesById, usersByOrgId, refreshUsersByOrgId } = props;

  return (
    <AccordionItem key={org.id}>
      <h2>
        <AccordionButton style={{ backgroundColor: theme.colors.neutral100 }}>
          <Box as="span" flex="1" textAlign="left">
            {org.name} ({org.id})
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel pt={0} pb={30}>
        <OrganizationDemo org={org} />
        <SignupLink org={org} />
        <OrganizationFeatures org={org} />
        <OrganizationDefaultMetric org={org} />
        <OrganizationUsers org={org} usersByOrgId={usersByOrgId} refreshUsersByOrgId={refreshUsersByOrgId} />
        <OrganizationProjectAccess org={org} projectNamesById={projectNamesById} />
        <OtherOrganisationData org={org} />
      </AccordionPanel>
    </AccordionItem>
  );
}

function OtherOrganisationData({ org }: { org: Organisation }) {
  const patcher = usePatchOrganization();
  const [newName, setNewName] = useState(org.name);

  return (
    <>
      <FieldTitle textAlign="left">Misc admin</FieldTitle>
      <Flex>
        <Input
          width="400px"
          value={newName}
          onChange={event => {
            setNewName(event.target.value);
          }}
        />
        <Button
          disabled={!!newName || newName === org.name}
          onClick={_event => {
            void (async () => {
              await patcher.mutateAsync({ id: org.id, name: newName });
              window.location.reload();
            })();
          }}
        >
          Rename
        </Button>
      </Flex>
      <Flex pt={5}>
        Delete? <DeleteOrganizationLink organisation={org} />
      </Flex>
      <Flex />
    </>
  );
}

function OrganizationDemo({ org }: { org: Organisation }) {
  if (!org.isDemo) {
    return null;
  }
  return (
    <Box pt={5} pb={5}>
      <div style={{ color: theme.colors.maroon, fontStyle: 'italic' }}>This is a Demo organization</div>
    </Box>
  );
}

function SignupLink({ org }: { org: Organisation }) {
  if (!org.signupCode) {
    return null;
  }
  return (
    <Box>
      <span
        onClick={() => {
          void navigator.clipboard.writeText(`${getUiUrl()}/signup/${org.signupCode}`);
        }}
        style={{ cursor: 'pointer', fontStyle: 'italic', textDecoration: 'underline' }}
      >
        Copy signup link
      </span>
    </Box>
  );
}

function OrganizationFeatures({ org }: { org: Organisation }) {
  const patcher = usePatchOrganization();

  return (
    <>
      <FieldTitle pt={10}>Features:</FieldTitle>
      <Flex gap={10}>
        {compact([
          <ItemBorderBox style={{ opacity: 0.5 }} key="research-static">
            Research
          </ItemBorderBox>,
          ...org.features.map((feature, _key) => (
            <ItemBorderBox key={_key}>
              {feature} <DeleteFeatureLink featureToDelete={feature} organisation={org} />
            </ItemBorderBox>
          )),
          ...Object.values(Feature)
            .filter(f => f !== Feature.ADMIN && !org.features.includes(f))
            .map(f => (
              <ItemBorderBox
                key={f}
                style={{ opacity: 0.5, cursor: 'pointer', userSelect: 'none' }}
                _hover={{ opacity: '1.0 !important' }}
                onClick={() => {
                  const updatedFeatures = [...org.features, f];
                  void (async () => {
                    await patcher.mutateAsync({ id: org.id, features: updatedFeatures });
                    window.location.reload();
                  })();
                }}
              >
                <PlusIcon />
                &nbsp;{f}
              </ItemBorderBox>
            )),
        ])}
      </Flex>
    </>
  );
}

function OrganizationDefaultMetric({ org }: { org: Organisation }) {
  const patcher = usePatchOrganization();

  return (
    <>
      <FieldTitle pt={10}>Default metric:</FieldTitle>
      <Flex gap={10}>
        {compact([
          ...Object.values(MetricMode).map(metric => (
            <ItemBorderBox
              key={metric}
              style={metric === org.defaultMetric ? {} : { opacity: 0.5, cursor: 'pointer', userSelect: 'none' }}
              _hover={metric === org.defaultMetric ? {} : { opacity: '1.0 !important' }}
              onClick={
                metric === org.defaultMetric
                  ? undefined
                  : () => {
                      void (async () => {
                        await patcher.mutateAsync({ id: org.id, defaultMetric: metric });
                        window.location.reload();
                      })();
                    }
              }
            >
              {metric === org.defaultMetric ? metric : <>&nbsp;{metric}</>}
            </ItemBorderBox>
          )),
        ])}
      </Flex>
    </>
  );
}

interface OrganizationUsersProps {
  org: Organisation;
  usersByOrgId: PartialDict<User[]>;
  refreshUsersByOrgId: () => Promise<void>;
}

function OrganizationUsers(props: OrganizationUsersProps) {
  const { org, usersByOrgId, refreshUsersByOrgId } = props;
  const users = chain(usersByOrgId)
    .values()
    .flatten()
    .compact()
    .uniqBy((u: User) => u.id)
    .value();
  const orgUsers = usersByOrgId[org.id] || [];
  const nonOrgUsers = users.filter(user => !orgUsers.some(orgUser => orgUser.id === user.id));
  const post = usePostOrganizationUser();

  const options = nonOrgUsers.map(user => ({
    value: user.id,
    label: `${user.firstName} ${user.lastName} (${user.email})`,
  }));

  return (
    <>
      <FieldTitle>Users:</FieldTitle>
      <Flex wrap="wrap" gap="20px">
        {orgUsers.map((u, _key) => (
          <ItemBorderBox key={_key}>
            {u.email} <DeleteOrgUserIcon refreshUsersByOrgId={refreshUsersByOrgId} organization={org} user={u} />
          </ItemBorderBox>
        ))}
        <ResistomapSelect
          options={options}
          key={'add-user'}
          onChange={selectedOption => {
            if (!selectedOption) {
              return;
            }
            void (async () => {
              await post(org.id, selectedOption.value);
              await refreshUsersByOrgId();
            })();
          }}
          isClearable={true}
          value={null}
          isMulti={false}
          placeholder="Add a user..."
        />
      </Flex>
    </>
  );
}

function OrganizationProjectAccess({
  org,
  projectNamesById,
}: {
  org: Organisation;
  projectNamesById: Dictionary<Project | null>;
}) {
  return (
    <>
      <FieldTitle>Project access (remove on project page):</FieldTitle>
      <SimpleGrid minChildWidth="200px" spacingX="20px" spacingY="10px">
        {org.accesses.length === 0 ? (
          '-'
        ) : org.id === resistomapOrganizationId ? (
          <ItemBorderBox>All projects</ItemBorderBox>
        ) : (
          sortAccesses(org).map((access, _key) => (
            <ItemBorderBox key={_key}>
              {projectNamesById[access.projectId]?.name}{' '}
              <DeleteOrgAccessIcon
                organization={org}
                projectId={access.projectId}
                projectName={projectNamesById[access.projectId]?.name}
              />
            </ItemBorderBox>
          ))
        )}
      </SimpleGrid>
    </>
  );
}

function sortAccesses(org: Organisation) {
  // We clone so it's immutable
  return clone(org.accesses).sort((a, b) => (a.projectId > b.projectId ? 1 : -1));
}

const FieldTitle = styled(Box)`
  font-weight: 501;
  padding-top: 30px;
  padding-bottom: 15px;
`;
