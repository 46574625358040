import { Flex } from '@chakra-ui/react';
import { useOverviewContext } from '@resistapp/client/contexts/use-overview-context/use-overview-context';
import {
  getMainAbundances,
  getNumDetectedAndAnalysedAssays,
  targetAbundancesByAssay,
} from '@resistapp/client/data-utils/plot-data/process-overview-line-datum';
import { get_inRawOrEffluentSamples } from '@resistapp/client/utils/string-manipulation';
import { MinorTarget } from '@resistapp/common/assays-temp-96-gene-minor-targets';
import { MetricMode } from '@resistapp/common/types';
import { chain } from 'lodash';
import { MSTHorizontalBarGraph } from '../mst-horizontal-bar-graph';
import { SiteDetailTitle } from '../site-detail-title';
import { SiteDetailContainer, SiteDetailsBaseProps } from './general-site-details';

export function MSTDetails({ containerRef }: SiteDetailsBaseProps) {
  const { selectedSiteDatum, metricMode } = useOverviewContext();

  if (!selectedSiteDatum) {
    return null;
  }

  const { detectedCount, analysedCount } = getNumDetectedAndAnalysedAssays(
    selectedSiteDatum,
    MinorTarget.MST,
    metricMode,
  );
  const mainAbundances = getMainAbundances(selectedSiteDatum, metricMode);
  const mainAbundancesByAssay = targetAbundancesByAssay(mainAbundances, MinorTarget.MST);
  const detectedMstMainAbundances = chain(mainAbundancesByAssay)
    .values()
    .flatten()
    // .filter(target => target.absolute !== null) // This is not needed as we show all analysed categories
    .value();

  const afterAbundancesByAssay = selectedSiteDatum.afterAbundances
    ? targetAbundancesByAssay(selectedSiteDatum.afterAbundances, MinorTarget.MST)
    : null;
  const detectedMstAfterAbundances = afterAbundancesByAssay
    ? chain(afterAbundancesByAssay)
        .values()
        .flatten()
        // .filter(target => target.absolute !== null) // This is not needed as we show all analysed categories
        .value()
    : [];

  const showAfterAbundances = metricMode === MetricMode.REDUCTION && detectedMstAfterAbundances.length > 0;

  const _inRawOrEffluentSamples = get_inRawOrEffluentSamples(selectedSiteDatum, metricMode);
  return (
    <SiteDetailContainer ref={containerRef} key="site-detail-box-pathogens">
      <SiteDetailTitle
        title="Microbial source tracking"
        explanation={`Relative distribution of host-specific genetic marker copy numbers${_inRawOrEffluentSamples}`}
        counts={{ detectedCount, analysedCount }}
      />
      {detectedMstMainAbundances.length > 0 ? (
        <>
          {showAfterAbundances ? (
            <>
              <MSTHorizontalBarGraph data={detectedMstMainAbundances} title="Raw" />
              <MSTHorizontalBarGraph data={detectedMstAfterAbundances} title="Treated" hideLegend={true} />
            </>
          ) : (
            <MSTHorizontalBarGraph data={detectedMstMainAbundances} />
          )}
        </>
      ) : (
        <Flex justifyContent="center" alignItems="center" height="100%">
          No microbial source tracking data available
        </Flex>
      )}
    </SiteDetailContainer>
  );
}
