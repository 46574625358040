import { Accordion, Button, Flex, useToast } from '@chakra-ui/react';
import { TitleBar } from '@resistapp/client/components/bars/title-bar';
import { LocalStorageId } from '@resistapp/client/components/shared/local-storage';
import { theme } from '@resistapp/client/components/shared/theme';
import { LoadingView } from '@resistapp/client/components/states/loading';
import { isAdmin } from '@resistapp/common/features';
import { Project } from '@resistapp/common/types';
import { Dictionary, keyBy } from 'lodash';
import { NavLink } from 'react-router-dom';
import useLocalStorageState from 'use-local-storage-state';
import { useUser } from '../../contexts/use-user-context';
import { useOrganizations, useProjects } from '../../hooks/api';
import { OrganizationRow } from './organization-row';
import { useUsersByOrganizationId } from './use-org-users';
import { AllUsersSection } from './users-section';

export function OrganisationsView() {
  const { user } = useUser();
  const { data: organizations, isLoading, error: organisationsError } = useOrganizations();
  const { data: projects, error: projectsError } = useProjects();

  const { usersByOrgId, refreshUsersByOrgId } = useUsersByOrganizationId();
  const toast = useToast();
  const [orgToast, setOrganizationCreated] = useLocalStorageState(LocalStorageId.organizationCreated, {
    defaultValue: false,
  });

  const error = organisationsError || projectsError;
  if (error) {
    throw error;
  } else if (isLoading || !organizations) {
    return <LoadingView />;
  }

  if (orgToast) {
    setOrganizationCreated(false);
    toast({
      title: 'Organisation created',
      description: 'The organization has been created',
      status: 'success',
      duration: 9000,
      isClosable: true,
    });
  }

  const projectNamesById: Dictionary<Project | null> = keyBy(projects, 'id');

  return (
    <div style={{ marginBottom: theme.spacing[16] }}>
      <TitleBar
        title="Organizations"
        $extraWide={true}
        controls={
          isAdmin(user) ? (
            <Flex gap={theme.spacing[4]}>
              <Button as={NavLink} to="/organization/create" alignSelf="center">
                New organization
              </Button>
              <Button as={NavLink} to="/user" alignSelf="center">
                New user
              </Button>
              <Button as={NavLink} to="/compose" alignSelf="center" variant="green">
                New pooled project
              </Button>
              <Button as={NavLink} to="/analyse" alignSelf="center" variant="green">
                New project
              </Button>
            </Flex>
          ) : null
        }
      />
      <Accordion allowToggle>
        {organizations.map((org, key) => (
          <OrganizationRow
            key={key}
            org={org}
            projectNamesById={projectNamesById}
            usersByOrgId={usersByOrgId}
            refreshUsersByOrgId={refreshUsersByOrgId}
          />
        ))}
      </Accordion>
      <AllUsersSection />
    </div>
  );
}
