import { useGeographicalReferenceLines } from './use-geographical-reference-lines';

interface Props {
  graphWidth: number;
  width: number;
  graphHeight: number;
  strokeColor: string;
  margin: { left: number; right: number };
}

export default function ReferenceLines(props: Props) {
  const { margin, width, graphHeight, graphWidth, strokeColor } = props;
  return (
    <>
      {/* Gray line marking high level border */}
      <line
        x1="0"
        x2={width - margin.right - margin.left}
        y1={graphHeight / 5}
        y2={graphHeight / 5}
        stroke={strokeColor}
      />
      {/* Gray line marking moderate level border */}
      <line
        x1="0"
        x2={width - margin.right - margin.left}
        y1={(graphHeight / 5) * 3}
        y2={(graphHeight / 5) * 3}
        stroke={strokeColor}
      />
      {/* Global and Continent Reference Lines */}
      <GeographicalReferenceLines graphWidth={graphWidth} graphHeight={graphHeight} />
    </>
  );
}

interface GeographicalReferenceLinesProps {
  graphWidth: number;
  graphHeight: number;
}

function GeographicalReferenceLines({ graphWidth, graphHeight }: GeographicalReferenceLinesProps) {
  const lines = useGeographicalReferenceLines(graphHeight);
  return (
    <>
      {lines.map((line, index) => (
        <line key={index} x1="0" x2={graphWidth} y1={line.y} y2={line.y} {...line.lineProps} />
      ))}
    </>
  );
}
