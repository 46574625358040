import { Box } from '@chakra-ui/react';
import { OverviewLineData } from '@resistapp/client/data-utils/plot-data/build-overview-line-data';
import { getNumDetectedAndAnalysedAssays } from '@resistapp/client/data-utils/plot-data/process-overview-line-datum';
import { allGeneGroups, Target } from '@resistapp/common/assays';
import { MetricMode } from '@resistapp/common/types';

import { getGroupColor, getGroupTextColor } from '../shared/palettes';
import { theme } from '../shared/theme';
import { AntibioticSelector } from './antibiotic-selector';
import { MonthSelector } from './month-selector';
import { antibioticGroupOptions, Option } from './types';

type Props = {
  title: string;
  bolded?: boolean;
  onChange: (value: Option | null) => void;
  metricMode: MetricMode;
  selectedTargets: Target[];
  trendData: OverviewLineData | undefined;
};

export function ScopeSelectors({ title, bolded, onChange, selectedTargets, metricMode, trendData }: Props) {
  const selectedAntibiotic: Option = getSelectedAntibioticOption(selectedTargets);

  // We only want to show antibiotics that have been analysed
  const analyzedAntibioticGroups = getAnalysedGroupsAndAll(trendData, metricMode);

  return (
    <Box fontSize={theme.fontSize.medium} fontWeight={theme.fontWeight.bold}>
      <AntibioticSelector
        metricMode={metricMode}
        onChange={onChange}
        selectedAntibiotic={selectedAntibiotic}
        analyzedAntibioticGroups={analyzedAntibioticGroups}
      />
      <MonthSelector />
      <div
        style={{
          fontWeight: bolded ? theme.fontWeight.extraHeavy : theme.fontWeight.bold,
        }}
      >
        {title}
      </div>
    </Box>
  );
}

function getAnalysedGroupsAndAll(trendData: OverviewLineData | undefined, metricMode: MetricMode) {
  const analyzedAntibioticGroups = allGeneGroups.antibiotic.filter(antibiotic => {
    return trendData?.flat().some(datum => {
      return getNumDetectedAndAnalysedAssays(datum, antibiotic, metricMode).analysedCount > 0;
    });
  });
  return analyzedAntibioticGroups.length > 0
    ? (['All antibiotics', ...analyzedAntibioticGroups] satisfies Array<Target | 'All antibiotics'>)
    : ([] satisfies Array<Target | 'All antibiotics'>);
}

function getSelectedAntibioticOption(selectedGroups: Target[]): Option {
  // We minus one because we don't want to include 'All antibiotics' in the count
  const isAllAntibiotics = selectedGroups.length === antibioticGroupOptions.length - 1;
  const selectedValue = isAllAntibiotics ? 'All antibiotics' : selectedGroups[0];
  const groupColor = isAllAntibiotics
    ? 'white'
    : selectedGroups[0]
      ? getGroupColor(selectedGroups[0], 'antibiotic')
      : 'white';
  const groupTextColor = isAllAntibiotics
    ? theme.colors.neutral800
    : selectedGroups[0]
      ? getGroupTextColor(selectedGroups[0], 'antibiotic')
      : theme.colors.neutral800;
  return {
    value: selectedValue,
    label: selectedValue,
    textColor: groupTextColor,
    bgColor: groupColor,
  };
}
