import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CSSProperties } from 'react';
import { theme } from '../shared/theme';

interface Props {
  onClick: () => void;
  style?: CSSProperties;
}

export function CloseIconCircle({ onClick, style }: Props) {
  return (
    <FontAwesomeIcon
      style={{ ...getCircleStyles(), ...style }}
      icon={faXmark as IconProp}
      onClick={onClick}
      color="white"
    />
  );
}

export function CloseIconSimple({ onClick, style }: Props) {
  return (
    <FontAwesomeIcon
      style={{ ...getBaseStyles(), ...style }}
      icon={faXmark as IconProp}
      onClick={onClick}
      color="white"
    />
  );
}

function getCircleStyles() {
  return {
    ...getBaseStyles(),
    position: 'absolute',
    top: '-13px',
    right: '-13px',
    width: '16px',
    height: '16px',
    border: `1px solid ${theme.colors.neutral300}`,
    filter: theme.shadows.normal.filter,
    borderRadius: '50%',
    backgroundColor: 'white',
    color: theme.colors.neutral700,
    padding: '4px',
  } satisfies CSSProperties;
}
function getBaseStyles() {
  return {
    cursor: 'pointer',
    color: theme.colors.neutral700,
  } satisfies CSSProperties;
}
