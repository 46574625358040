import { FormControl, FormLabel, Input, Stack } from '@chakra-ui/react';
import { FormView } from '@resistapp/client/components/shared/layout';
import { ErrorDiv } from '@resistapp/client/components/states/error';
import { useUser } from '@resistapp/client/contexts/use-user-context';
import { getUserDefaultMetric, getUserProjectIds } from '@resistapp/common/access';
import { Feature, hasFeature, isAdmin } from '@resistapp/common/features';
import { MetricMode } from '@resistapp/common/types';
import { rndProjectId } from '@resistapp/common/utils';
import { useEffect, useState } from 'react';
import useLocalStorageState from 'use-local-storage-state';
import { AgreePoliciesCheckbox } from '../components/agree-policies-checkbox/agree-policies-checkbox';
import { ResistomapButton } from '../components/buttons/resistomap-button';
import { RSecondTitle } from '../components/headers/r-second-title';
import { LocalStorageId } from '../components/shared/local-storage';
import { useNavigateWithQuery } from '../hooks/use-navigate-with-query';
import { initHotjar } from '../hotjar';

export function LoginView() {
  const { user, loginErrorMessage, login, loggingIn } = useUser();
  const [cookiesAccepted, setCookiesAccepted] = useLocalStorageState(LocalStorageId.cookiesAccepted, {
    defaultValue: false,
  });
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigateWithQuery();

  // This handles the redirection of the user to the correct place after successful login has been done (ie. we have user-property)
  useEffect(() => {
    if (!user) {
      initHotjar();
    } else if (isAdmin(user)) {
      navigate('/admin', false);
    } else {
      const projectIds = getUserProjectIds(user);
      const projectId = projectIds[0] || rndProjectId;
      const defaultMetric = getUserDefaultMetric(user);

      if (hasFeature(user, Feature.ARGI) && defaultMetric === MetricMode.ARGI) {
        navigate(`/index/${projectId}`, false);
      } else if (hasFeature(user, Feature.RISK) && defaultMetric === MetricMode.RISK) {
        navigate(`/risk/${projectId}`, false);
      } else if (hasFeature(user, Feature.REDUCTION) && defaultMetric === MetricMode.REDUCTION) {
        navigate(`/reduction/${projectId}`, false);
      } else {
        if (projectIds.length > 1) {
          navigate(`/research/${projectIds[0]}`, false);
        } else {
          navigate(`/research/${rndProjectId}`, false);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const loginDisabled = !email || !password || !cookiesAccepted || loggingIn || !!user;

  return (
    <FormView>
      <RSecondTitle>Login</RSecondTitle>
      <Stack spacing={5} width="full">
        <FormControl variant="floating" isRequired>
          <Input
            value={email}
            onChange={event => {
              setEmail(event.target.value);
            }}
            size="md"
            isRequired
            id="email"
            placeholder=" "
            name="email"
            autoComplete="email"
            autoFocus
            width="full"
          />
          <FormLabel>Email Address</FormLabel>
        </FormControl>
        <FormControl variant="floating" isRequired>
          <Input
            value={password}
            onChange={event => {
              setPassword(event.target.value);
            }}
            size="md"
            isRequired
            width="full"
            name="password"
            placeholder=" "
            type="password"
            id="password"
            autoComplete="current-password"
            autoFocus
          />
          <FormLabel>Password</FormLabel>
        </FormControl>
        <AgreePoliciesCheckbox
          cookiesAccepted={cookiesAccepted}
          setCookiesAccepted={setCookiesAccepted}
          disabled={loggingIn || !!user}
        />
        <ResistomapButton
          style={{ textTransform: 'capitalize', width: '100%' }}
          onClick={() => {
            login(email, password);
          }}
          isDisabled={loginDisabled}
        >
          {loggingIn ? 'Logging in...' : 'Log in'}
        </ResistomapButton>
        {loginErrorMessage && <ErrorDiv>{loginErrorMessage}</ErrorDiv>}
      </Stack>
    </FormView>
  );
}
