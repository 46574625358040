import styled from '@emotion/styled';
import { useWindowSize } from '@react-hook/window-size';
import { Footer } from '@resistapp/client/components/page-layout/footer/footer';
import { headerHeight } from '@resistapp/client/components/page-layout/header-bar/header-bar';
import { GeneLegend } from '@resistapp/client/components/plots/legends/gene-legend';
import { binColor } from '@resistapp/client/components/plots/legends/heatmap-bins';
import { WorldmapLegend } from '@resistapp/client/components/plots/legends/worldmap-legend';
import { oldTheme } from '@resistapp/client/components/shared/old-styles';
import { getGroupColor } from '@resistapp/client/components/shared/palettes';
import { theme } from '@resistapp/client/components/shared/theme';
import { PlotTooltip, usePlotTooltip } from '@resistapp/client/components/tooltips/plot-tooltip';
import { useUser } from '@resistapp/client/contexts/use-user-context';
import { Filters } from '@resistapp/client/data-utils/filter-data/filter';
import { useWorldmap } from '@resistapp/client/hooks/api';
import { useNavigateWithQuery } from '@resistapp/client/hooks/use-navigate-with-query';
import { useQueryFilters } from '@resistapp/client/hooks/use-query-filters/use-query-filters';
import { AbundanceStats } from '@resistapp/common/api-types';
import { allGeneGroups, groupingName } from '@resistapp/common/assays';
import { EnvironmentType } from '@resistapp/common/environment-types';
import { getEnvironmentTypeOrWasteWater, rndProjectId } from '@resistapp/common/utils';
import { Graticule, Mercator } from '@visx/geo';
import useTooltipInPortal, { UseTooltipInPortal } from '@visx/tooltip/lib/hooks/useTooltipInPortal';
import { Dictionary, compact } from 'lodash';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { feature } from 'topojson-client';
import { Topology } from 'topojson-specification';
import { CountryTooltipContent } from './tooltip';
import topology from './world-topo.json';

const startAnimationLength = 0.5;

export type GeoMercatorProps = {
  width: number;
  height: number;
  events?: boolean;
};

interface FeatureShape {
  type: 'Feature';
  id: string;
  geometry: { coordinates: Array<Array<[number, number]>>; type: 'Polygon' };
  properties: { name: string };
}

const typedTopology = topology as unknown as Topology;
const world = feature(typedTopology, typedTopology.objects.units);

export function WorldView() {
  const { data } = useWorldmap();
  const navigate = useNavigateWithQuery();
  const [width] = useWindowSize();
  const { user } = useUser();
  const { queryFilters } = useQueryFilters(undefined);
  const { focusInfo, setGrouping, filters } = queryFilters;
  const tooltipStuff = useTooltipInPortal({
    scroll: false,
    detectBounds: false,
  });
  const [searchParams] = useSearchParams();
  const type = getEnvironmentTypeOrWasteWater(searchParams.get('type'));

  const tooltipRef = tooltipStuff.containerRef;
  const [starting, setStarting] = useState(false);
  const [started, setStarted] = useState(!!user);

  const groupingData = data && data[filters.selectedTargetGrouping];
  const centerX = 0.5 * width;
  const centerY = 0.33 * width;
  const height = width - 0.17 * width; // centerY clips this much in the north
  const scale = (width / 630) * 100;

  const legendFitsOnMap = legendHeight < height - headerHeight - 40;
  const typedWorld = world as unknown as { features: FeatureShape[] };

  return (
    <>
      {width < 10 ? null : (
        <svg
          id="worldview-map"
          ref={tooltipRef}
          width={width}
          height={height}
          style={{
            backgroundColor: theme.colors.neutral50,
            position: 'absolute',
            top: '0px',
            left: '0px',
            paddingTop: '40px',
          }}
        >
          <Mercator<FeatureShape> data={typedWorld.features} scale={scale} translate={[centerX, centerY]}>
            {mercator => {
              // Draw Finland last
              const finland = mercator.features.find(
                ({ feature: localFeature }) => localFeature.properties.name === 'Finland',
              );
              const others = mercator.features.filter(
                ({ feature: localFeature }) => localFeature.properties.name !== 'Finland',
              );
              const sortedFeatures = compact([...others, finland]);

              return (
                <g>
                  <Graticule graticule={g => mercator.path(g) || ''} stroke={oldTheme.darkGray} strokeOpacity={0.0} />
                  {sortedFeatures.map(({ feature: localFeature, path }, i) => (
                    <Country
                      key={`map-feature-${i}`}
                      tooltipStuff={tooltipStuff}
                      filters={filters}
                      started={started}
                      groupingData={groupingData}
                      focusInfo={focusInfo}
                      feature={localFeature}
                      path={path}
                      demoProjectId={
                        localFeature.properties.name === 'Finland' && type === EnvironmentType.WASTEWATER
                          ? rndProjectId
                          : undefined
                      }
                    />
                  ))}
                </g>
              );
            }}
          </Mercator>
        </svg>
      )}
      {started && (
        <FloatingLegends style={{ top: `${width < 850 ? 80 : width / 5}px`, left: oldTheme.spacing.s }}>
          <FloatingLegend>
            <WorldmapLegend />
          </FloatingLegend>
          <FloatingLegend style={{ width: '221px' }}>
            <GeneLegend
              setGrouping={setGrouping}
              toggleGeneGroup={queryFilters.toggleGeneGroup}
              availableGroups={allGeneGroups[filters.selectedTargetGrouping]}
              filters={filters}
              disableKeys={true}
              defaultValue="antibiotic"
              header="Gene classes"
              legendHeight="260px"
              topHeight="84px"
              showOptions={true}
            />
          </FloatingLegend>
        </FloatingLegends>
      )}
      {!started && (
        <LandingTitle
          style={{ top: 0 }}
          onClick={() => {
            setStarting(true);
            setTimeout(() => {
              setStarted(true);
            }, startAnimationLength * 1000);
          }}
        >
          <Title $starting={starting}>
            Antibiotic Resistance
            <br />
            in Wastewater
          </Title>
          <Instructions $starting={starting}>
            Click to explore
            <br />
            {user === null && (
              <LoginHint
                onClick={() => {
                  navigate('/login', false);
                }}
              >
                or login
              </LoginHint>
            )}
          </Instructions>
        </LandingTitle>
      )}
      {legendFitsOnMap && <Footer top={height} />}
    </>
  );
}

interface CountryProps {
  started: boolean;
  filters: Filters;
  path: string | null;
  focusInfo: string;
  feature: FeatureShape;
  groupingData: Dictionary<Dictionary<AbundanceStats>> | undefined;
  tooltipStuff: UseTooltipInPortal;
  demoProjectId: number | undefined;
}

function Country(props: CountryProps) {
  const { path, started, filters, groupingData, focusInfo, feature: _feature, tooltipStuff, demoProjectId } = props;
  const navigate = useNavigateWithQuery();
  const { tooltipData, tooltipProps, handleMouseMove } = usePlotTooltip<
    AbundanceStats & { label: string; color: string; median: number }
  >(tooltipStuff);
  const key = filters.selectedTargets.length === 1 ? filters.selectedTargets[0] : 'all';
  const stats = groupingData?.[_feature.id]?.[key];
  const color = started ? binColor(stats?.median, false, true) : 'white';
  const geneGroupInfo = focusInfo.length ? focusInfo : groupingName.antibiotic;
  const groupColor: string = getGroupColor(key, filters.selectedTargetGrouping) || oldTheme.darkGray;
  const label = `${_feature.properties.name} — ${geneGroupInfo}`;
  const datum = stats && { ...stats, date: '', color: groupColor, label };
  const stroke = started ? (demoProjectId ? oldTheme.blue : oldTheme.slightBlue) : oldTheme.faintGreen;

  return (
    <>
      <path
        d={path || ''}
        fill={color}
        stroke={stroke}
        strokeWidth={started && demoProjectId ? 1 : 0.5}
        onMouseEnter={event => {
          if (datum && datum.median !== undefined) {
            handleMouseMove(event, { ...datum, median: datum.median }, true);
          }
        }}
        onMouseLeave={event => {
          handleMouseMove(event, undefined);
        }}
        onMouseMove={event => {
          if (datum && datum.median !== undefined) {
            handleMouseMove(event, { ...datum, median: datum.median });
          }
        }}
        onClick={() => {
          if (demoProjectId) {
            navigate(`/index/${demoProjectId}`, false);
          }
        }}
      />
      {tooltipData && (
        <PlotTooltip {...tooltipProps} backgroundColor={theme.colors.backgroundGrayTransparent6}>
          <CountryTooltipContent demoProjectId={demoProjectId} tooltipData={tooltipData} />
        </PlotTooltip>
      )}
    </>
  );
}

const legendHeight = 530;
const FloatingLegends = styled.div`
  margin: ${oldTheme.spacing.xs};
  position: absolute;
  width: 190px;
  height: ${legendHeight}px;
`;

const FloatingLegend = styled.div`
  padding-top: ${oldTheme.spacing.m};
  padding-right: ${oldTheme.spacing.xs};
  background-color: ${theme.colors.backgroundGrayTransparent6};
`;

const LandingTitle = styled.div`
  padding-top: ${oldTheme.spacing.m};
  padding-right: ${oldTheme.spacing.xs};
  background-color: ${oldTheme.transparent};
  position: absolute;
  width: 100%;
  height: 100%;
`;

interface TestingProps {
  $starting: boolean;
}

const Title = styled.div<TestingProps>`
  color: ${props => (props.$starting ? oldTheme.transparent : oldTheme.blue)};
  text-align: center;
  padding-top: 180px;
  font-family: ${oldTheme.fontFamily};
  font-size: 60px;
  line-height: 67px;
  font-weight: 700;
  transition: color ${startAnimationLength}s ease-in-out;
`;

const Instructions = styled.div<TestingProps>`
  color: ${props => (props.$starting ? oldTheme.transparent : oldTheme.blue)};
  text-align: center;
  font-family: ${oldTheme.fontFamily};
  font-size: 32px;
  margin-top: 32px;
  line-height: 32px;
  font-weight: 700px;
  transition: color ${startAnimationLength}s ease-in-out;
`;

const LoginHint = styled.span`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;
