import { CSSProperties } from 'react';

interface CaretIconProps {
  style?: CSSProperties;
}

export function UpRightIcon({ style }: CaretIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="9"
      viewBox="0 0 8 9"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.333"
      strokeLinecap="round"
      strokeLinejoin="round"
      style={style}
    >
      <path d="M0.790208 7.83793L7.20996 1.17126" />
      <path d="M0.790208 1.17126H7.20996V7.83793" />
    </svg>
  );
}

export function DownRightIcon({ style }: CaretIconProps) {
  return (
    // <svg xmlns="http://www.w3.org/2000/svg" width="8" height="9" viewBox="0 0 8 9" fill="none">
    // </svg>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="9"
      viewBox="0 0 8 9"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.333"
      strokeLinecap="round"
      strokeLinejoin="round"
      style={style}
    >
      <path d="M0.790208 1.17126L7.20996 7.83793" />
      <path d="M7.20996 1.17126V7.83793H0.790208" />
      {/* <path d="M7 7L17 17M17 17v-8M17 17H9" /> */}
    </svg>
  );
}

export function RightIcon({ style }: CaretIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="9"
      viewBox="0 0 8 9"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.333"
      strokeLinecap="round"
      strokeLinejoin="round"
      style={style}
    >
      <path d="M0.790208 4.50459H7.20996" />
      <path d="M4.5 1.17126L7.20996 4.50459L4.5 7.83793" />
    </svg>
  );
}
