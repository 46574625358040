import { useUsers } from '@resistapp/client/hooks/api';
import { PartialDict, User } from '@resistapp/common/types';
import { reduce } from 'lodash';
import { useEffect, useState } from 'react';

export function useUsersByOrganizationId() {
  const [usersByOrgId, setUsersByOrg] = useState<PartialDict<User[]>>({});
  const { data: users, send: loadUsers } = useUsers();
  useEffect(() => {
    if (users) {
      const usersDict = reduce(
        users,
        (acc, u) => {
          if (u.organizations.length === 0) {
            // Hackily, include non-org users
            acc[`0`]?.push(u);
          }
          for (const organization of u.organizations) {
            acc[organization.id]?.push(u) || (acc[organization.id] = [u]);
          }

          return acc;
        },
        { '0': [] } as PartialDict<User[]>,
      );
      setUsersByOrg(usersDict);
    } else {
      void loadUsers();
    }
  }, [users, loadUsers]);

  return {
    usersByOrgId,
    refreshUsersByOrgId: async () => {
      await loadUsers();
    },
  };
}
