import { PlotTooltip, usePlotTooltip } from '@resistapp/client/components/tooltips/plot-tooltip';
import { GeneAndCopyNumber } from '@resistapp/client/data-utils/plot-data/process-overview-line-datum';
import { metricRange } from '@resistapp/client/utils/metric-utils';
import { friendlyCopyNumber, friendlyFoldChange } from '@resistapp/common/friendly';
import { MetricMode } from '@resistapp/common/types';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { GridColumns } from '@visx/grid';
import { Group } from '@visx/group';
import { scaleBand, scaleLinear } from '@visx/scale';
import { Line } from '@visx/shape';
import { useTooltipInPortal } from '@visx/tooltip';
import { isNil, max } from 'lodash';
import { useMemo } from 'react';
import { theme } from '../shared/theme';
import { CopyNumberBar } from './copy-number-bar';
import { formatGeneMutationSuffixes } from './site-details/general-site-details';

interface Props {
  genesAndNumbers: GeneAndCopyNumber[];
  width: number;
  height: number;
  events: boolean;
  metricMode: MetricMode;
  italic?: boolean;
}

export function CopyNumberBarGraph({ width, height, genesAndNumbers, metricMode, italic = false }: Props) {
  const tooltipStuff = useTooltipInPortal({
    scroll: false,
    detectBounds: true,
  });
  const { handleMouseMove, tooltipProps, tooltipData } = usePlotTooltip<GeneAndCopyNumber>(tooltipStuff);

  const is2Sided = metricMode === MetricMode.REDUCTION;
  const scaleProperty = metricMode === MetricMode.REDUCTION ? 'reduction' : 'copyNumber';
  if (metricMode === MetricMode.REDUCTION) {
    assertReductionData(genesAndNumbers);
  }

  const xMax = is2Sided ? width : width - margins.horizontal;
  const yMax = height - margins.vertical;
  const xMinScale = metricMode === MetricMode.REDUCTION ? metricRange[MetricMode.REDUCTION].min : 1;
  const maxNumber =
    metricMode === MetricMode.REDUCTION
      ? metricRange[MetricMode.REDUCTION].max
      : max(genesAndNumbers.map(d => d[scaleProperty])) || 0;
  const descendingGenesAndNumbers = [...genesAndNumbers].sort((a, b) => {
    const aValue = a[scaleProperty];
    const bValue = b[scaleProperty];
    if (isNil(aValue) || isNil(bValue)) {
      return 0;
    }
    return bValue - aValue;
  });

  const yScale = useMemo(
    () =>
      scaleBand<string>({
        range: [0, yMax],
        round: true,
        domain: descendingGenesAndNumbers.map(d => getScaleLabel(d.assay, d.gene)),
        padding: 0.4,
      }),
    [yMax, descendingGenesAndNumbers],
  );
  const xScale = useMemo(
    () =>
      scaleLinear<number>({
        range: [0, xMax - twoSidedBarMargin.left * (twoSidedBarMargin.rightQuantifier + 1)],
        round: true,
        // The 1.23/1.05 is a margin to the edge of graph to make the bars not end at the edge of the graph.
        domain: [xMinScale, maxNumber * (!is2Sided ? 1.23 : 1)],
      }),
    [xMax, maxNumber, xMinScale, is2Sided],
  );
  const xBarScaleFor2Sided = useMemo(
    () =>
      scaleLinear<number>({
        range: [0, xMax - twoSidedBarMargin.left * (twoSidedBarMargin.rightQuantifier + 1)],
        round: true,
        domain: [0, maxNumber],
      }),
    [xMax, maxNumber],
  );

  // We want overflow:visible, since if the values in the chart go beyond min or max it's better to show the values
  return (
    <svg width={width} height={height + yMarginForxAxisLegend} overflow="visible">
      <Group top={0} left={is2Sided ? twoSidedBarMargin.left : margins.horizontal}>
        <GridColumns
          left={is2Sided ? twoSidedBarMargin.left / 2 : 0}
          height={height - 34}
          scale={xScale}
          stroke={theme.colors.neutral300}
          numTicks={6}
        />
        {!is2Sided && <Line from={{ x: 0, y: 0 }} to={{ x: 0, y: height - 34 }} stroke={theme.colors.neutral300} />}
        <AxisBottom
          left={is2Sided ? twoSidedBarMargin.left / 2 : 0}
          scale={xScale}
          top={yMax}
          tickFormat={n =>
            metricMode === MetricMode.REDUCTION ? friendlyFoldChange(n.valueOf()) : friendlyCopyNumber(n.valueOf())
          }
          numTicks={6}
          hideTicks
          hideAxisLine
          tickLabelProps={tickLabelProps}
        />
        {descendingGenesAndNumbers.map(geneAndCopyNumber => (
          <Group left={0} key={`bar-${geneAndCopyNumber.assay}`}>
            <CopyNumberBar
              key={`bar-${geneAndCopyNumber.assay}`}
              genesAndNumbers={descendingGenesAndNumbers}
              geneAndCopyNumber={geneAndCopyNumber}
              metricMode={metricMode}
              scaleProperty={scaleProperty}
              is2Sided={is2Sided}
              xScale={xScale}
              yScale={yScale}
              xMax={xMax}
              handleMouseMove={handleMouseMove}
              xBarScaleFor2Sided={xBarScaleFor2Sided}
            />
          </Group>
        ))}

        {!is2Sided && (
          <AxisLeft
            scale={yScale}
            tickFormat={getGeneFromLabel}
            tickLabelProps={{ ...tickLabelProps, fontStyle: italic ? 'italic' : 'normal' }}
            hideTicks
            hideAxisLine
          />
        )}
      </Group>
      {tooltipData ? (
        <PlotTooltip {...tooltipProps}>
          <div>
            Gene: <b>{formatGeneMutationSuffixes(tooltipData.gene)}</b>
          </div>
          <div>
            Copy nr: <b>{tooltipData.copyNumber.toFixed()}</b>
          </div>
        </PlotTooltip>
      ) : (
        ''
      )}
    </svg>
  );
}

const margins = { vertical: 40, horizontal: 140 } as const;
const yMarginForxAxisLegend = 5 as const; // This is needed to prevent the bottom axis texts getting hidden
export const twoSidedBarMargin = {
  left: 10,
  rightQuantifier: 3,
} as const;
export const BarLabelThreshold = 50 as const;
export const tickLabelProps = {
  fontSize: 14,
  color: theme.colors.neutral700,
  fontWeight: theme.fontWeight.bold,
} as const;

export function getScaleLabel(assay: string, gene: string) {
  return `${assay}#${gene}`;
}

function getGeneFromLabel(label: string) {
  return label.split('#')[1];
}

function assertReductionData(
  genesAndCopyNumbers: GeneAndCopyNumber[],
): asserts genesAndCopyNumbers is Array<GeneAndCopyNumber & { reduction: number }> {
  if (genesAndCopyNumbers.some(d => d.reduction === undefined)) {
    throw new Error('All items must have reduction data when in reduction mode');
  }
}
