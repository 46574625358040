import { FormControl, FormHelperText, FormLabel, Input, Stack } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { ResistomapButton } from '@resistapp/client/components/buttons/resistomap-button';
import { RSecondTitle } from '@resistapp/client/components/headers/r-second-title';
import { FormView } from '@resistapp/client/components/shared/layout';
import { oldTheme } from '@resistapp/client/components/shared/old-styles';
import { MaybeApiError } from '@resistapp/client/components/states/error';
import { Loading } from '@resistapp/client/components/states/loading';
import { usePostProject } from '@resistapp/client/hooks/api';
import { useNavigateWithQuery } from '@resistapp/client/hooks/use-navigate-with-query';
import { parseSheetIdFromUrl } from '@resistapp/common/utils';
import { useEffect, useState } from 'react';

export function AnalyseView() {
  const navigate = useNavigateWithQuery();
  const { loading, error, send, data: sampling } = usePostProject();
  const [name, setName] = useState('');
  const [sheetLink, setSheetLink] = useState('');
  const [linkedProject, setLinkedProject] = useState<number>();

  useEffect(() => {
    if (sampling) {
      navigate(`/research/${sampling.id}`, false);
    }
  }, [sampling]);

  return (
    <FormView>
      <RSecondTitle>New Sampling Project</RSecondTitle>
      <Stack spacing={5} width="full">
        <FormControl isRequired isDisabled={loading}>
          <FormLabel htmlFor="name">Name</FormLabel>
          <Input
            id="name"
            name="name"
            value={name}
            onChange={event => {
              setName(event.target.value);
            }}
            autoFocus
            width="full"
          />
        </FormControl>
        <FormControl isDisabled={loading}>
          <FormLabel htmlFor="sheetLink">Lab sheet link</FormLabel>
          <Input
            id="sheetLink"
            name="sheetLink"
            value={sheetLink}
            onChange={event => {
              setSheetLink(event.target.value);
            }}
            width="full"
          />
          {sheetLink && !parseSheetIdFromUrl(sheetLink) && (
            <FormHelperText>Please copy from browser URL field</FormHelperText>
          )}
        </FormControl>

        <FormControl isDisabled={loading}>
          <FormLabel htmlFor="linkedProject">Project # for connecting to previous samples</FormLabel>
          <Input
            id="linkedProject"
            name="linkedProject"
            value={linkedProject}
            onChange={event => {
              setLinkedProject(parseInt(event.target.value, 10));
            }}
            type="number"
            width="full"
          />
          {linkedProject !== undefined && linkedProject <= 0 && (
            <FormHelperText>Please copy project number from the URL of a previous project page</FormHelperText>
          )}
        </FormControl>
        <br />
        <ResistomapButton
          onClick={() => {
            send({ name, sheetLink, linkedProject });
          }}
          isDisabled={loading || !name || !isValidSheetLink(sheetLink)}
        >
          {loading ? 'Analyzing...' : 'Analyze'}
        </ResistomapButton>
        <br />
        <MaybeApiError error={error} />
        {loading && <Loading />}
      </Stack>
    </FormView>
  );
}

export const Error = styled.div`
  margin-top: ${oldTheme.spacing.s};
  color: ${oldTheme.cautionRed};
`;

function isValidSheetLink(url: string) {
  return url.trim().startsWith('https://docs.google.com/spreadsheets');
}
