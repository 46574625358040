import { chain } from 'lodash';
import { FullSamplesByUID, Sample } from './types';

export function isSingleProject(samplesUIDs: string[]) {
  const numProjects = chain(samplesUIDs).map(extractProjectNumber).uniq().value().length;
  return numProjects <= 1;
}

export function assertSingleProject(samplesByUID: FullSamplesByUID) {
  if (!isSingleProject(Object.keys(samplesByUID))) {
    throw Error('Multi-project downloads are not supported.');
  }
}

function parseSampleUID(sampleUID: string) {
  // Sample UID format: <samplingId>-<sampleNum>, eg. 123-456
  const splitted = sampleUID.split('-');
  const projectNum = parseInt(splitted[0], 10);
  const sampleNum = parseInt(splitted[1], 10);
  if (splitted.length !== 2 || !projectNum || !sampleNum) {
    throw new Error(`Invalid sample UID: ${sampleUID}`);
  }
  return { projectNum, sampleNum };
}

export function extractSampleNumber(sampleUID: string) {
  const { sampleNum } = parseSampleUID(sampleUID);
  return sampleNum;
}

export function extractProjectNumber(sampleUID: string) {
  const { projectNum } = parseSampleUID(sampleUID);
  return projectNum;
}

export function getSampleUID(sample: Pick<Sample, 'projectId' | 'number'>) {
  return `${sample.projectId}-${sample.number}`;
}
