import {
  PlacementWithLogical,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
} from '@chakra-ui/react';
import styled from '@emotion/styled';
import { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { CSSProperties, useEffect, useRef } from 'react';
import { tutorials } from '../../hooks/use-tutorial';
import { oldTheme } from '../shared/old-styles';
import { theme } from '../shared/theme';

interface Props {
  children: React.ReactNode;
  style?: CSSProperties;
  header?: string | React.ReactNode;
  placement?: PlacementWithLogical;
  iconSize?: SizeProp;
  isForceOpen?: boolean;
  highlight?: boolean;
  footer?: React.ReactNode;
}

export function InfoTooltipIcon(props: Props) {
  const icon = faQuestionCircle as IconProp;
  const iconSize = props.iconSize || 'lg';
  const popoverRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (props.isForceOpen) {
      tutorials.overview.scrollToStep(popoverRef);
    }
  }, [props.isForceOpen]);

  return (
    <Root
      style={{
        padding: oldTheme.spacing.xs,
        ...props.style,
      }}
      className="download_info-tooltip-icon"
    >
      <Popover trigger="hover" placement={props.placement} isOpen={props.isForceOpen || undefined}>
        <PopoverTrigger>
          <HoverIcon icon={icon} size={iconSize} style={{ color: props.isForceOpen ? 'black' : undefined }} />
        </PopoverTrigger>
        <PopoverContent
          ref={popoverRef}
          style={{ minWidth: 'min-content' }}
          rootProps={{
            style: {
              padding: 10,
              ...theme.shadows.normal,
              filter: props.highlight ? theme.shadows.strong.filter : '',
              border: props.highlight ? theme.colors.maroon : '',
            },
          }}
        >
          <PopoverArrow />
          {props.header ? <PopoverHeader style={{ paddingBottom: 10 }}>{props.header}</PopoverHeader> : null}
          <PopoverBody style={{ padding: oldTheme.spacing.s, paddingBottom: 0 }}>{props.children}</PopoverBody>
          {props.footer && <PopoverFooter style={{ paddingTop: 20, borderTop: 0 }}>{props.footer}</PopoverFooter>}
        </PopoverContent>
      </Popover>
    </Root>
  );
}

const HoverIcon = styled(FontAwesomeIcon)`
  color: ${oldTheme.slightGray};
  cursor: pointer;
  &:hover {
    color: black;
  }
`;

const Root = styled.div`
  display: inline-block;
  align-items: center;
  padding-left: ${oldTheme.spacing.s};
  padding-right: ${oldTheme.spacing.s};
`;
