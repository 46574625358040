import { fetchOrThrow } from '@resistapp/client/hooks/api';
import { User } from '@resistapp/common/types';
import { ResistomapSelect } from '../forms/resistomap-select';

interface Option {
  label: string;
  value: number;
}

export function AddUserAccessDropdown({ users, projectId }: { users: User[]; projectId: number }) {
  const options: Option[] = users.map(user => ({
    label: `${user.email} (${user.firstName} ${user.lastName})`,
    value: user.id,
  }));
  return (
    <div>
      <span style={{ fontWeight: 701 }}>Grant access to:</span>
      <ResistomapSelect
        placeholder="-"
        size="md"
        isMulti={false}
        isSearchable={true}
        options={options}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onChange={async selectedOption => {
          if (selectedOption && selectedOption.value) {
            try {
              const { status } = await fetchOrThrow('POST', `/user/${selectedOption.value}/project/${projectId}`);
              if (status === 200) {
                window.location.reload();
              } else {
                alert(`Adding access failed with status ${status}`);
              }
            } catch (e) {
              alert('Adding access failed');
            }
          }
        }}
      />
    </div>
  );
}
