import { defaultStyles, useTooltip } from '@visx/tooltip';
import { UseTooltipInPortal } from '@visx/tooltip/lib/hooks/useTooltipInPortal';
import { TooltipProps } from '@visx/tooltip/lib/tooltips/Tooltip';
import React, { ReactNode, useCallback } from 'react';
import { RectReadOnly } from 'react-use-measure';
import { theme } from '../shared/theme';
export const defaultTooltipStyles = {
  ...defaultStyles,
  minWidth: 60,
  color: 'black',
};

export interface PlotTooltipProps {
  tooltipOpen: boolean;
  TooltipInPortal: React.FC<TooltipProps>;
  tooltipLeft: number;
  tooltipTop: number;
}

interface Props extends PlotTooltipProps {
  backgroundColor?: string;
  children?: ReactNode;
  style?: React.CSSProperties;
}

export interface TooltipStuff {
  containerBounds: RectReadOnly;
  TooltipInPortal: React.FC<PlotTooltipProps>;
}

export function PlotTooltip({
  tooltipOpen,
  TooltipInPortal,
  tooltipLeft,
  tooltipTop,
  backgroundColor,
  children,
  style,
}: Props) {
  if (!tooltipOpen) {
    return null;
  }
  return (
    <TooltipInPortal
      key={Math.random()} // needed for bounds to update correctly
      left={tooltipLeft}
      top={tooltipTop}
      style={{
        ...defaultTooltipStyles,
        zIndex: theme.zIndexes.tooltipZ,
        backgroundColor: backgroundColor || theme.colors.neutral50,
        ...style,
      }}
    >
      {children}
    </TooltipInPortal>
  );
}

export function usePlotTooltip<Data>(tooltipStuff: UseTooltipInPortal) {
  const { containerBounds, TooltipInPortal } = tooltipStuff;
  const {
    tooltipData,
    showTooltip,
    hideTooltip,
    updateTooltip,
    tooltipOpen,
    tooltipLeft = 0,
    tooltipTop = 0,
  } = useTooltip<Data | undefined>({
    tooltipOpen: false,
    tooltipData: undefined,
  });

  const handleMouseMove = useCallback(
    (event: React.MouseEvent | React.TouchEvent, newTooltipData: Data | undefined, enter?: boolean) => {
      if (newTooltipData) {
        const containerX = ('clientX' in event ? event.clientX : 0) - containerBounds.left;
        const containerY = ('clientY' in event ? event.clientY : 0) - containerBounds.top;
        if (enter) {
          showTooltip({
            tooltipLeft: containerX,
            tooltipTop: containerY,
            tooltipData: newTooltipData,
          });
        } else {
          updateTooltip({
            tooltipData: newTooltipData,
            tooltipLeft: containerX,
            tooltipTop: containerY,
            tooltipOpen: true,
          });
        }
      } else {
        hideTooltip();
      }
    },
    [containerBounds],
  );

  return {
    handleMouseMove,
    tooltipData,
    hideTooltip,
    tooltipProps: {
      tooltipOpen: tooltipOpen && !!tooltipData,
      TooltipInPortal,
      tooltipLeft,
      tooltipTop,
    } satisfies PlotTooltipProps,
  };
}
