import styled from '@emotion/styled';
import { useOverviewContext } from '@resistapp/client/contexts/use-overview-context/use-overview-context';
import chartIconSrc from '../../assets/icon/charts.svg';
// import fileIconSrc from '../../assets/icon/download.svg';
import { ActionButton } from '../buttons/action-button';

export function OverviewActions() {
  const { data } = useOverviewContext();
  // const isMobile = useBreakpointValue({ base: true, md: false });

  const buttonData = [
    {
      text: 'Analyse in detail',
      alt: 'different kind of charts',
      src: chartIconSrc,
      link: `/research/${data?.id}`,
      size: '32px',
    },
  ];
  return (
    <Container>
      <ActionButton
        text={buttonData[0].text}
        src={buttonData[0].src}
        alt={buttonData[0].alt}
        link={buttonData[0].link}
      />
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  gap: 8px;
`;
