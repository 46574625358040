import { Box } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { useSampleDataContext } from '@resistapp/client/contexts/sample-data-context';
import { OverviewProcessDatum } from '@resistapp/client/data-utils/plot-data/build-overview-line-data';
import { getNumDetectedAndAnalysedAssays } from '@resistapp/client/data-utils/plot-data/process-overview-line-datum';
import { getMetricAndLevel } from '@resistapp/client/utils/metric-utils';
import { MinorTarget } from '@resistapp/common/assays-temp-96-gene-minor-targets';
import { WithAbundances } from '@resistapp/common/statistics/resistance-index';
import { MetricMode } from '@resistapp/common/types';
import { isNil } from 'lodash';
import { MouseEventHandler } from 'react';
import { MarkerPointerArrow } from '../../arrows/arrow';
import { CloseIconCircle } from '../../icons/close-icon';
import { DownRightIcon, RightIcon, UpRightIcon } from '../../icons/diaconal-arrow-icons';
import { EnvTypeIcon } from '../../icons/env-type-icons';
import { getChangeIndicatorColor, OverviewMetricLabel } from '../../shared/overview-metric-label';
import { theme } from '../../shared/theme';
import { getMarkerOffset } from './marker-utils';

export type Positions = 'top' | 'bottom' | 'left' | 'right' | 'center' | 'chart';
const directionStyles = {
  bottom: {
    position: 'absolute',
    flexDirection: 'column-reverse',
  },
  top: {
    position: 'absolute',
    flexDirection: 'column',
  },
  center: {
    position: 'absolute',
    flexDirection: 'column',
  },
  left: {
    position: 'absolute',
    flexDirection: 'row',
  },
  right: {
    position: 'absolute',
    flexDirection: 'row-reverse',
  },
  chart: {
    position: 'absolute',
    flexDirection: 'column',
  },
} as const satisfies { [index in Positions]: { position: string; flexDirection: string } };

const maximumEnvironmentNameLength = 8;

interface Props {
  overviewDatum: OverviewProcessDatum;
  position: Positions;
  metricMode: MetricMode;
  pinned?: boolean;
  onClick?: (close?: boolean) => void;
  onMouseEnter?: MouseEventHandler<HTMLDivElement>;
}

export function ProcessMarker({
  onMouseEnter,
  overviewDatum,
  position,
  metricMode,
  pinned = false,
  onClick = () => {},
}: Props) {
  const { queryFilters } = useSampleDataContext();
  const hasPreciseCoordinates = Boolean(overviewDatum.lat);

  const [metric, metricLevel] = getMetricAndLevel(
    overviewDatum.abundances,
    overviewDatum.afterAbundances,
    queryFilters.filters.selectedTargets,
    metricMode,
  );
  const [metricAfter] = getMetricAndLevel(
    overviewDatum.afterAbundances || [],
    overviewDatum.afterAbundances,
    queryFilters.filters.selectedTargets,
    metricMode,
  );

  const [metricBefore] = getMetricAndLevel(
    overviewDatum.abundances,
    overviewDatum.abundances,
    queryFilters.filters.selectedTargets,
    metricMode,
  );

  const changeInMetric =
    isNil(metricAfter) || isNil(metricBefore)
      ? undefined
      : metricAfter === metricBefore
        ? 0
        : metricAfter > metricBefore
          ? 1
          : -1;

  const { width, height } = getMarkerSize(overviewDatum, metricMode);
  const offset = getMarkerOffset(position, width, height, !hasPreciseCoordinates);
  const style = {
    ...directionStyles[position],
    top: `${offset.y}px`,
    left: `${offset.x}px`,
  };
  const { detectedCount: detectedPathogenCount } = getNumDetectedAndAnalysedAssays(
    overviewDatum,
    MinorTarget.PATHOGEN,
    metricMode,
  );

  return (
    <ProcessMarkerContainer
      onMouseEnter={onMouseEnter}
      width={width}
      height={height}
      style={{
        display: 'flex',
        ...style,
      }}
    >
      {pinned && (
        <CloseIconCircle
          onClick={() => {
            onClick(true);
          }}
          style={{
            zIndex: theme.zIndexes.closeIcon,
          }}
        />
      )}
      <ProcessMarkerBox
        className="ProcessMarkerBox"
        pinned={pinned}
        onClick={() => {
          onClick();
        }}
      >
        <IndexNameContainer>
          {overviewDatum.environment.subtype && (
            <MarkerTitleContainer>
              <EnvTypeIcon
                envSubtype={overviewDatum.environment.subtype}
                style={{
                  paddingRight: theme.oldSpacing.xxs,
                  paddingLeft: theme.oldSpacing.xxs,
                }}
              />
            </MarkerTitleContainer>
          )}
          {overviewDatum.environment.name.slice(0, maximumEnvironmentNameLength)}
          {overviewDatum.environment.name.length > maximumEnvironmentNameLength && '...'}
        </IndexNameContainer>
        <ARGIContainer>
          {metricMode === MetricMode.RISK && changeInMetric !== undefined && (
            <BeforeContainer change={changeInMetric} metricMode={metricMode}>
              <ResistanceIndexBeforeContainer id="ResistanceIndexBeforeContainer">
                {metricBefore !== null ? Math.round(metricBefore).toString() : ''}
              </ResistanceIndexBeforeContainer>
              <ArrowContainer>
                {changeInMetric < 0 ? (
                  <DownRightIcon style={{ color: getChangeIndicatorColor(-1) + 'A0' }} />
                ) : changeInMetric > 0 ? (
                  <UpRightIcon style={{ color: getChangeIndicatorColor(1) + 'A0' }} />
                ) : (
                  <RightIcon style={{ color: getChangeIndicatorColor(0) + 'A0' }} />
                )}
              </ArrowContainer>
            </BeforeContainer>
          )}
          <OverviewMetricLabel
            id="process-marker-metric-label"
            level={metricLevel}
            metricMode={metricMode}
            metric={metricMode === MetricMode.RISK ? metricAfter ?? metric : metric}
          />
          {changeInMetric !== undefined && metricMode === MetricMode.ARGI && (
            <AfterContainer change={changeInMetric}>
              <ArrowContainer>
                {changeInMetric < 0 ? (
                  <DownRightIcon style={{ color: getChangeIndicatorColor(-1) + 'A0' }} />
                ) : changeInMetric > 0 ? (
                  <UpRightIcon style={{ color: getChangeIndicatorColor(1) + 'A0' }} />
                ) : (
                  <RightIcon style={{ color: getChangeIndicatorColor(0) + 'A0' }} />
                )}
              </ArrowContainer>
              <ResistanceIndexAfterContainer id="ResistanceIndexAfterContainer">
                {metricAfter !== null ? metricAfter.toFixed(1) : ''}
              </ResistanceIndexAfterContainer>
            </AfterContainer>
          )}
        </ARGIContainer>
        {detectedPathogenCount > 0 && (
          <PathogenContainer>
            <PathogensNumber>{detectedPathogenCount}</PathogensNumber>
            <PathogensText>PATHOGENS</PathogensText>
          </PathogenContainer>
        )}
      </ProcessMarkerBox>
      {overviewDatum.lat && position !== 'chart' && position !== 'center' && (
        <MarkerPointerArrow
          direction={position === 'top' ? 'down' : position === 'bottom' ? 'up' : position}
          borderColor={pinned ? theme.colors.blue500 : undefined}
        />
      )}
    </ProcessMarkerContainer>
  );
}

export function getMarkerSize(marker: WithAbundances | undefined, metricMode: MetricMode, showPathogens = true) {
  const { detectedCount } = marker
    ? getNumDetectedAndAnalysedAssays(marker, MinorTarget.PATHOGEN, metricMode)
    : { detectedCount: 0 };
  return {
    width: 104,
    height: 71 + (detectedCount && showPathogens ? 23 : 0),
    chartOffset: 30,
  };
}

const ProcessMarkerContainer = styled.div<{ width: number; height: number }>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: ${props => props.height}px;
  width: ${props => props.width}px;
  cursor: pointer;
  filter: ${theme.shadows.normal.filter};
  font-family: ${theme.fontFamily.baseVariable};
`;

const ProcessMarkerBox = styled.div<{ pinned?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: ${theme.spacing[0.5]} 6px 0;
  border-radius: ${theme.borders.radius.small};
  background-color: white;
  border: ${props => (props.pinned ? theme.borders.selected : 'none')};
  color: ${theme.colors.bodyGray};
`;

const AfterContainer = styled.div<{ change: number }>`
  min-width: 32px;
  display: flex;
  flex-direction: ${props => (props.change === 1 ? 'column-reverse' : 'column')};
  align-items: center; // Changed from flex-start to center
  padding-left: ${theme.spacing[1]};
`;

const ARGIContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding-top: ${theme.spacing[0.5]};
`;

const PathogenContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const MarkerTitleContainer = styled.div<{ pinned?: boolean }>`
  font-size: ${theme.fontSize.small};
  font-weight: ${theme.fontWeight.heavy};
`;

const ArrowContainer = styled.div`
  width: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const IndexNameContainer = styled.div`
  min-height: 22px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  color: ${theme.colors.neutral700};
  font-size: ${theme.fontSize.small};
  font-weight: ${theme.fontWeight.heavy};
`;

const ResistanceIndexAfterContainer = styled.div`
  display: flex;
  justify-content: center; // Added to center the text
  font-size: ${theme.fontSize.medium};
  font-weight: ${theme.fontWeight.heavy};
  color: ${theme.colors.neutral500};
  min-height: 20px;
  width: 100%; // Added to ensure full width
`;

const PathogensNumber = styled(Box)`
  font-weight: ${theme.fontWeight.heavy};
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: 'ss01' on;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: ${theme.colors.red600};
  color: white;
  font-size: 10px;
`;

const PathogensText = styled(Box)`
  padding-left: ${theme.spacing[1]};
  font-size: ${theme.fontSize.extraSmall};
`;

const BeforeContainer = styled.div<{ change: number; metricMode: MetricMode }>`
  min-width: 32px;
  display: flex;
  flex-direction: ${props =>
    props.metricMode === MetricMode.RISK
      ? props.change === 1
        ? 'column-reverse'
        : 'column'
      : props.change === 1
        ? 'column-reverse'
        : 'column'};
  align-items: center;
  padding-right: ${theme.spacing[1]};
`;

const ResistanceIndexBeforeContainer = styled.div`
  display: flex;
  justify-content: center; // Added to center the text
  font-size: ${theme.fontSize.medium};
  font-weight: ${theme.fontWeight.heavy};
  color: ${theme.colors.neutral500};
  min-height: 20px;
  width: 100%; // Added to ensure full width
`;
