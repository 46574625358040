import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CSSProperties } from 'react';

interface PlusIconProps {
  style?: CSSProperties;
}

export function PlusIcon({ style }: PlusIconProps) {
  return <FontAwesomeIcon style={style} icon={faPlus as IconProp} />;
}
