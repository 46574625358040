import styled from '@emotion/styled';
import { oldTheme } from '@resistapp/client/components/shared/old-styles';
import { useDropzone } from 'react-dropzone';

const Zone = styled.div`
  flex: 1;
  margin-top: 36px;
  padding-left: 36px;
  padding-right: 36px;
  max-width: 512px;
  display: flex;
  color: white;
  background-color: ${oldTheme.slightGray};
`;

interface Props {
  onDrop: (acceptedFiles: File[]) => void;
}

export function FileDropzone(props: Props) {
  const { getRootProps, getInputProps } = useDropzone(props);
  return (
    <Zone {...getRootProps()}>
      <input {...getInputProps()} />
      <p>🗳 Drag &apos;n&apos; drop analyses</p>
    </Zone>
  );
}
