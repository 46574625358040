import { friendlyFoldChange } from '@resistapp/common/friendly';
import { MetricMode } from '@resistapp/common/types';
import { TickRendererProps } from '@visx/axis';
import { Text } from '@visx/text';
import { format } from 'date-fns';
import { positioning } from './chart-styles';

export function prepareBottomAxisTick(firstDate: Date) {
  return function BottomAxisText(data: TickRendererProps) {
    const formattedFirstDate = format(firstDate, 'MMM');
    const year = format(new Date(firstDate), 'yyyy');
    const formattedValue =
      data.formattedValue === format(new Date(data.x), 'LLLL') ? format(new Date(data.x), 'LLL') : data.formattedValue;
    return (
      <Text
        y={data.y + positioning.bottomLegend.textY}
        x={data.x + positioning.bottomLegend.textX}
        textAnchor="middle"
        style={{ fontWeight: '500' }}
      >
        {`${formattedValue} ${data.formattedValue === formattedFirstDate ? year : ''}`}
      </Text>
    );
  };
}

export function perpareLeftAxisTick(metricMode: MetricMode) {
  return function LeftAxisText(data: TickRendererProps) {
    // The data.formattedValue is a string with a unicode minus sign, which causes the value to be NaN when converted to a number
    const normalizedValue = data.formattedValue?.replace('−', '-');
    // We don't show the uneven numbers on the left legends, but show the lines
    if (!normalizedValue || Math.abs(Number(normalizedValue)) % 1 !== 0) {
      return null;
    }

    return (
      <Text y={data.y + positioning.leftLegend.textY} x={data.x + positioning.leftLegend.textX} textAnchor="end">
        {metricMode === MetricMode.REDUCTION
          ? friendlyFoldChange(Number(normalizedValue)).replace('-', '−')
          : data.formattedValue}
      </Text>
    );
  };
}
