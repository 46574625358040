import { GeneGrouping, Targets, allGeneGroups } from '@resistapp/common/assays';
import { EnvironmentType } from '@resistapp/common/environment-types';
import { DEFAULT_GENE_GROUPING } from '../data-utils/gene-groups';

export enum QueryParams {
  GENE_GROUP_GROUPING = 'ggrouping',
  GENE_GROUPS = 'ggroups',
  ENVIRONMENT_TYPE = 'etype',
  ENVIRONMENTS = 'environments',
  ABSOLUTE_MODE = 'absoluteMode',
}

export enum OtherParams {
  VERIFICATION_CODE = 'code',
}

export function getGeneGroupingParams(searchParams: URLSearchParams) {
  const geneGroupParam = searchParams.get(QueryParams.GENE_GROUP_GROUPING);
  return isGeneGrouping(geneGroupParam) ? geneGroupParam : DEFAULT_GENE_GROUPING;
}

export function getGeneGroupsParams(searchParams: URLSearchParams) {
  const plainGeneGroupsParam = splitQueryParamArray(searchParams.get(QueryParams.GENE_GROUPS), true) ?? [];
  // Backwards compatibility for typo in antibiotic name
  const correctedGeneGroups = plainGeneGroupsParam.map(g => {
    if (g === 'Beta Lactam' || g === 'Beta+Lactam' || g === 'Beta%20Lactam') {
      return Targets['Beta-Lactam'];
    }
    return g;
  });
  return correctedGeneGroups.filter(g => g);
}

export function getEnvironmentTypeParams(searchParams: URLSearchParams) {
  const plainParam = searchParams.get(QueryParams.ENVIRONMENT_TYPE);
  // Backwards compatibility for old env type strings
  const environmentTypesParam =
    plainParam === 'Waste water' || plainParam === 'Waste+water' || plainParam === 'Waste%20water'
      ? EnvironmentType.WASTEWATER
      : plainParam === 'Surface water' || plainParam === 'Surface+water' || plainParam === 'Surface%20water'
        ? EnvironmentType.NATURAL_WATER
        : plainParam?.toUpperCase(); // 'Soil' etc
  if (!environmentTypesParam) {
    return undefined;
  }
  const environmentTypes = splitQueryParamArray(environmentTypesParam) as EnvironmentType[];
  const validEnvironmentTypes = environmentTypes.filter(e => isEnvironmentType(e));
  return validEnvironmentTypes.length ? validEnvironmentTypes : undefined;
}

export function getEnvironmentsParams(searchParams: URLSearchParams, envNamesHaveComma: boolean) {
  const envsParamText = searchParams.get(QueryParams.ENVIRONMENTS);
  const environmentsParam = splitQueryParamArray(envsParamText, !envNamesHaveComma) ?? [];
  return environmentsParam.filter(g => !!g);
}

export function getAbsoluteModeParams(searchParams: URLSearchParams) {
  const absoluteModeParam = searchParams.get(QueryParams.ABSOLUTE_MODE);
  return absoluteModeParam ? absoluteModeParam === 'true' : false;
}

function isGeneGrouping(param?: string | null): param is GeneGrouping {
  return Boolean(param && param in allGeneGroups);
}

function isEnvironmentType(param?: string | null): param is EnvironmentType {
  return Boolean(param && Object.values(EnvironmentType as Record<string, string>).includes(param));
}

export function mutateEnvironmentRelatedSearchParams(searchParams: URLSearchParams, types: EnvironmentType[]) {
  searchParams.delete(QueryParams.ENVIRONMENTS);

  if (!types.length) {
    searchParams.delete(QueryParams.ENVIRONMENT_TYPE);
  } else {
    const joinedTypes = joinQueryParamArray(types);
    searchParams.set(QueryParams.ENVIRONMENT_TYPE, joinedTypes);
  }
}

export function mutateSearchParamsWithSelection(
  paramID: string,
  searchParams: URLSearchParams,
  wholeGroup: string[],
  next: string[],
  only = false,
) {
  // If the user wants to select only one value, we remove the old selections
  if (only) {
    searchParams.delete(paramID);
  }

  const filteredList = next.filter(n => wholeGroup.includes(n));
  // If the next group is the same as all groups, we don't add ALL the groups to the
  // URL, but instead empty URL === default to all groups selected
  const selectedEnvironments =
    JSON.stringify(next) !== JSON.stringify(wholeGroup) ? joinQueryParamArray(filteredList) : '';

  if (!selectedEnvironments) {
    searchParams.delete(paramID);
  } else {
    searchParams.set(paramID, selectedEnvironments);
  }
}

export function getVerificationCode(searchParams: URLSearchParams) {
  return searchParams.get(OtherParams.VERIFICATION_CODE);
}

// Note: changing this will break old URLs
// Split query param arrays with a human readable string unlikely to be found (though not forbidden) in env names
export const queryParamArraySeparator = '_-.-_';
export function splitQueryParamArray(param?: string | null, allowLegacyCommaSeparator?: boolean) {
  if (param && allowLegacyCommaSeparator && !param.includes(queryParamArraySeparator) && param.includes(',')) {
    return param.split(',');
  }
  return param?.split(queryParamArraySeparator);
}

export function joinQueryParamArray(param: string[]) {
  return param.join(queryParamArraySeparator);
}
