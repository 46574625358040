import { calculateMarkerBounds } from '@resistapp/client/components/map/markers/marker-utils';
import {
  activateColoredRegions,
  deactivateColoredRegions,
  getBoundingBoxForEnvironment,
} from '@resistapp/client/components/map/overview-map-utils';
import { positioning } from '@resistapp/client/components/plots/trendchart/chart/chart-styles';
import { getTimeScale } from '@resistapp/client/components/plots/trendchart/chart/scales';
import { useOverviewTooltip } from '@resistapp/client/components/plots/trendchart/chart/use-overview-tooltip';
import {
  OverviewDatum,
  OverviewLineData,
  OverviewProcessDatum,
  buildOverviewLineData,
  getProjectOverviewEnvironmentType,
  getSupportedSamplesWithConsistentAdminLevels,
  overviewEnvironmentTypesInPrioOrder,
} from '@resistapp/client/data-utils/plot-data/build-overview-line-data';
import { useCountries } from '@resistapp/client/hooks/api';
import { QueryFilters } from '@resistapp/client/hooks/use-query-filters/use-query-filters';
import { ApiError } from '@resistapp/client/utils/error';
import { MapSourceWithLevel, getMapSourceFromAdminLevel } from '@resistapp/client/utils/map-sources';
import { Pooling, PoolingMode, PoolingType } from '@resistapp/common/api-types';
import { Target, allGeneGroups, isAntibiotic } from '@resistapp/common/assays';
import {
  areSamplesAnalysedWithOneHealthPanel,
  countMetricGenes,
} from '@resistapp/common/assays-temp-96-gene-minor-targets';
import { EnvironmentType } from '@resistapp/common/environment-types';
import {
  DEFAULT_END_INTERVAL,
  DEFAULT_START_INTERVAL,
  ensureUtcMonth,
  utcStartOfNextMonth,
} from '@resistapp/common/friendly';
import { getAdminAreaKey } from '@resistapp/common/pool-samples';
import { arePropertiesPresentInAllFullSamples } from '@resistapp/common/typeguards';
import {
  AdminArea,
  AdminLevelKey,
  Continent,
  FullProject,
  FullSample,
  MetricMode,
  getAllOriginalEnvironmentNames,
} from '@resistapp/common/types';
import { Dictionary, chain, get, intersection, isNil, keys, mapValues } from 'lodash';
import { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { LngLatBoundsLike, LngLatLike, MapRef, ViewStateChangeEvent } from 'react-map-gl';
import { buildMapData, getUniqueMonths } from '../../data-utils/plot-data/build-map-data';
import { useSampleDataContext } from '../sample-data-context';

export interface OverviewContextData {
  hasPreparedData: boolean;

  trendData: OverviewLineData | undefined; // environment time series (shortcut for trendDataByLevel[adminLevel])
  mapData: OverviewDatum[] | undefined; // latest data point from each envirnoment in the time series (shortcut for mapDataByLevel[adminLevel])
  selectedSiteDatum: OverviewDatum | undefined; // A single site selected for site view
  selectedOrHoveredAreaOrSiteEnvId: number | undefined; // Environment id (either area or site) that is currently hovered over, or selected in site view
  setSelectedOrHoveredAreaOrSiteEnvId: (id: number | undefined) => void;
  continent: Continent | undefined;
  loading: boolean;
  error: Error | ApiError | null;
  shownAdminLevel: number | null | undefined;
  selectedAntibiotic: Target | undefined;
  handleZoom: (e: ViewStateChangeEvent) => void;
  data: FullProject | undefined;
  activeMapSource: MapSourceWithLevel | undefined;
  setMapRef: (mapRef: MapRef) => void;
  mapRef: MapRef | null;
  zoom: number | undefined;
  siteCount: number | undefined;
  samplingPointsCount: number | undefined;
  isOneHealthProject: boolean | undefined;
  metricGeneCnt: number | undefined;
  zoomAndCenter: (zoomOrBounds: { zoom: number; center: LngLatLike } | { bounds: LngLatBoundsLike }) => void;

  previousAdminAreasLifo: AdminArea[];
  // If nextAdminArea is null, it means we selected site, if it was undefined, it means we un-selected project
  changeZoomedAdminArea: (nextAdminArea: AdminArea | null | undefined) => void;
  setMapLoaded: (mapLoaded: boolean) => void;
  topLevelMapData: OverviewProcessDatum | undefined;
  zoomedMapData: OverviewProcessDatum | undefined;
  trenchartTooltip: {
    setTrendChartSize: (size: { width: number; height: number }) => void;
    trendChartSize: { width: number; height: number };
    mouseMoveHandler: (data: OverviewDatum[], event: React.MouseEvent<SVGElement>) => void;
    mouseClickHandler: (data: OverviewDatum) => void;
    onMouseLeave: () => void;
    onMouseEnter: () => void;
    TooltipComponentForARGIMarker: () => JSX.Element | null;
    TooltipComponentForRiskScore: () => JSX.Element | null;
    TooltipComponentForReduction: () => JSX.Element | null;
    hideTooltip: () => void;
  };
  setTrendLineRef: (ref: React.MutableRefObject<HTMLDivElement | null>) => void;

  metricMode: MetricMode;
  notAvailableReason: string | null;
  availableMonths: string[];
  setMonth: (date: Date | null) => void;
  selectedMonth: Date | null;
}

type DataForCallback = {
  trendLineRef: React.MutableRefObject<HTMLDivElement | null> | undefined;
  shownAdminLevel: number | null | undefined;
  selectedOrHoveredAreaOrSiteEnvId: number | undefined;
  positionTooltip: (data: OverviewDatum) => void;
  oldBoundingBox: string;
};

interface ProviderProps {
  children: React.ReactNode;
  metricMode: MetricMode;
}

const OverviewContext = createContext<OverviewContextData | undefined>(undefined);
export function OverviewContextProvider({ children, metricMode }: ProviderProps) {
  // DATA LOADING
  const { data, queryFilters, loading, error } = useSampleDataContext();
  const { data: countryByAlpha3 } = useCountries();

  // REFS
  const mapDataUpdateInvocationCnt = useRef(0);
  const queryFiltersRef = useRef<QueryFilters['filters'] | null>(null);
  const dataRef = useRef(data);
  const [oldBoundingBox, setOldBoundingBox] = useState<string>('');
  const dataForCallback = useRef<DataForCallback | null>(null);

  const [mapRef, setMapRef] = useState<MapRef | null>(null);
  const [trendLineRef, setTrendLineRef] = useState<React.MutableRefObject<HTMLDivElement | null>>();

  // INTERNAL STATE

  // Admin level and site selection state
  const [levelsWithZoomableAreas, setLevelsWithZoomableAreas] = useState<number[]>([]); // Ascending zoomable levels (from highest up level to the most detailed that still hides more than one site in some area)
  const [zoomedAdminArea, setZoomedAdminArea] = useState<AdminArea | null | undefined>(); // null means no admin level pooling (individual site samples)
  const [shownAdminLevel, setShownAdminLevel] = useState<number | null | undefined>(); // The rendered pooling level. This is the next level from zoomedAdminArea.level in levelsWithZoomableAreas, or null if there is not next level (individual sites are shown)
  const [previousAdminAreasLifo, setPreviousAdminAreasLifo] = useState<AdminArea[]>([]);
  const [selectedOrHoveredAreaOrSiteEnvId, setSelectedOrHoveredAreaOrSiteEnvId] = useState<number>();
  const [availableMonths, setAvailableMonths] = useState<string[]>([]);

  // Map state
  const [zoom, setZoom] = useState<number>();
  const [activeMapSource, setActiveMapSource] = useState<MapSourceWithLevel | undefined>();
  const [mapLoaded, setMapLoaded] = useState(false);

  // Data
  const [supportedSamples, setSupportedSamples] = useState<FullSample[] | undefined>();
  const [trendDataByLevel, setTrendDataByLevel] = useState<Dictionary<OverviewDatum[][]> | undefined>();
  const [mapDataByLevel, setMapDataByLevel] = useState<Dictionary<OverviewDatum[]> | undefined>();
  const [trendData, setTrendData] = useState<OverviewDatum[][] | undefined>();
  const [mapData, setMapData] = useState<OverviewDatum[] | undefined>();

  // Project metadata
  const [isOneHealthProject, setIsOneHealthProject] = useState<boolean>();
  const [metricGeneCnt, setMetricGeneCnt] = useState<number>();
  const [continent, setContinent] = useState<Continent | undefined>();

  // Rendering helpers
  const [trendChartSize, setTrendChartSize] = useState({ width: 0, height: 0 });
  const [notAvailableReason, setNotAvailableReason] = useState<string | null>(null);
  const graphWidth = trendChartSize.width - positioning.margin.left - positioning.margin.right + 1;
  const timeScale = getTimeScale(trendData, graphWidth);

  // Single site view
  const singleSiteSelected =
    queryFilters.filters.selectedEnvironmentNamesOrdered.length === 1 ||
    queryFilters.filters.selectedEnvironmentNamesOrdered.length === 2;
  const selectedEnvNames =
    // We aren't zoomed in an andmin area or showing admin levels and there is only one site (selected 1 or 2 envs)
    zoomedAdminArea === null && shownAdminLevel === null && singleSiteSelected
      ? queryFilters.filters.selectedEnvironmentNamesOrdered
      : [];
  const selectedSiteDatum = selectedEnvNames.length
    ? mapData?.filter(d => selectedEnvNames.includes(d.environment.name))[0]
    : undefined;
  const selectedSiteName = selectedSiteDatum?.environment.name;

  const selectedAntibiotic =
    queryFilters.filters.selectedTargets.length === 1 ? queryFilters.filters.selectedTargets[0] : undefined;

  const updateTooltipPositionOnChartResize = useCallback(() => {
    if (!dataForCallback.current || !dataForCallback.current.trendLineRef?.current) {
      return;
    }

    const globalRef = dataForCallback.current;
    const currentBoundingBox = JSON.stringify(globalRef.trendLineRef?.current?.getBoundingClientRect());
    if (currentBoundingBox !== globalRef.oldBoundingBox) {
      const correctAdminLevel = globalRef.shownAdminLevel || 'null';
      const newTrendData = trendDataByLevel?.[correctAdminLevel];

      if (globalRef.selectedOrHoveredAreaOrSiteEnvId && newTrendData) {
        const selectedTrendData = newTrendData
          .flat()
          .find(dataLocal => dataLocal.environment.id === globalRef.selectedOrHoveredAreaOrSiteEnvId);
        setOldBoundingBox(currentBoundingBox);
        selectedTrendData && globalRef.positionTooltip(selectedTrendData);
      }
    }
  }, [dataForCallback, trendDataByLevel, setOldBoundingBox]);

  const trendLineRefCurrent = trendLineRef?.current;
  useEffect(() => {
    if (!trendLineRefCurrent) {
      return;
    }

    const config = { attributes: true, childList: true, subtree: true };
    const observer = new MutationObserver(updateTooltipPositionOnChartResize);
    observer.observe(trendLineRefCurrent, config);
  }, [trendLineRefCurrent, updateTooltipPositionOnChartResize]);

  // QUERY PARAM INITIALISATION
  const [queryParamsInitialised, setQueryParamsInitialised] = useState(false);
  useEffect(() => {
    if (
      data &&
      (JSON.stringify(data) !== JSON.stringify(dataRef.current) ||
        JSON.stringify(queryFilters.filters) !== JSON.stringify(queryFiltersRef.current))
    ) {
      dataRef.current = data;
      queryFiltersRef.current = queryFilters.filters;
      let paramsUpdated = false;
      if (
        queryFilters.filters.selectedEnvironmentTypes.length > 1 ||
        queryFilters.filters.selectedEnvironmentTypes.find(type => !overviewEnvironmentTypesInPrioOrder.includes(type))
      ) {
        const supportedProjectEnvironmentType = getProjectOverviewEnvironmentType(data.samplesByUID);
        queryFilters.setEnvironmentTypes(supportedProjectEnvironmentType || EnvironmentType.WASTEWATER);
        paramsUpdated = true;
      }
      if (queryFilters.filters.selectedTargetGrouping !== 'antibiotic') {
        queryFilters.setGrouping('antibiotic');
        paramsUpdated = true;
      }
      if (
        !queryFilters.filters.selectedTargets.length ||
        queryFilters.filters.selectedTargets.find(target => !isAntibiotic(target))
      ) {
        queryFilters.toggleGeneGroup(allGeneGroups.antibiotic, true, 'antibiotic');
        paramsUpdated = true;
      }

      // TODO initiate setPreviousAdminAreasLifo if landing on site view

      // If params were updated, wait for the next render cycle before setting queryParamsReady
      if (paramsUpdated) {
        setTimeout(() => {
          setQueryParamsInitialised(true);
        }, 0);
      } else if (!queryParamsInitialised) {
        setQueryParamsInitialised(true);
      }
    }
  }, [data, queryFilters, queryParamsInitialised]);

  // PREPARE FLAT WASTE OR NATURAL WATER SAMPLES WITH CONSISTENT ADMIN LEVELS
  // Overview does not use focussedByUID, because it needs to look at all the genes.
  // However, query parameters are still used to keep track of focused samples between overview, sample view and reseach view
  const samplesByUID = data?.samplesByUID;
  useEffect(() => {
    if (samplesByUID) {
      const supportedSamplesWithNormalisedLevels = getSupportedSamplesWithConsistentAdminLevels(samplesByUID);
      setSupportedSamples(supportedSamplesWithNormalisedLevels);
      setIsOneHealthProject(areSamplesAnalysedWithOneHealthPanel(samplesByUID));
      setMetricGeneCnt(countMetricGenes(samplesByUID, metricMode, selectedAntibiotic));
    }
  }, [samplesByUID, metricMode, selectedAntibiotic]);

  // PREPARE OVERVIEW TREND, MAP AND HELPER DATA
  useEffect(() => {
    if (!supportedSamples?.length || !queryParamsInitialised) {
      return;
    }

    // Avoid race conditions that can easily happen eg when an orignial map data update with all project data is missing co-ordinates,
    // but a subsequent, map update with filtered data isn't
    const thisInvocation = ++mapDataUpdateInvocationCnt.current;
    (() => {
      try {
        if (!arePropertiesPresentInAllFullSamples(supportedSamples, 'time')) {
          if (mapDataUpdateInvocationCnt.current === thisInvocation) {
            setTrendDataByLevel({});
            setMapDataByLevel({});
            setLevelsWithZoomableAreas([]);
            setAvailableMonths([]);
          }
          return;
        }

        // Uncomment and load overview for end-to-end test project to update buildOverviewLineData regression test input.json
        // console.log('focusedByUID', JSON.stringify(focusedByUID));

        const levelsInAllSamples = keys(supportedSamples[0].adminLevels);
        const poolings: Pooling[] = [...levelsInAllSamples, null].map(level =>
          isNil(level)
            ? {
                type: PoolingType.SITE,
                mode: PoolingMode.THROW_MISSING,
              }
            : {
                type: PoolingType.SITE_AND_ADMIN_LEVEL,
                mode: PoolingMode.THROW_MISSING,
                level: +level,
              },
        );

        const newTrendDataByLevel = chain(poolings)
          .keyBy(pooling => ('level' in pooling ? `${pooling.level}` : 'null'))
          // Replace groupYearly with false to always use monthly data
          .mapValues(pooling => buildOverviewLineData(supportedSamples, pooling))
          .value();

        const newMapDataByLevel = mapValues(newTrendDataByLevel, levelTrendData =>
          buildMapData(levelTrendData, queryFilters.filters.interval),
        );
        const zoomableLevels = determineZoomableLevels(newMapDataByLevel);
        const months = getUniqueMonths(newTrendDataByLevel['null']);

        // This may be a usefull spot to log any any map area issues
        // console.log('newMapDataByLevel', newMapDataByLevel);
        // console.log('zoomableLevels', zoomableLevels);

        if (mapDataUpdateInvocationCnt.current === thisInvocation) {
          setTrendDataByLevel(newTrendDataByLevel);
          setMapDataByLevel(newMapDataByLevel);
          setLevelsWithZoomableAreas(zoomableLevels);
          setAvailableMonths(months);
          setNotAvailableReason(null);
        }
      } catch (err) {
        if (err instanceof Error) {
          setNotAvailableReason(err.message);
          console.error('OverviewContext error', err);
        } else {
          console.error('OverviewContext error', err);
        }
      }
    })();
  }, [supportedSamples, queryParamsInitialised, queryFilters.filters.interval]);

  useEffect(() => {
    if (trendDataByLevel && mapDataByLevel) {
      // shownAdminLevel can be null, we want to make sure the type is correct. Index can not be null
      const correctAdminLevel = shownAdminLevel || 'null';
      const newTrendData = trendDataByLevel[correctAdminLevel];
      // When one specific site is selected (transient aggregated environments have negative ids)
      if (shownAdminLevel === null && selectedOrHoveredAreaOrSiteEnvId && selectedOrHoveredAreaOrSiteEnvId > 0) {
        const selectedTrendData = newTrendData
          .flat()
          .find(dataLocal => dataLocal.environment.id === selectedOrHoveredAreaOrSiteEnvId);
        setTrendData([[selectedTrendData]] as OverviewProcessDatum[][]);
      } else {
        setTrendData(newTrendData);
      }

      const newMapData = mapDataByLevel[correctAdminLevel];

      setMapData(newMapData);
    } else {
      setTrendData([]);
      setMapData([]);
    }
  }, [levelsWithZoomableAreas, trendDataByLevel, mapDataByLevel, shownAdminLevel, selectedOrHoveredAreaOrSiteEnvId]);

  // PREPARE MAP SOURCES (COLORED REGIONS) AND ZOOM TO BOUNDS
  useEffect(() => {
    if (!trendData || !mapData || !countryByAlpha3 || !supportedSamples) {
      return;
    }

    const mapInstance = mapRef?.getMap();
    if (selectedSiteDatum) {
      if (mapInstance && mapLoaded && selectedSiteName) {
        const datum = mapData.find(d => {
          const siteEnvNames = getAllOriginalEnvironmentNames(d.environment, d.environmentAfter);
          return siteEnvNames.includes(selectedSiteName);
        });
        if (!isNil(datum?.inferredLon) && !isNil(datum.inferredLat)) {
          mapInstance.flyTo({ zoom: 13, center: [datum.inferredLon, datum.inferredLat], padding: 120 });
        }
      }
      return;
    }

    const countries = getCountriesFromSamples(supportedSamples);
    const { country } = getCountryAdminLevelIntersection(supportedSamples);
    const mapSource =
      shownAdminLevel && countries.length === 1
        ? getMapSourceFromAdminLevel(shownAdminLevel, country)
        : countries.length !== 1
          ? getMapSourceFromAdminLevel(2)
          : undefined;

    if (country) {
      setContinent(get(countryByAlpha3, country, undefined)?.continent);
    }
    if (mapInstance && mapLoaded) {
      if (!mapSource || selectedSiteName) {
        setActiveMapSource(undefined);
        if (zoomedAdminArea?.boundaries) {
          zoomAndCenter({ bounds: zoomedAdminArea.boundaries });
        }
        deactivateColoredRegions(mapInstance);
      } else if (shownAdminLevel) {
        setActiveMapSource(mapSource);
        deactivateColoredRegions(mapInstance);
        const boundingBoxes = chain(trendData)
          .map(_data => {
            const environmentId = _data[0].environment.id;
            return [environmentId, getBoundingBoxForEnvironment(_data)];
          })
          .fromPairs()
          .value() as Dictionary<{ ne: [number, number]; sw: [number, number] }>;
        activateColoredRegions(
          mapInstance,
          mapSource,
          mapData,
          setSelectedOrHoveredAreaOrSiteEnvId,
          boundingBoxes,
          changeZoomedAdminArea,
          queryFilters.filters,
          metricMode,
        );
        if (countries.length === 1) {
          zoomedAdminArea?.boundaries && zoomAndCenter({ bounds: zoomedAdminArea.boundaries });
        } else {
          const bounds = calculateMarkerBounds(mapData);
          zoomAndCenter({ bounds });
        }
      }
    }
  }, [
    trendData,
    mapData,
    supportedSamples,
    shownAdminLevel,
    zoomedAdminArea,
    mapRef,
    mapLoaded,
    selectedSiteDatum,
    selectedSiteName,
    metricMode,
  ]);

  // PREPARE ADMIN LEVELS
  useEffect(() => {
    if (selectedSiteName) {
      setShownAdminLevel(null);
    } else if (mapDataByLevel && singleSiteSelected && zoomedAdminArea === undefined) {
      // Decipher selected site from query filters
      const siteData = mapDataByLevel['null'];
      const siteToKeepSelected = siteData.find(d => {
        const siteEnvNames = getAllOriginalEnvironmentNames(d.environment, d.environmentAfter);
        return (
          siteEnvNames.length === queryFilters.filters.selectedEnvironmentNamesOrdered.length &&
          siteEnvNames.every(envName => queryFilters.filters.selectedEnvironmentNamesOrdered.includes(envName))
        );
      });
      if (siteToKeepSelected) {
        setZoomedAdminArea(null);
        setShownAdminLevel(null);
        setSelectedOrHoveredAreaOrSiteEnvId(siteToKeepSelected.environment.id);
      }
    } else if (
      mapDataByLevel &&
      keys(mapDataByLevel).length &&
      levelsWithZoomableAreas.length &&
      zoomedAdminArea === undefined
    ) {
      const highestLevel = levelsWithZoomableAreas[0];
      const levelMapData = mapDataByLevel[highestLevel];
      const firstArea = levelMapData[0]?.adminLevels?.[`${highestLevel}` as AdminLevelKey];
      setZoomedAdminArea(firstArea);
      setShownAdminLevel(levelsWithZoomableAreas.find(level => firstArea && level > firstArea.level) || null);
    }
  }, [
    mapDataByLevel,
    levelsWithZoomableAreas,
    selectedSiteName,
    zoomedAdminArea,
    singleSiteSelected,
    queryFilters.filters.selectedEnvironmentNamesOrdered,
  ]);

  // If only one site inside admin area, select it
  useEffect(() => {
    if (zoomedAdminArea && !shownAdminLevel && !activeMapSource) {
      const sitesInAdminArea = getAllSitesInAdminArea(zoomedAdminArea, mapData);

      if (sitesInAdminArea && sitesInAdminArea.length === 1) {
        queryFilters.toggleEnvironment(sitesInAdminArea[0].environment.name, true);
        changeZoomedAdminArea(null);
        hideTooltip();
      }
    }
  }, [zoomedAdminArea, shownAdminLevel, mapData, activeMapSource]);

  // PREPARE CALLBACKS
  const handleZoom = useCallback(
    (e: ViewStateChangeEvent) => {
      const { zoom: newZoom } = e.viewState;
      setZoom(newZoom);
    },
    [setZoom],
  );
  const zoomAndCenter = useCallback(
    (zoomOrBounds: { zoom: number; center: LngLatLike } | { bounds: LngLatBoundsLike }) => {
      const mapInstance = mapRef?.getMap();
      if (mapInstance && 'zoom' in zoomOrBounds) {
        mapInstance.flyTo({ zoom: zoomOrBounds.zoom, center: zoomOrBounds.center, padding: 120 });
        setZoom(zoomOrBounds.zoom);
      } else if (mapInstance && 'bounds' in zoomOrBounds) {
        const cameraForBounds = mapInstance.cameraForBounds(zoomOrBounds.bounds);
        mapInstance.flyTo({ zoom: cameraForBounds?.zoom, center: cameraForBounds?.center, padding: 120 });
      }
    },
    [mapRef],
  );
  const changeZoomedAdminArea = useCallback(
    (adminArea: AdminArea | null | undefined) => {
      // If adminArea is magically undefined, it means site is being unselected. If the site is being unselected and we
      // don't have any adminAreas to show, we zoom out so show all markers
      if (adminArea === undefined && levelsWithZoomableAreas.length >= 1 && mapData) {
        const bounds = calculateMarkerBounds(mapData);
        zoomAndCenter({ bounds });
      } else if (
        previousAdminAreasLifo.length &&
        adminArea &&
        getAdminAreaKey(adminArea) === getAdminAreaKey(previousAdminAreasLifo[previousAdminAreasLifo.length - 1])
      ) {
        setZoomedAdminArea(adminArea);
        setShownAdminLevel(levelsWithZoomableAreas.find(level => level > adminArea.level) || null);
        setPreviousAdminAreasLifo(previousAdminAreasLifo.slice(0, -1));
      } else if (!adminArea) {
        setZoomedAdminArea(null);
        setShownAdminLevel(null);
      } else {
        setZoomedAdminArea(adminArea);
        setShownAdminLevel(levelsWithZoomableAreas.find(level => level > adminArea.level) || null);
        if (zoomedAdminArea && getAdminAreaKey(adminArea) !== getAdminAreaKey(zoomedAdminArea)) {
          setPreviousAdminAreasLifo([...previousAdminAreasLifo, zoomedAdminArea]);
        }
      }
    },
    [zoomedAdminArea, previousAdminAreasLifo, levelsWithZoomableAreas],
  );

  // TOOLTIP STATE AND EFFECTS
  const {
    mouseMoveHandler,
    mouseClickHandler,
    onMouseLeave,
    onMouseEnter,
    TooltipComponentForARGIMarker,
    TooltipComponentForRiskScore,
    TooltipComponentForReduction,
    hideTooltip,
    positionTooltip,
    setTrendChartSizeForTooltip,
  } = useOverviewTooltip(
    selectedOrHoveredAreaOrSiteEnvId,
    setSelectedOrHoveredAreaOrSiteEnvId,
    trendData,
    timeScale,
    metricMode,
    shownAdminLevel,
    changeZoomedAdminArea,
    selectedSiteDatum,
  );

  const correctSetMapRef = (mapRefLocal: MapRef | null) => {
    if (!mapRef && mapRefLocal) {
      setMapRef(mapRefLocal);
    }
  };

  const getAdminAreaDatum = (level: number, name: string) => {
    return mapDataByLevel && mapDataByLevel[level].find(area => area.environment.name === name);
  };

  dataForCallback.current = {
    trendLineRef,
    shownAdminLevel,
    selectedOrHoveredAreaOrSiteEnvId,
    positionTooltip,
    oldBoundingBox,
  };

  const setTrendChartSizeLocal = (size: { width: number; height: number }) => {
    setTrendChartSize(size);
    setTrendChartSizeForTooltip(size);
  };

  const setMonth = useCallback(
    (date: Date | null) => {
      if (date === null) {
        queryFilters.setInterval(DEFAULT_START_INTERVAL, DEFAULT_END_INTERVAL);
      } else {
        const startOfMonth = ensureUtcMonth(date);
        const startOfNextMonth = utcStartOfNextMonth(startOfMonth);
        queryFilters.setInterval(startOfMonth, startOfNextMonth);
      }
    },
    [queryFilters],
  );

  const selectedMonth =
    // Currently the interval.start can present the 1900 year, which is less than 0
    queryFilters.filters.interval.start.valueOf() > 0 ? queryFilters.filters.interval.start : null;

  const contextData = {
    hasPreparedData: !!trendData && !!mapData,
    mapData,
    siteCount: mapDataByLevel && mapDataByLevel['null'].length,
    samplingPointsCount:
      mapDataByLevel && mapDataByLevel['null'].reduce((acc, d) => acc + (d.environmentAfter ? 2 : 1), 0),
    selectedSiteDatum,
    trendData,
    selectedAntibiotic,
    previousAdminAreasLifo,
    loading: loading || !queryParamsInitialised,
    continent,
    error,
    selectedOrHoveredAreaOrSiteEnvId,
    setSelectedOrHoveredAreaOrSiteEnvId,
    activeMapSource,
    setMapRef: correctSetMapRef,
    mapRef,
    handleZoom,
    shownAdminLevel,
    isOneHealthProject,
    metricGeneCnt,
    zoomAndCenter,
    zoom,
    data,
    changeZoomedAdminArea,
    setMapLoaded,
    topLevelMapData:
      mapDataByLevel && levelsWithZoomableAreas.length ? mapDataByLevel[levelsWithZoomableAreas[0]][0] : undefined,
    zoomedMapData: zoomedAdminArea ? getAdminAreaDatum(zoomedAdminArea.level, zoomedAdminArea.name) : undefined,
    trenchartTooltip: {
      setTrendChartSize: setTrendChartSizeLocal,
      trendChartSize,
      mouseMoveHandler,
      mouseClickHandler,
      onMouseLeave,
      onMouseEnter,
      TooltipComponentForARGIMarker,
      TooltipComponentForRiskScore,
      TooltipComponentForReduction,
      hideTooltip,
    },
    setTrendLineRef,
    metricMode,
    notAvailableReason,
    availableMonths,
    setMonth,
    selectedMonth,
  };

  return <OverviewContext.Provider value={contextData}>{children}</OverviewContext.Provider>;
}

export function useOverviewContext() {
  const context = useContext(OverviewContext);
  if (!context) {
    throw new Error('useOverviewContext must be used within a OverviewContextProvider');
  }
  return context;
}

export function useSelectedSiteDatumOrThrow() {
  const { selectedSiteDatum } = useOverviewContext();
  if (!selectedSiteDatum) {
    throw new Error('Expected site view to be active');
  }
  return selectedSiteDatum;
}

export function getCountryAdminLevelIntersection(flatSamples: FullSample[]) {
  // TODO: should we include the admin level intersection in this function (?)
  // TODO: support samples accross countries, and with mixed admin levels available
  const countries = chain(flatSamples)
    .map(s => s.country)
    .uniq()
    .value();

  // Ensure that we only use admin levels that are available for all samples
  // Rural samples might be lacking deeper admin levels that are present in big cities.
  // Eg. sample 36381 (-7.9734244,112.6308338) has levels 1-5 whereas sample 36369 (-6.1982399,106.8451211) has levels 1-9.

  const adminLevels =
    countries.length !== 1
      ? undefined
      : chain(flatSamples)
          .map(s => s.adminLevels)
          .map(levels => keys(levels || {}))
          .reduce((previous, current) => intersection(previous, current))
          .map(level => +level)
          .value();

  const country = countries.length === 1 ? countries[0] : undefined;
  return { country, adminLevels };
}

function getCountriesFromSamples(samples: FullSample[]) {
  return chain(samples)
    .map(s => s.country)
    .uniq()
    .value();
}

/*
 * Determine admin levels whose areas we can ZOOM TO. This includes
 * - First, the default zoom level: the highest level that has only one area with samples (this level is never shown as areas)
 * - Last, zoomable level that has more than one site in some area (this level is shown as colored areas, and sites are shown when zooming into one)
 * - And usefull levels in between the first and the last level
 *   - Useless levels that have as many datums as the next area are skipped so that user always gets more data on each click
 *
 * Mind the ZOOMED area & level vs SHOWN level concepts:
 * When ZOOMING into a level 2 area, data is SHOWN on level 3 (or whatever is the next available level in all samples)
 */
export function determineZoomableLevels(mapDataByLevel: Dictionary<OverviewDatum[]>): number[] {
  const numSites = mapDataByLevel['null'].length;

  const ret = chain(mapDataByLevel)
    .toPairs()
    // Consider non-aggregated site level as the deepest level
    .sortBy(([level]) => (isNil(level) ? 16 : +level))
    .filter(
      // Get rid of weird corner cases where a level has less areas than its upper level
      ([_, levelAreas], pairIndex, allPairs) => !pairIndex || levelAreas.length >= allPairs[pairIndex - 1][1].length,
    )
    .filter(([_, levelAreas], pairIndex, allPairs) => {
      // Skip useless levels where the next level doesn't have any more areas (note: expects that null level still exists)
      return pairIndex < allPairs.length - 1 && allPairs[pairIndex + 1][1].length > levelAreas.length;
    })
    // Ensure that some area on each level has more than one data point
    // Also drops special case null site level as the last operation
    .filter(([_, levelAreas]) => levelAreas.length < numSites)
    .map(([level]) => +level)
    .value();

  return ret;
}

function getAllSitesInAdminArea(adminArea: AdminArea, mapData: OverviewDatum[] | undefined) {
  return mapData?.filter(datum => {
    const key = String(adminArea.level) as AdminLevelKey;
    const localAdminArea = datum.adminLevels?.[key];
    return localAdminArea && getAdminAreaKey(localAdminArea) === getAdminAreaKey(adminArea);
  });
}
