import { Alert, AlertDescription, AlertIcon, Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { SpacingContainer } from '@resistapp/client/components/containers/spacing-container';
import { RSecondTitle } from '@resistapp/client/components/headers/r-second-title';
import { useUser } from '@resistapp/client/contexts/use-user-context';
import { useVerifyEmail } from '@resistapp/client/hooks/api';
import { useNavigateWithQuery } from '@resistapp/client/hooks/use-navigate-with-query';
import { getVerificationCode } from '@resistapp/client/utils/url-manipulation';
import { getUserProjectIds } from '@resistapp/common/access';
import { Feature, hasFeature } from '@resistapp/common/features';
import { rndProjectId } from '@resistapp/common/utils';
import { isNil } from 'lodash';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { SignupLogo } from '../../components/icons/signup-logo';

export function VerifyEmailView() {
  const { setLoggedIn } = useUser();
  const navigate = useNavigateWithQuery();
  const [searchParams] = useSearchParams();
  const verificationCode = getVerificationCode(searchParams);
  const { create } = useVerifyEmail();
  const [redirectSeconds, setRedirectSeconds] = useState<number | undefined>();

  useEffect(() => {
    if (verificationCode) {
      create.mutate({ verificationCode });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verificationCode]);

  useEffect(() => {
    if (create.isSuccess && isNil(redirectSeconds)) {
      setLoggedIn(create.data);
      const redirectIn = 5;
      const countdown = (seconds: number) => {
        setTimeout(
          () => {
            setRedirectSeconds(seconds);
            if (!seconds) {
              const projects = getUserProjectIds(create.data);
              if (hasFeature(create.data, Feature.ARGI)) {
                navigate(`/index/${projects[0] || rndProjectId}`, false);
              } else if (hasFeature(create.data, Feature.RISK)) {
                navigate(`/risk/${projects[0]}`, false);
              } else if (hasFeature(create.data, Feature.REDUCTION)) {
                navigate(`/reduction/${projects[0]}`, false);
              } else {
                navigate(`/research/${projects[0]}`, false);
              }
            }
          },
          (redirectIn - seconds) * 1000,
        );
      };
      for (let s = redirectIn; s >= 0; s--) {
        countdown(s);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [create.isSuccess, create.data, redirectSeconds]);

  return (
    <Flex direction="column" alignItems="center">
      <SpacingVerifyContainer>
        <SignupLogo />
        <RSecondTitle style={{ textAlign: 'center', paddingBottom: '30px' }}>Verify email</RSecondTitle>
        {create.error && (
          <Alert status="error">
            <AlertIcon />
            <AlertDescription>{create.error.message}</AlertDescription>
          </Alert>
        )}
        {create.isSuccess && (
          <Alert status="success">
            <AlertIcon />
            <AlertDescription>
              Email verified.
              <br />
              Redirecting you to the platform in {redirectSeconds} s...
            </AlertDescription>
          </Alert>
        )}
      </SpacingVerifyContainer>
    </Flex>
  );
}

const SpacingVerifyContainer = styled(SpacingContainer)`
  max-width: 400px;
  width: 100%;
`;
