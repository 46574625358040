import styled from '@emotion/styled';
import { useLocation } from 'react-router-dom';
import { oldTheme } from '../../shared/old-styles';

import facebookImage from '@resistapp/client/assets/fb.png';
import linkedinImage from '@resistapp/client/assets/linkedin.png';
import twitterImage from '@resistapp/client/assets/twitter.png';
import { theme } from '../../shared/theme';

interface Props {
  top?: number;
}

const footerHeight = 17;

export function Footer({ top }: Props) {
  const location = useLocation();
  const onWorldmap = location.pathname.includes('world') || location.pathname === '/';
  if (!top && onWorldmap) {
    return null;
  }
  const onLogin = location.pathname.includes('login');
  const color = theme.colors.neutral500;

  return (
    <div style={{ paddingBottom: onWorldmap || onLogin ? footerHeight : 'unset' }}>
      <div
        style={{
          position: onWorldmap || onLogin ? 'absolute' : 'unset',
          zIndex: top ? 10 : theme.zIndexes.footerZ,
          top,
          bottom: 0,
          width: '100%',
          height: footerHeight,
          paddingTop: '22px',
          paddingBottom: '32px',
        }}
      >
        <footer
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingLeft: oldTheme.spacing.l,
            paddingRight: oldTheme.spacing.l,
            paddingBottom: oldTheme.spacing.l,
            paddingTop: oldTheme.spacing.s,
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: 'auto',
              gap: '32px',
            }}
          >
            <Resistomap style={{ color }}>© Resistomap {new Date().getFullYear()}</Resistomap>
            <FooterText style={{ color }}>Elimäenkatu 15, Helsinki, Finland</FooterText>
          </div>
          {onWorldmap && (
            <Links>
              <a href="https://www.facebook.com/resistomap" target="_blank" rel="noreferrer">
                <SocMed alt="Facebook" src={facebookImage as string} style={{ filter: 'grayscale(100%)' }} />
              </a>
              <a href="https://twitter.com/resistomap" target="_blank" rel="noreferrer">
                <SocMed alt="X" src={twitterImage as string} style={{ filter: 'grayscale(100%)' }} />
              </a>
              <a href="https://www.linkedin.com/company/resistomap" target="_blank" rel="noreferrer">
                <SocMed alt="Linkedin" src={linkedinImage as string} style={{ filter: 'grayscale(100%)' }} />
              </a>
            </Links>
          )}
        </footer>
      </div>
    </div>
  );
}

const FooterText = styled.div`
  line-height: 20px;
  font-size: 15px;
`;

const Resistomap = styled(FooterText)`
  ${oldTheme.fontWeight.bold}
`;

const SocMed = styled.img`
  width: 40px;
  height: 40px;
  margin: 0px;
  padding: 0px;
  background-color: ${oldTheme.white};
`;

const Links = styled.div`
  display: flex;
  align-items: center;
  justify-content: right;
`;
