import { FormControl, FormErrorMessage, FormLabel, Input } from '@chakra-ui/react';
import { FieldErrors, FieldValues, UseFormRegister } from 'react-hook-form';
import { FormTypeInput } from '../form-types';

interface Props<T extends FieldValues> {
  field: FormTypeInput;
  errors: FieldErrors<T>[string];
  register: UseFormRegister<T>;
}

export function InputField<T extends FieldValues>(props: Props<T>) {
  return (
    <FormControl isInvalid={!!props.errors} key={props.field.name}>
      <FormLabel fontWeight={501}>{props.field.label}</FormLabel>
      <Input
        placeholder={props.field.label}
        type={props.field.type}
        {...props.register(props.field.name as FieldValues[string])}
      />
      <FormErrorMessage>{props.errors?.message as FieldValues[string]}</FormErrorMessage>
    </FormControl>
  );
}
