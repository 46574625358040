import styled from '@emotion/styled';
import { oldTheme } from '../shared/old-styles';
import { theme } from '../shared/theme';
import { IconBase } from './icon-base';

export const TrashIcon = styled(IconBase)`
  font-size: ${oldTheme.fontSize.m};
  padding-top: 4px;
  color: ${theme.colors.maroon} !important;
  &:hover {
    color: ${theme.colors.maroon} !important;
  }
  &:active {
    text-decoration: none;
    color: ${theme.colors.maroon} !important;
  }
  cursor: pointer;
`;
