import { Filters, handleFiltersSelectionWithKeys } from '@resistapp/client/data-utils/filter-data/filter';
import { Target } from '@resistapp/common/assays';
import { FullSamplesByUID } from '@resistapp/common/types';
import { sortUniqEnvironments } from '@resistapp/common/utils';
import { ascending } from 'd3-array';
import { ScaleOrdinal } from 'd3-scale';
import { differenceInSeconds } from 'date-fns';
import { difference, keyBy, mapValues, uniqBy } from 'lodash';
import { KeysPressOptions } from '../legends/legend';

export const chartLayoutValues = {
  plotLeftOffset: 71, // For the y-axis ticks and markers
  xAxisBottomMargin: 12,
  xAxisLeftMargin: 1,
  yAxisLeftMargin: 1,
  heatmapYAxisTopMargin: 10,
  heatMapRightMargin: 5,
  heatmapYAxisTopMargin2: 15,
  yAxisMarginLeft: 6,
  // TODO: I think with proper design there should be no need for this extra margin.
  // Now the bar and box plots work with above "yAxisMarginLeft", but heatmap needs this 6
  heatmapYAxisMarginLeftExtraMargin: 6,
  xAxisHeight: 40,
  xAxisTopMargin: 5,
};

export function getShortestStepInSeconds(times: Date[]) {
  const sortedTimes = (() => {
    const uniqTimes = uniqBy(times, time => time.toISOString());
    uniqTimes.sort(ascending);
    return uniqTimes;
  })();
  return sortedTimes.reduce<number>((minSoFar, current, i) => {
    if (i === 0) {
      return minSoFar;
    }
    const previous = sortedTimes[i - 1];
    return Math.min(minSoFar, differenceInSeconds(current, previous));
  }, Number.MAX_SAFE_INTEGER);
}

export function exponentToSuperScript(exponent: number) {
  switch (exponent) {
    case 0:
      return '⁰';
    case -1:
      return '⁻¹';
    case -2:
      return '⁻²';
    case -3:
      return '⁻³';
    case -4:
      return '⁻⁴';
    case -5:
      return '⁻⁵';
    case -6:
      return '⁻⁶';
    case -7:
      return '⁻⁷';
    case -8:
      return '⁻⁸';
    case -9:
      return '⁻⁹';
    default:
      throw Error(`Unsupported exponent: ${exponent}`);
  }
}

export function getHeatmapChartOneGeneHeight(numKeys: number) {
  return numKeys > 100 ? 5 : 10;
}

// We need to unregister the previous event listener before registering a new one, to avoid bugs and memory leaks
let activehandleKeyUpEventListener: () => void = () => {};
// We track what label was previously selected, for using the shift key functionality when selecting samples.
let previouslySelectedLabel: string | undefined = undefined;
export function getSampleSelector(
  showSampleNumbers: boolean,
  selectedEnvironmentNames: Target[],
  allEnvironmentNames: Target[],
  samplesBeingSelected: { ids: string[]; removeOldSelections: boolean },
  setSamplesBeingSelected: (value: { ids: string[]; removeOldSelections: boolean }) => void,
  toggleEnvironment: (envName: string | string[], only: boolean) => void,
  selectedEnvironmentIds: string[],
  commitMode: boolean,
  isLegend: boolean,
) {
  return (label: string, keys: KeysPressOptions) => {
    const newSelectedLabel = !showSampleNumbers && !isLegend ? label : label.replace(/^\d+\s-\s(.+)/, '$1');
    const selectedEnvironmentNamesOrIds =
      showSampleNumbers && !isLegend ? selectedEnvironmentIds : selectedEnvironmentNames;
    const previousLabel = keys.shift
      ? previouslySelectedLabel ||
        (selectedEnvironmentNamesOrIds.length === 1
          ? selectedEnvironmentNamesOrIds[0]
          : samplesBeingSelected.ids[samplesBeingSelected.ids.length - 1])
      : samplesBeingSelected.ids[samplesBeingSelected.ids.length - 1];
    const [newSelections, removeOldSelections] = handleFiltersSelectionWithKeys(
      commitMode ? samplesBeingSelected.ids : selectedEnvironmentNamesOrIds,
      allEnvironmentNames,
      newSelectedLabel,
      keys.shift ? previousLabel : samplesBeingSelected.ids[samplesBeingSelected.ids.length - 1],
      keys,
    );

    window.removeEventListener('keyup', activehandleKeyUpEventListener);
    if (commitMode && (keys.ctrl || keys.shift)) {
      const newSelectionsArray = Array.isArray(newSelections) ? newSelections : [newSelections];
      const existingOldSelections = keys.shift ? [] : difference(samplesBeingSelected.ids, newSelectionsArray);
      const correctedSamplesBeingSelected = difference(newSelectionsArray, samplesBeingSelected.ids);
      const shiftSelections = difference(newSelectionsArray, selectedEnvironmentNamesOrIds);
      const correctlySelected = {
        ids: [...correctedSamplesBeingSelected, ...existingOldSelections],
        removeOldSelections,
      };
      setSamplesBeingSelected(keys.shift ? { ids: shiftSelections, removeOldSelections } : correctlySelected);

      const handleKeyUp = () => {
        toggleEnvironment(correctlySelected.ids, removeOldSelections);
        setSamplesBeingSelected({ ids: [], removeOldSelections: true });
        previouslySelectedLabel = newSelectedLabel;
      };

      window.addEventListener('keyup', handleKeyUp);
      activehandleKeyUpEventListener = handleKeyUp;
      previouslySelectedLabel = keys.ctrl ? newSelectedLabel : previouslySelectedLabel;
      // This is returned for tests
      return handleKeyUp;
    }

    previouslySelectedLabel = newSelectedLabel;
    toggleEnvironment(newSelections, removeOldSelections);
    // Not using commit mode, then we have to empty the samplesBeingSelected state to avoid bugs and unexpected states
    setSamplesBeingSelected({ ids: [], removeOldSelections: true });
    return;
  };
}

export function getSelectedEnvironmentIds(
  selectedEnvironmentNames: string[],
  environmentNames: {
    [x: string]: string;
  },
) {
  return selectedEnvironmentNames.map(name => {
    const id = Object.keys(environmentNames).find(key => environmentNames[key] === name);
    return id ? String(id) : '';
  });
}

export function getEnvironmentDatas(
  samplesByUID: FullSamplesByUID,
  selectedEnvironmentTypes: Filters['selectedEnvironmentTypes'],
) {
  const environments = sortUniqEnvironments(samplesByUID);
  const nameById = mapValues(
    keyBy(environments, env => `${env.id}`),
    env => env.name,
  );
  const environmentIds = environments
    .filter(env => selectedEnvironmentTypes.includes(env.type))
    .map(env => `${env.id}`);
  const environmentNames = environmentIds.map(id => nameById[id]);

  return { environments, nameById, environmentIds, environmentNames };
}

export function getSemiTransparentColorFromScale(scale: ScaleOrdinal<string, string>) {
  return (d: string) => {
    const hex = scale(d);
    return getSemiTransparentColor(hex);
  };
}
export function getSemiTransparentColor(hex: string) {
  return hex + '80'; // 80 in hex = 50% opacity
}
