import styled from '@emotion/styled';
import { oldTheme } from '@resistapp/client/components/shared/old-styles';
import { ExternalLinkIcon } from '../icons/external-link-icon';

interface Props {
  text: string;
  href: string;
}

export function UrlLink({ text, href }: Props) {
  return (
    <Root>
      <Link href={href} target="_blank" rel="noopener noreferrer">
        <ExternalLinkIcon title="Go to link" size="sm" style={{ paddingTop: '4px' }} />
        {text}
      </Link>
    </Root>
  );
}

const Root = styled.div`
  color: ${oldTheme.lightBlue};
  font-size: ${oldTheme.fontSize.s};
  margin-left: ${oldTheme.spacing.xxs};
`;

const Link = styled.a`
  display: flex;
  gap: 5px;
  min-width: 165px;
  cursor: pointer;
  color: gray;
  &:hover {
    color: ${oldTheme.black};
  }
`;
