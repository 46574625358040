import { FullProject } from '@resistapp/common/types';
import { createContext, useContext, useRef, useState } from 'react';
import { ResearchPlotData, buildResearchPlotData } from '../data-utils/plot-data/research-plot-data';
import { QueryFilters } from '../hooks/use-query-filters/use-query-filters';
import { AbsoluteModeData, useSampleDataContext } from './sample-data-context';

export interface SampleSelectionState {
  ids: string[];
  removeOldSelections: boolean;
}

interface BaseResearch {
  error: Error | null;
  loading: boolean;
  queryFilters: QueryFilters;
  absoluteMode: AbsoluteModeData;
  plotData: ResearchPlotData | null;
  sampling: FullProject | null;
  setGraphAndLegendContainerWidth: (width: number) => void;
  graphAndLegendContainerWidth: number;
  samplesBeingSelected: SampleSelectionState;
  setSamplesBeingSelected: (value: SampleSelectionState) => void;
}

interface ResearchLoading extends BaseResearch {
  loading: true;
  plotData: null;
  sampling: null;
}

interface ResearchLoaded extends BaseResearch {
  loading: false;
  plotData: ResearchPlotData | null;
  sampling: FullProject;
}

const ResearchContext = createContext<ResearchLoading | ResearchLoaded | undefined>(undefined);

export function ResearchProvider({ children }: { children: React.ReactNode }) {
  const { data: samplingData, queryFilters, absoluteMode, loading, error } = useSampleDataContext();
  const [graphAndLegendContainerWidth, setGraphAndLegendContainerWidth] = useState(0);
  const [samplesBeingSelected, setSamplesBeingSelectedLocal] = useState<SampleSelectionState>({
    ids: [],
    removeOldSelections: false,
  });
  const sampleRef = useRef<SampleSelectionState>({
    ids: [],
    removeOldSelections: false,
  });
  sampleRef.current = samplesBeingSelected;

  const sampling = {
    ...samplingData,
    focusedByUID: queryFilters.hasFocus ? samplingData?.focusedByUID : samplingData?.samplesByUID,
  };

  const samplingIsValid = validateSampling(sampling);
  const plotData = samplingIsValid ? buildResearchPlotData(sampling, queryFilters.filters) : null;

  const setSamplesBeingSelected = (newSelection: SampleSelectionState) => {
    if (newSelection.ids.length === 0) {
      setSamplesBeingSelectedLocal({ ids: [], removeOldSelections: newSelection.removeOldSelections });
    } else {
      setSamplesBeingSelectedLocal(newSelection);
    }
  };

  if (plotData && samplingIsValid && !loading) {
    const contextData = {
      loading: false as const,
      plotData,
      sampling,
      queryFilters,
      absoluteMode,
      error,
      setGraphAndLegendContainerWidth,
      graphAndLegendContainerWidth,
      samplesBeingSelected,
      setSamplesBeingSelected,
    };
    return <ResearchContext.Provider value={contextData}>{children}</ResearchContext.Provider>;
  } else {
    const contextData = {
      loading: true as const,
      plotData: null,
      sampling: null,
      queryFilters,
      absoluteMode,
      error,
      setGraphAndLegendContainerWidth,
      graphAndLegendContainerWidth,
      samplesBeingSelected,
      setSamplesBeingSelected,
    };
    return <ResearchContext.Provider value={contextData}>{children}</ResearchContext.Provider>;
  }
}

export function useResearchContext() {
  const context = useContext(ResearchContext);

  if (!context) {
    throw new Error('useResearchContext must be used within a ResearchProvider');
  }

  return context;
}

/**
 * Validates the structure and content of the sampling data.
 * @param {FullProject | null} sampling - The sampling data to validate.
 * @returns {boolean} - Returns true if the sampling data is valid, otherwise false.
 */
function validateSampling(sampling: Partial<FullProject>): sampling is FullProject {
  // Example validation: Ensure that the sampling has necessary properties
  const requiredProperties = ['samplesByUID', 'focusedByUID', 'qpcrFiles'];
  const hasAllProperties = requiredProperties.every(prop => prop in sampling);

  if (!hasAllProperties) {
    return false;
  }

  // Further validations can be added here based on project requirements
  return true;
}
