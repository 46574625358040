import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { oldTheme } from '../shared/old-styles';
import { theme } from '../shared/theme';

export const IconBase = styled(FontAwesomeIcon)`
  color: ${theme.colors.neutral500} !important;
  &:hover {
    color: ${theme.colors.neutral700} !important;
  }
  &:active {
    text-decoration: none;
    color: ${theme.colors.neutral900} !important;
  }
  margin-bottom: ${oldTheme.spacing.min};
  margin-left: ${oldTheme.spacing.xxs};
`;
