import { every } from 'lodash';
import { EnumLike } from 'zod';
import { RequiredFields } from './type-utils';
import { FullSample } from './types';

// These are typeguard functions for typeguarding the typescript code and avoiding methods like using any or !
export function isStringInEnum<T extends string | number | boolean>(
  value: any,
  enumObj: Record<string, T>,
): value is T {
  return Object.values(enumObj).some(v => v === value);
}

export function isEnumKey<T extends EnumLike>(
  value: string | number,
  enumObj: T,
): value is Extract<keyof T, string | number> {
  return Boolean(enumObj[value]);
}

export function isPropertyPresentInFullSample<T extends keyof FullSample>(
  sample: FullSample,
  property: T,
): sample is RequiredFields<FullSample, T> {
  return Boolean(sample[property]);
}

export function arePropertiesPresentInAllFullSamples<T extends keyof FullSample>(
  samples: FullSample[],
  property: T,
): samples is Array<RequiredFields<FullSample, T>> {
  return every(samples, sample => isPropertyPresentInFullSample(sample, property));
}

export function isObjectType(obj: any): obj is Record<string, unknown> {
  return typeof obj === 'object' && obj !== null;
}
