import { useState } from 'react';
import { LocalStorageId } from '../components/shared/local-storage';

export class Tutorial {
  id: 'overview';
  stepCount: number;
  hasScrolledToStep: number | undefined;
  constructor(id: 'overview', stepCount: number) {
    this.id = id;

    if (!this.hasTutorialBeenShown() && !this.getCurrentStep()) {
      this.setTutorialStep(1);
    }
    this.stepCount = stepCount;
    this.hasScrolledToStep = undefined;
  }

  getTutorialStepId() {
    return LocalStorageId.overviewTutorialStep;
  }
  hasTutorialBeenShown() {
    return this.getCurrentStep() < 0;
  }
  setTutorialStep(step: number | null) {
    if (step === null) {
      localStorage.setItem(this.getTutorialStepId(), '-1');
      return;
    }

    localStorage.setItem(this.getTutorialStepId(), String(step));
  }
  getCurrentStep() {
    return Number(localStorage.getItem(this.getTutorialStepId())) || 1;
  }

  closeTutorial() {
    this.setTutorialStep(null);
  }

  changeStep(direction: 'previous' | 'next' | 'stop') {
    switch (direction) {
      case 'previous':
        if (this.getCurrentStep() > 1) {
          this.setTutorialStep(this.getCurrentStep() - 1);
        }
        break;
      case 'next':
        if (this.getCurrentStep() < this.stepCount) {
          this.setTutorialStep(this.getCurrentStep() + 1);
        }
        break;
      case 'stop':
        this.closeTutorial();
        break;
      default:
        console.error('Invalid direction');
        break;
    }
    return this.getCurrentStep();
  }

  getTutorialStep() {
    if (this.hasTutorialBeenShown()) {
      return;
    }

    return this.getCurrentStep();
  }

  getTutorialPhaseText() {
    const title =
      this.getCurrentStep() === 1 ? (
        <i>Click map labels for site details</i>
      ) : this.getCurrentStep() === 2 ? (
        <i>Analyse in detail</i>
      ) : (
        <span>
          <i>Hover or tap on the question marks</i> ↑
        </span>
      );
    return (
      <div>
        <i>
          <b>Tip!</b>
        </i>{' '}
        {title}
      </div>
    );
  }

  scrollToStep(popoverRef?: React.RefObject<HTMLDivElement>) {
    if (!popoverRef || !popoverRef.current || this.hasScrolledToStep === this.getCurrentStep()) {
      return;
    }

    const rect = popoverRef.current.getBoundingClientRect();
    const scrollTop = window.scrollY || document.documentElement.scrollTop;
    const targetPosition = rect.top + scrollTop - 100; // 100px offset from the top, so that there is clear space.

    window.scrollTo({
      top: targetPosition,
      behavior: 'smooth',
    });

    this.hasScrolledToStep = this.getCurrentStep();
  }
}

export const tutorials = {
  overview: new Tutorial('overview', 3),
};

export const useTutorial = (id: Tutorial['id']) => {
  const [statusChanged, setStatusChanged] = useState<number>(0);

  const nextStep = () => {
    tutorials[id].changeStep('next');
    setStatusChanged(statusChanged + 1);
  };
  const prevStep = () => {
    tutorials[id].changeStep('previous');
    setStatusChanged(statusChanged + 1);
  };
  const stopTutorial = () => {
    tutorials[id].changeStep('stop');
    setStatusChanged(statusChanged + 1);
  };

  return {
    currentTutorialStep: tutorials[id].getCurrentStep(),
    tutorial: tutorials[id],
    nextStep,
    prevStep,
    stopTutorial,
  };
};
