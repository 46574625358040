import { isAdmin } from '@resistapp/common/features';
import { User } from '@resistapp/common/types';
import React, { ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import useLocalStorageState from 'use-local-storage-state';
import { LocalStorageId, storedToken } from '../components/shared/local-storage';
import { useGetUser, usePostLogin } from '../hooks/api';

interface UserContextType {
  user: User | undefined | null;
  logout: (url?: string | number) => void;
  loginErrorMessage: string | undefined;
  userLoading: boolean;
  login: (email: string, password: string) => void;
  loggingIn: boolean;
  setLoggedIn: (userData: User) => void;
}

const UserContext = React.createContext<UserContextType>({
  user: undefined,
  logout: () => {},
  loginErrorMessage: undefined,
  userLoading: false,
  login: () => {},
  loggingIn: false,
  setLoggedIn: () => {},
});

export function useUser() {
  return useContext(UserContext);
}

export function UserContextProvider(props: { children: ReactNode }) {
  const { data, loading: userLoading, send: getUser } = useGetUser();
  const [loginErrorMessage, setLoginErrorMessage] = useState<string | undefined>();
  const postLogin = usePostLogin(
    userData => {
      setLoggedIn(userData);
    },
    localError => {
      setLoginErrorMessage(localError.message);
      setLoggingIn(false);
    },
  );
  // User is logged in fully, when user is set. When the user refreshes the page we retrieve the user data through API
  // and set the user data again.
  const [user, setUser] = useState<User | undefined | null>(undefined);
  const [loggingIn, setLoggingIn] = useState(false);
  const [teamBrowser, setTeamBrowser] = useLocalStorageState<boolean>(LocalStorageId.teamBrowser, {
    defaultValue: false,
  });
  const [, storeToken, { removeItem: removeToken }] = useLocalStorageState<string>(LocalStorageId.token, {
    defaultValue: undefined,
  });
  const token = storedToken();
  const setLoggedIn = (userData: User) => {
    userData.token && storeToken(userData.token);
    if (isAdmin(userData) && !teamBrowser) {
      setTeamBrowser(true); // Never unset
    }
    setUser(userData);
    setLoggingIn(false);
  };

  useEffect(() => {
    // Step 2: When we have called getUser and received the user data, we set the user as logged in with the data
    if (data && !user) {
      setUser(data);
      // Step 1: When we have a token, we should call getUser to retrieve the user data
    } else if (token) {
      void getUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const logout = useCallback(() => {
    removeToken();
    setUser(null);
  }, [removeToken, setUser]);
  const login = useCallback(
    (email: string, password: string) => {
      setLoggingIn(true);
      void postLogin(email, password);
    },
    [postLogin],
  );

  return (
    <UserContext.Provider
      value={{
        user,
        loggingIn,
        login,
        setLoggedIn,
        userLoading,
        logout,
        loginErrorMessage,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
}
