import { sentanceCase } from '@resistapp/common/friendly';
import { Option } from './types';

export function buildOption<O extends Option = string>(option: O, labeller?: (option: O) => string) {
  const value = typeof option === 'string' ? option : option.id;
  return {
    value,
    label: labeller ? labeller(option) : sentanceCase(`${value}`),
  };
}
