import { chain, max, min } from 'lodash';
import { friendlyMonth } from './friendly';

export type DateInterval = {
  start: Date;
  end: Date;
};

export function getStartAndEndTime(data: Array<{ date: string | Date }>): DateInterval | undefined {
  const dates = chain(data)
    .map(datum => datum.date)
    .compact()
    .map(date => new Date(date))
    .value();
  const start = min(dates);
  const end = max(dates);
  return start && end ? { start: new Date(start), end: new Date(end) } : undefined;
}

// This function returns:
// - Jan - Feb 2025 (when same year)
// - 2024 - 2025 (when different years)
// - Jan 2024 - Feb 2025 (when years)
export function getFriendlyInterval(data: Array<{ date: string | Date }>, separator = '-') {
  const dateInterval = getStartAndEndTime(data);
  if (!dateInterval) {
    return '';
  }

  const years = [dateInterval.start.getFullYear(), dateInterval.end.getFullYear()];
  const isSameYear = years[0] === years[1];
  const start = !isSameYear ? `${years[0]}` : friendlyMonth(dateInterval.start, 'postfixAlways');
  const end = !isSameYear ? `${years[1]}` : friendlyMonth(dateInterval.end, 'postfixAlways');
  // This changes Jan 2024 - Feb 2024 => Jan - Feb 2024
  const shortenedStart = isSameYear ? start.replace(/^([a-zA-Z]+)(\s){1}([0-9])+$/, '$1') : start;

  return start === end ? start : `${shortenedStart} ${separator} ${end}`;
}
