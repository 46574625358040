import { Checkbox } from '@chakra-ui/react';
import { oldTheme } from '../shared/old-styles';

interface Props {
  cookiesAccepted: boolean;
  setCookiesAccepted: (value: boolean) => void;
  disabled: boolean;
}

export function AgreePoliciesCheckbox({ cookiesAccepted, setCookiesAccepted, disabled }: Props) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '10px',
        padding: oldTheme.spacing.min,
      }}
    >
      <Checkbox
        isChecked={cookiesAccepted}
        color="primary"
        size="lg"
        onChange={() => {
          setCookiesAccepted(!cookiesAccepted);
        }}
        disabled={disabled}
      />
      <span style={{ fontSize: 12 }}>
        I accept the{' '}
        <a
          href="https://drive.google.com/file/d/1aTSOz7sumM0WL_3KUGS7IRpFQz87iZyY/view?usp=sharing"
          target="_blank"
          rel="noreferrer"
        >
          privacy policy
        </a>{' '}
        & anonymous usage tracking.
      </span>
    </div>
  );
}
